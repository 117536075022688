import React from "react";
import { NavLink, useParams } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import HelmetComponent from "../../Admin/Layout/HelmetComponent/HelmetComponent";

const Introduction = () => {
    const { translate } = translator();
    const { lang } = useParams();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Title_DocAPI")}
                description={translate("SEO", "Desc_DocAPI")}
                link="/documentation/introduction"
                keywords={translate("SEO", "Keys_DocAPI")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <Banner title={translate("DOC_API_PAGE", "OFFICIAL_DOC")}></Banner>
            <article className="flex xl:space-x-12 sm:!text-base md:!text-lg">
                <div className="min-w-0">
                    <div>
                        <div className="text-slate-600 dark:text-slate-400">
                            <div className="pt-5 ">
                                <p className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "FIRST_PARAGRAPH",
                                    )}
                                </p>
                                <p className="text-slate-600 dark:text-slate-400">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "FIRST_PARAGRAPH2",
                                    )}
                                </p>
                                <p className="pt-3text-slate-600 dark:text-slate-400">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "SECOND_PARAGRAPH2",
                                    )}
                                </p>
                            </div>

                            <div className="pt-10">
                                <p className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "AUTH_PARAGRAPH",
                                    )}
                                </p>
                                <p className="text-slate-600 dark:text-slate-400">
                                    <span className="">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "AUTH_PARAGRAPH2",
                                        )}
                                    </span>
                                    <span className="">
                                        {" "}
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/documentation/authentication`}
                                            className="text-blue-500 underline hover:text-blue-700"
                                        >
                                            #authentication
                                        </NavLink>
                                    </span>
                                </p>
                            </div>

                            <div className="pt-5">
                                <p className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "PAYMENT_PARAGRAPH1",
                                    )}
                                </p>
                                <p className="text-slate-600 dark:text-slate-400">
                                    <span className="">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "PAYMENT_PARAGRAPH",
                                        )}
                                    </span>
                                    <span className="">
                                        {" "}
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/documentation/payment`}
                                            className="text-blue-500 underline hover:text-blue-700"
                                        >
                                            #payment
                                        </NavLink>
                                    </span>
                                </p>
                            </div>

                            <div className="pt-5">
                                <p className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "TRANSFER_PARAGRAPH1",
                                    )}
                                </p>
                                <p className="text-slate-600 dark:text-slate-400">
                                    <span className="">
                                        {" "}
                                        {translate(
                                            "DOC_API_PAGE",
                                            "TRANSFER_PARAGRAPH",
                                        )}
                                    </span>
                                    <span className="">
                                        {" "}
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/documentation/transfer`}
                                            className="text-blue-500 underline hover:text-blue-700"
                                        >
                                            #transfer
                                        </NavLink>
                                    </span>
                                </p>
                            </div>

                            <div className="pt-5 ">
                                <p className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "WEBHOOK_PARAGRAPH",
                                    )}
                                </p>
                                <p className="text-slate-600 dark:text-slate-400">
                                    <span className="">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "WEBHOOK_PARAGRAPH2",
                                        )}
                                    </span>
                                    <span className="">
                                        {" "}
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/documentation/webhook`}
                                            className="text-blue-500 underline hover:text-blue-700"
                                        >
                                            #webhook
                                        </NavLink>
                                    </span>
                                </p>
                            </div>

                            <div className="pt-10">
                                <p className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "PLUGIN_PARAGRAPH1",
                                    )}
                                </p>
                                <p className="text-slate-600 dark:text-slate-400">
                                    <span className="">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "PLUGIN_PARAGRAPH_2-1",
                                        )}
                                    </span>
                                    <span className="">
                                        {" "}
                                        <a
                                            href="https://infinexia.com/plugin/infinexia-gateway.zip"
                                            className="text-blue-500 underline hover:text-blue-700"
                                            download={true}
                                        >
                                            {translate(
                                                "HELPCENTER_API",
                                                "Api_Integration_Plugin2-2",
                                            )}
                                        </a>
                                    </span>
                                </p>
                            </div>

                            <div className="pt-10">
                                <p className="text-slate-600 dark:text-slate-400">
                                    <span className="">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "FAQ_PARAGRAPH",
                                        )}
                                    </span>
                                    <span className="">
                                        {" "}
                                        <NavLink
                                            to={
                                                lang
                                                    ? `/${lang}/help-center/intro`
                                                    : "/help-center/intro"
                                            }
                                            className="text-blue-500 underline hover:text-blue-700"
                                        >
                                            {translate(
                                                "HOME_PAGE",
                                                "Help_Center",
                                            )}
                                        </NavLink>
                                    </span>
                                </p>
                                <div className="pt-2">
                                    <p className="text-slate-600 dark:text-slate-400">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "SUPPORT_PARAGRAPH",
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Introduction;
