import React from "react";
import FourCubesSvg from "../../Components/Extra/Svg/FourCubesSvg";
import FourBookFaqSvg from "../../Components/Extra/Svg/FourBookFaqSvg";
import BookOpenCoverSvg from "../../Components/Extra/Svg/BookOpenCoverSvg";
import BookFaqSvg from "../../Components/Extra/Svg/BookFaqSvg";
import BooksFaqBannerSvg from "../../Components/Extra/Svg/BooksFaqBannerSvg";
import { useNavigate } from "react-router-dom";

interface BannerIntroProps {
    title: string;
    onClick?: () => void; // Propriété pour gérer les clics
}

const BannerIntro: React.FC<BannerIntroProps> = ({ title, onClick }) => {
    return (
        <div
            className="h-16 flex md:!justify-start sm:justify-center mb-5 md:!ml-5 sm:ml-0 items-center"
            onClick={onClick} // Gestionnaire d'événements
            style={{ cursor: onClick ? "pointer" : "default" }} // Curseur pointer si cliquable
        >
            <BooksFaqBannerSvg />
            <span className="font-bold md:text-2xl sm:text-xl text-blue-600 ml-2">
                {title}
            </span>
        </div>
    );
};

export default BannerIntro;
