import React from "react";

const BookIntroFaqSvg = () => {
    return (
        <svg
            className="fill-blue-600 mr-2"
            width="18"
            height="18"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill=""
                d="M96 0C43 0 0 43 0 96L0 416c0 53 43 96 96 96l288 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L384 0 96 0zm0 384l256 0 0 64L96 448c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16zm16 48l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
            />
        </svg>
    );
};

export default BookIntroFaqSvg;
