import React, { useState, useEffect } from "react";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../../globalState/hook";
import LanguageSwitcher from "../../Account/Language/Language";
import translator from "../../../Components/Extra/Translation/Translate";

const logo = "/dist/image/Logo-Infinexia.webp";

const PotsHeader = () => {
    const location = useLocation();
    const { isAuthenticated } = useAppSelector((state) => state.auth);
    const [toggleMenu, setToggleMenu] = useState(false);
    const isHome = true;
    // const isHome = ["/", "/home", "/accueil"].includes(location.pathname);
    const [scroll, setScroll] = useState(isHome);
    const { lang } = useParams();
    const [has_dropdown, setDropdwon] = useState(false);
    const [currentTarget, setCurrentTarget] = useState(
        typeof window !== "undefined" ? window.location.hash : "",
    );

    //Change background header on scroll
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 40 || !isHome)
            setScroll(true); // => scroll position
        else setScroll(false);
    };

    const handleDropdown = () => {
        setDropdwon(!has_dropdown);
    };

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location.pathname]);

    useEffect(() => {
        const targetElement = document.getElementById(
            currentTarget.replace("#", ""),
        );
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentTarget]);

    return (
        <div
            className={`${
                scroll ? "bg-white text-black" : "text-white  "
            }  transition-all ${
                isHome ? "fixed" : "sticky"
            } mdg-shadow top-0 w-full z-50 font-medium text-lg`}
        >
            <nav className=" sticky top-0 ">
                <div className="flex px-2 lg:!px-24  items-center h-[66px] md:h-full">
                    <div className="xl:w-72  w-96">
                        <NavLink to={lang ? `/${lang}` : "/"}>
                            <img
                                className={`xl:w-72 w-96 ${scroll ? "invert" : "invert-0"}`}
                                src={"/dist/image/Logo-Infinexia.webp"}
                                alt=""
                            />
                        </NavLink>
                    </div>
                    <div className="w-full px-2 lg:!px-10 justify-end">
                        <div className=" hidden xl:flex w-full justify-end">
                            <div className=" xl:ml-20 flex gap-3 py-5">
                                <NavLink
                                    to="/"
                                    className="btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-1"
                                >
                                    {translator().translate(
                                        "POTS_PAGE",
                                        "ACCESS_SITE",
                                    )}
                                </NavLink>
                                <div className="intro-none w-4 h-4 mr-1 pt-1 sm:mr-6 language">
                                    <LanguageSwitcher doc />
                                </div>
                            </div>
                        </div>

                        <div className=" xl:hidden  text-black flex justify-end ">
                            <div
                                className={`${
                                    scroll ? " text-black" : "text-white"
                                } intro-none w-8 h-6 mt-[4px] md:!mt-[2px] mr-3 sm:mr-6 language`}
                            >
                                <LanguageSwitcher doc />
                            </div>
                            <button onClick={() => setToggleMenu(!toggleMenu)}>
                                <Bars3Icon
                                    className={`h-8 w-8 ${
                                        scroll ? " text-black" : "text-white"
                                    } `}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`text-black fixed z-40 w-full  bg-gray-100 overflow-hidden flex text-center items-center flex-col xl:hidden gap-12  origin-top duration-700 ${
                        !toggleMenu ? "h-0" : "h-full"
                    }`}
                >
                    <div className="py-8 w-full">
                        <ul className="flex flex-col gap-3 font-bold tracking-wider w-full">
                            <li>
                                <NavLink
                                    to="/"
                                    className="btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-1"
                                >
                                    {translator().translate(
                                        "POTS_PAGE",
                                        "ACCESS_SITE",
                                    )}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* mobile navigation */}
            </nav>
        </div>
    );
};

export default PotsHeader;
