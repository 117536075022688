import React, { useState } from "react";
import translator from "./../../Components/Extra/Translation/Translate";
import { useNavigate } from "react-router-dom";
import CubeSvg from "../../Components/Extra/Svg/CubeSvg";
import BookSearchFaqSvg from "../../Components/Extra/Svg/BookSearchFaqSvg";
import SearchFaqSvg from "../../Components/Extra/Svg/SearchFaqSvg";

interface FAQItem {
    title: string;
    description: string;
    url: string;
}

const FAQSearch = () => {
    const { translate } = translator();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const navigate = useNavigate();

    // Exemple de données : titres, descriptions et liens
    const faqData: FAQItem[] = [
        {
            title: translate("HELPCENTER", "Start_Infinexia"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Start_Infinexia")}`,
            url: "/help-center/getting-start/open-individual-account",
        },
        {
            title: translate("HELPCENTER", "Individual_Account"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Open_Individual")}`,
            url: "/help-center/getting-start/open-individual-account",
        },
        {
            title: translate("HELPCENTER", "Business_Account"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Open_Business")}`,
            url: "/help-center/getting-start/open-business-account",
        },
        {
            title: translate("HELPCENTER", "Select_Offer"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Subscribe_Offer")}`,
            url: "/help-center/getting-start/subscription",
        },
        {
            title: translate("HELPCENTER", "Currencies_Accounts"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Add_Currencie_Account")}`,
            url: "/help-center/accounts-management/currencies-accounts",
        },
        {
            title: translate("HELPCENTER", "Safes_Accounts"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Add_Safe_Account")}`,
            url: "/help-center/accounts-management/safes-accounts",
        },
        {
            title: translate("HELPCENTER", "Jackpots_Accounts"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Add_Jackpot_Account")}`,
            url: "/help-center/accounts-management/jackpots-accounts",
        },
        {
            title: translate("HELPCENTER", "SubAccounts"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Add_SubAccount")}`,
            url: "/help-center/accounts-management/subaccounts",
        },
        {
            title: translate("HELPCENTER", "Receive_Money"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "How_Receive_Money")}`,
            url: "/help-center/transactions/receive-money",
        },
        {
            title: translate("HELPCENTER", "Send_Money"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "How_Send_Money")}`,
            url: "/help-center/transactions/send-money",
        },
        {
            title: translate("HELPCENTER", "Bank_Transfers"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "How_Bank_Transfers")}`,
            url: "/help-center/transactions/bank-transfers-and-direct-debits",
        },
        {
            title: translate("HELPCENTER", "Physical_Cards"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Order_Physical")}`,
            url: "/help-center/cards/physical-cards",
        },
        {
            title: translate("HELPCENTER", "Cartes virtuelles"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Generate_Virtual")}`,
            url: "/help-center/cards/virtual-cards",
        },
        {
            title: translate("HELPCENTER", "Settings_Security"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Modify_Profile")}`,
            url: "/help-center/settings-security/edit-profile",
        },
        {
            title: translate("HELPCENTER", "Settings_Security"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Modify_Login_PassWord")}`,
            url: "/help-center/settings-security/login-password",
        },
        {
            title: translate("HELPCENTER", "Settings_Security"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Modify_Transac_PassWord")}`,
            url: "/help-center/settings-security/transaction-password",
        },
        {
            title: translate("HELPCENTER", "Settings_Security"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Activate_2FA")}`,
            url: "/help-center/settings-security/2fa-authentication",
        },
        {
            title: translate("HELPCENTER", "Settings_Security"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Deactivate_2FA")}`,
            url: "/help-center/settings-security/2fa-authentication",
        },
        {
            title: translate("HELPCENTER", "Earning_Rewards"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Sponsor_Friends")}`,
            url: "/help-center/sponsorship/invite-a-friends",
        },
        {
            title: translate("HELPCENTER", "Ambassador"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Become_Ambassador")}`,
            url: "/help-center/sponsorship/ambassador",
        },
        {
            title: translate("HELPCENTER", "Customer_Service"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "How_Customer_Service")}`,
            url: "/help-center/various/contact-customer-service",
        },
        {
            title: translate("HELPCENTER", "Working_With_Infinexia"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "How_Working_With_Infinexia")}`,
            url: "/help-center/various/working-with-infinexia",
        },
        {
            title: translate("HELPCENTER", "Press service"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "How_Press service")}`,
            url: "/help-center/various/press-service",
        },
        {
            title: translate("HELPCENTER", "Estimates_Invoices"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Config_Estimates_Invoices")}`,
            url: "/help-center/estimates-invoices/configure-estimates-invoices",
        },
        {
            title: translate("HELPCENTER", "Estimates_Invoices"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Create_Estimates")}`,
            url: "/help-center/estimates-invoices/create-send-estimates",
        },
        {
            title: translate("HELPCENTER", "Estimates_Invoices"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Create_Invoices")}`,
            url: "/help-center/estimates-invoices/create-send-invoices",
        },
        {
            title: translate("HELPCENTER", "Payment_Api"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Api_Access")}`,
            url: "/help-center/payment-api/request-api-access",
        },
        {
            title: translate("HELPCENTER", "Payment_Api"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "API_Intégration")}`,
            url: "/help-center/payment-api/api-integration",
        },
        {
            title: translate("HELPCENTER", "Payment_Api"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER_API", "Api_Integration_Plugin2-2")}`,
            url: "/help-center/payment-api/plugin-integration",
        },
    ];

    // Filtrer les résultats basés sur le terme de recherche (3 lettres minimum)
    const filteredFAQ = faqData.filter(
        (item) =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    // Gestion du changement de texte
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        setShowSuggestions(value.length >= 3);
    };

    return (
        <div className="">
            <div className="relative sm:w-[90%] sm:!max-w-[90%] md:w-[600px] md:!max-w-[600px] mx-auto mt-2">
                {/* Barre de recherche avec icône à droite */}
                <div className="relative">
                    {/* Champ de recherche */}
                    <input
                        type="text"
                        placeholder={translate(
                            "HELPCENTER",
                            "Search_Placehoder",
                        )}
                        className="w-full px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-600 text-gray-800 dark:!text-gray-800 pr-10" // Ajout du padding droit pour l'icône
                        value={searchTerm}
                        onChange={handleInputChange}
                        onFocus={() =>
                            setShowSuggestions(searchTerm.length >= 3)
                        }
                        onBlur={() =>
                            setTimeout(() => setShowSuggestions(false), 200)
                        } // Délai pour permettre de cliquer sur les suggestions
                    />

                    {/* Icône de recherche */}
                    <SearchFaqSvg />
                </div>

                {/* Menu déroulant des suggestions */}
                {showSuggestions && filteredFAQ.length > 0 && (
                    <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto text-gray-600">
                        {filteredFAQ.map((item, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 hover:bg-blue-600 cursor-pointer hover:text-white border-b border-t flex items-center"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(item.url);
                                    setShowSuggestions(false);
                                }}
                            >
                                {/* SVG et contenu à l'intérieur du même conteneur */}
                                <div className="flex items-center hover:text-white fill-blue-600 hover:!fill-white">
                                    {/* Style conditionnel pour le SVG */}
                                    <div className="mr-2">
                                        <BookSearchFaqSvg />
                                    </div>
                                    <div>
                                        <div className="text-sm md:!text-base font-bold">
                                            {item.title}
                                        </div>
                                        <div>{item.description}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Message si aucune suggestion */}
                {showSuggestions && filteredFAQ.length === 0 && (
                    <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg p-4 text-gray-500">
                        {translate("HELPCENTER", "No_Result")}
                        <br></br>
                        {translate("HELPCENTER", "No_Result_Suggestion")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FAQSearch;
