export const AuthResponses200 = {
    title: "Success Response",
    fields: [
        {
            name: "fee",
            required: "required",
            type: "number",
            description: "Fee in percent",
        },
        {
            name: "assets",
            required: "required",
            type: "array",
            description: "List of assets with details",
            subfields: [
                {
                    name: "name",
                    required: "required",
                    type: "string",
                    description: "Asset name (e.g., Euro)",
                },
                {
                    name: "abbr",
                    required: "required",
                    type: "string",
                    description: "Wallet identifier (e.g., EUR)",
                },
                {
                    name: "logo",
                    required: "required",
                    type: "string",
                    description: "URL of the logo",
                },
            ],
        },
    ],
};

export const AuthResponses401 = {
    title: "Unauthorized",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_MERCHANT",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Merchant invalid",
        },
    ],
};

// InitializePaymentResponse
export const InitializePaymentResponses200 = {
    title: "Success Response",
    fields: [
        {
            name: "payment_url",
            required: "required",
            type: "string",
            description: "Redirect the user to this payment page",
        },
        {
            name: "token",
            required: "required",
            type: "string",
            description: "Reference of the order on Infinexia",
        },
        {
            name: "code",
            required: "required",
            type: "string",
            description: "PAYMENT_INITIALIZED",
        },
        {
            name: "status",
            required: "required",
            type: "string",
            description: "ok",
        },
    ],
};

export const InitializePaymentResponses401 = {
    title: "401 Unauthorized",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_MERCHANT",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Merchant invalid",
        },
    ],
};

export const InitializePaymentResponses403 = {
    title: "403 Forbidden",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "DISABLED_SERVICE",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description:
                "Payment is disabled, please enable it on your profile",
        },
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INACTIVE_MERCHAND",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Merchant status has been desactivated",
        },
    ],
};

export const InitializePaymentResponses422 = {
    title: "Unprocessable Entity",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_WALLET",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Invalid wallet",
        },
    ],
};

// CheckStatusPayment
export const CheckStatusPaymentResponses200 = {
    title: "Success Response",
    fields: [
        {
            name: "transaction_id",
            required: "required",
            type: "string",
            description: "Your unique transaction ID",
        },
        {
            name: "status",
            required: "required",
            type: "string",
            description: `"completed" | "pending" | "canceled" | "expired"`,
        },
        {
            name: "transaction",
            required: "required",
            type: "object",
            description: "Transaction details",
            subfields: [
                {
                    name: "transaction_id",
                    required: "required",
                    type: "string",
                    description: "Your unique transaction ID",
                },
                {
                    name: "status",
                    required: "required",
                    type: "string",
                    description: `"completed" | "pending" | "canceled" | "expired"`,
                },
                {
                    name: "token",
                    required: "required",
                    type: "string",
                    description: "Reference of the order on Infinexia",
                },
                {
                    name: "wallet",
                    required: "required",
                    type: "string",
                    description: "EUR",
                },
                {
                    name: "amount",
                    required: "required",
                    type: "number",
                    description: "Transaction amount",
                },
                {
                    name: "currency",
                    required: "required",
                    type: "string",
                    description: "Transaction currency",
                },
                {
                    name: "reference",
                    required: "required",
                    type: "string",
                    description: "Order reference",
                },
            ],
        },
    ],
};

export const CheckStatusPaymentResponses422 = {
    title: "Unprocessable Entity",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_TRANSACTION",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Invalid transaction",
        },
    ],
};

// CheckFees
export const CheckFeesResponses200 = {
    title: "Success Response",
    fields: [
        {
            name: "fee",
            required: "required",
            type: "number",
            description: "Fee in percent",
        },
        {
            name: "assets",
            required: "required",
            type: "array",
            description: "List of assets with details",
            subfields: [
                {
                    name: "name",
                    required: "required",
                    type: "string",
                    description: "Asset name (e.g., Euro)",
                },
                {
                    name: "abbr",
                    required: "required",
                    type: "string",
                    description: "Wallet identifier (e.g., EUR)",
                },
                {
                    name: "logo",
                    required: "required",
                    type: "string",
                    description: "URL of the logo",
                },
            ],
        },
    ],
};

export const CheckFeesResponses401 = {
    title: "Unauthorized",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_MERCHANT",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Merchant invalid",
        },
    ],
};

// InitializeTransfer
export const InitializeTransferResponses200 = {
    title: "Success Response",
    fields: [
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Transfer done successfully",
        },
        {
            name: "code",
            required: "required",
            type: "string",
            description: "TRANSFERT_SUCCESS",
        },
        {
            name: "status",
            required: "required",
            type: "string",
            description: "SUCCESS",
        },
        {
            name: "transaction",
            required: "required",
            type: "object",
            description: "Transaction details",
            subfields: [
                {
                    name: "inf_transaction_id",
                    required: "required",
                    type: "string",
                    description: "Transaction reference on Infinexia",
                },
                {
                    name: "status",
                    required: "required",
                    type: "string",
                    description: `"completed" | "pending" | "canceled"`,
                },
                {
                    name: "wallet",
                    required: "required",
                    type: "string",
                    description: "EUR",
                },
                {
                    name: "amount",
                    required: "required",
                    type: "number",
                    description: "Transaction amount",
                },
                {
                    name: "partner",
                    required: "required",
                    type: "string",
                    description: "Partner ID",
                },
                {
                    name: "transaction_id",
                    required: "required",
                    type: "string",
                    description: "Your unique transaction ID",
                },
            ],
        },
    ],
};

export const InitializeTransferResponses400 = {
    title: "Bad Request",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INSUFFICIENT_BALANCE",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Insufficient balance",
        },
        {
            name: "code",
            required: "required",
            type: "string",
            description: "UNKNOWN_ERROR",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "An error was occured",
        },
        {
            name: "code",
            required: "required",
            type: "string",
            description: "PARTNER_NOT_FOUND",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "An error was occured",
        },
    ],
};

export const InitializeTransferResponses401 = {
    title: "Unauthorized",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_MERCHANT",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Merchant invalid",
        },
    ],
};

export const InitializeTransferResponses403 = {
    title: "Bad Request",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "DISABLED_SERVICE",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Merchant status has been desactivated",
        },
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INACTIVE_MERCHAND",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description:
                "Payment is disabled, please enable it on your profile",
        },
    ],
};

export const InitializeTransferResponses409 = {
    title: "Conflict",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "DUPLICATE_TRANSACTION_ID",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Duplicate transaction id",
        },
    ],
};

export const InitializeTransferResponses422 = {
    title: "Unprocessable Entity",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_WALLET",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Invalid wallet",
        },
    ],
};

// CheckStatusTransfer
export const CheckStatusTransferResponses200 = {
    title: "Success Response",
    fields: [
        {
            name: "transaction_id",
            required: "required",
            type: "string",
            description: "Your unique transaction ID",
        },
        {
            name: "status",
            required: "required",
            type: "string",
            description: `"completed" | "pending" | "canceled"`,
        },
        {
            name: "transaction",
            required: "required",
            type: "object",
            description: "Transaction details",
            subfields: [
                {
                    name: "inf_transaction_id",
                    required: "required",
                    type: "string",
                    description: "Transaction reference on Infinexia",
                },
                {
                    name: "status",
                    required: "required",
                    type: "string",
                    description: `"completed" | "pending" | "canceled"`,
                },
                {
                    name: "wallet",
                    required: "required",
                    type: "string",
                    description: "EUR",
                },
                {
                    name: "amount",
                    required: "required",
                    type: "number",
                    description: "Transaction amount",
                },
                {
                    name: "partner",
                    required: "required",
                    type: "string",
                    description: "Partner ID",
                },
                {
                    name: "transaction_id",
                    required: "required",
                    type: "string",
                    description: "Your unique transaction ID",
                },
            ],
        },
    ],
};

export const CheckStatusTransferResponses422 = {
    title: "Unprocessable Entity",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_TRANSACTION",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Invalid transaction",
        },
    ],
};

export const WebhookResponses200 = {
    title: "OK",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "ok",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "ok",
        },
    ],
};

export const WebhookResponses401 = {
    title: "Unauthorized",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INVALID_MERCHANT",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Merchant invalid",
        },
    ],
};

export const WebhookResponses500 = {
    title: "Internal server error",
    fields: [
        {
            name: "code",
            required: "required",
            type: "string",
            description: "INTERNAL_SERVER_ERROR",
        },
        {
            name: "message",
            required: "required",
            type: "string",
            description: "Internal server error",
        },
    ],
};
