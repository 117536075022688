import React from "react";

interface SubTitleProps {
    id?: string;
    title: string;
    disableDarkMode?: boolean;
}

const SubTitle: React.FC<SubTitleProps> = ({ id, title, disableDarkMode }) => {
    return (
        <h2
            id={id}
            data-scrollspy-target=""
            className={`sm!text:base md:!text-lg font-[650] text-slate-800 ${
                !disableDarkMode ? "dark:text-slate-800" : ""
            } scroll-mt-24`}
        >
            {title}
        </h2>
    );
};

export const SubTitle2: React.FC<SubTitleProps> = ({
    title,
    disableDarkMode,
}) => {
    return (
        <h3
            className={`text-sm font-[400] text-slate-800 ${
                !disableDarkMode ? "dark:text-slate-800" : ""
            } scroll-mt-24`}
        >
            {title}
        </h3>
    );
};

export const SubTitle3: React.FC<SubTitleProps> = ({
    title,
    disableDarkMode,
}) => {
    return (
        <h4
            className={`text-sm font-[650] text-slate-800 ${
                !disableDarkMode ? "dark:text-slate-800" : ""
            } scroll-mt-24`}
        >
            {title}
        </h4>
    );
};

export default SubTitle;
