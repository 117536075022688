import React, { useEffect, useState } from "react";

import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";
import { NavLink, useLocation, useParams } from "react-router-dom";

const PaymentOld = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "PAYMENT");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "PAYMENT");
        }
    };
    return (
        <>
            <Banner title={translate("DOC_API_PAGE", "PAYMENT")}></Banner>
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/documentation/introduction`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("DOC_API_PAGE", "DOCUMENTATION")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="flex xl:space-x-12 sm:!text-sm md:!text-base text-slate-600 dark:text-slate-400 font-normal">
                <div className="w-full">
                    <div>
                        <header className="mb-6">
                            <h1
                                id="pay-with"
                                data-scrollspy-target=""
                                className="sm:!text-base md:!text-lg font-bold !text-black dark:text-slate-200"
                            >
                                {translate(
                                    "DOC_API_PAGE",
                                    "API_Service_Description",
                                )}
                            </h1>
                        </header>
                        <div className="space-y-6">
                            <section id="base-url" className="space-y-2">
                                <div className="space-y-1">
                                    <SubTitle id="base-url" title="Base url" />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Base_Url_Description",
                                        )}
                                    </p>

                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono flex justify-between">
                                            <span className="text-purple-500">
                                                https://api.infinexia.com/payment
                                            </span>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text="https://api.infinexia.com/payment"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </code>
                                    </pre>
                                </div>
                            </section>

                            <section
                                id="initialize-payment"
                                className="space-y-2 pt-5"
                            >
                                <div className="space-y-1">
                                    <SubTitle
                                        id="initialize-payment"
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "API_Initialize_Payment",
                                        )}
                                    />

                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Initialize_Payment_Description",
                                        )}
                                    </p>
                                    <SubTitle3 title="Endpoint"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <div className="flex justify-between">
                                            <div>
                                                <code className="font-pt-mono  text-lg font-[700] text-purple-500 text-">
                                                    POST
                                                </code>{" "}
                                                <code className="font-pt-mono ">
                                                    https://api.infinexia.com/payment/initialize
                                                </code>
                                            </div>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text="https://api.infinexia.com/payment/initialize"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </div>
                                    </pre>
                                    <SubTitle3 title="Request Header"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "Content-Type": "application/json",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-id": "YOUR_APP_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-key": "YOUR_APP_KEY",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="Request Body"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id": "INF-12345678",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "wallet": "USD",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "amount": 100,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "currency": "USD",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "call_back_url": "string",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "success_url": "string",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "fail_url": "string",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "description": "First Payment",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "payee_name": "John",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "description": "First Payment",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "reference": "#order-1"
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="200 Response Ok"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "payment_url":
                                            "https://..?ref=order-token",
                                            //redirect the user on this payment
                                            page
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "token": "xxxx-xxxx-x-x--xx--x", //
                                            reference of the order on Infinexia
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": PAYMENT_INITIALIZED,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "status": "ok",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_MERCHANT,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Merchant invalid",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_WALLET,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Invalid wallet",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="403 Forbidden"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": DISABLED_SERVICE,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Payemnt is disabled,
                                            please enable it on your profile.",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INACTIVE_MERCHAND,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Merchant status has been
                                            desactivated.",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </section>

                            <section
                                id="check-status"
                                className="pt-10 space-y-2"
                            >
                                <div className="space-y-1">
                                    <SubTitle
                                        id="check-status"
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "API_Check_Status",
                                        )}
                                    />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Check_Status_Description",
                                        )}
                                    </p>
                                    <SubTitle3 title="Endpoint"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <div className="flex justify-between">
                                            <div>
                                                <code className="font-pt-mono  text-lg font-[700] text-purple-500 text-">
                                                    POST
                                                </code>{" "}
                                                <code className="font-pt-mono ">
                                                    https://api.infinexia.com/payment/check-status
                                                </code>
                                            </div>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text="https://api.infinexia.com/payment/check-status"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </div>
                                    </pre>
                                    <SubTitle3 title="Request Header"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "Content-Type": "application/json",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-id": "YOUR_APP_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-key": "YOUR_APP_KEY",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="Request Body"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id":
                                            "YOUR_UNIQUE_TRANSACTION_ID",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="200 OK"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id":
                                            "YOUR_UNIQUE_TRANSACTION_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "status": "completed" //{" "}
                                            <span className="text-green-600">
                                                "completed" | "pending" |
                                                "canceled" | "expired"
                                            </span>
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction":{"{"}
                                            <code className="font-pt-mono block ml-5">
                                                "transaction_id":
                                                "YOUR_UNIQUE_TRANSACTION_ID",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "status": "completed" //{" "}
                                                <span className="text-green-600">
                                                    "completed" | "pending" |
                                                    "canceled" | "expired"
                                                </span>
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "token": "xxxx-xxxx-x-x--xx--x",
                                                // reference of the order on
                                                Infinexia
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "wallet": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "amount": 100,
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "currency": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "reference": "#order-1"
                                            </code>
                                            {"}"}
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_TRANSACTION,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Invalid transaction",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </section>

                            <section
                                id="get-fee-and-available-wallets"
                                className="pt-10 space-y-2"
                            >
                                <div className="space-y-1">
                                    <SubTitle
                                        id="get-fee-and-available-wallets"
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "API_Get_Fee_And_Available_Wallets",
                                        )}
                                    />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Get_Fee_And_Available_Wallets_Description",
                                        )}
                                    </p>
                                    <SubTitle3 title="Endpoint"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <div className="flex justify-between">
                                            <div>
                                                <code className="font-pt-mono  text-lg font-[700] text-purple-500 text-">
                                                    GET
                                                </code>{" "}
                                                <code className="font-pt-mono ">
                                                    https://api.infinexia.com/payment
                                                </code>
                                            </div>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text=" https://api.infinexia.com/payment"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </div>
                                    </pre>
                                    <SubTitle3 title="Request Header"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "Content-Type": "application/json",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-id": "YOUR_APP_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-key": "YOUR_APP_KEY",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>

                                    <SubTitle3 title="200 OK"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "fee": 2,5 //in percent
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "assets": [
                                            <code className="font-pt-mono block ml-5">
                                                {"{"}
                                                <code className="font-pt-mono block ml-5">
                                                    "name":"Dollar",
                                                </code>
                                                <code className="font-pt-mono block ml-5">
                                                    "abbr":"USD", // wallet
                                                    identifier
                                                </code>
                                                <code className="font-pt-mono block ml-5">
                                                    "logo":"logo_url", // URL of
                                                    the logo on
                                                    https://api.infinexia.com/
                                                </code>
                                                {"}"}
                                            </code>
                                            ]
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_MERCHANT,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Merchant invalid",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </section>
                            <section id="webhook" className="pt-10 space-y-2">
                                <div className="space-y-1">
                                    <SubTitle id="webhook" title="Webhook" />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Webhook_Description",
                                        )}
                                    </p>

                                    <SubTitle3 title="Data"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id":
                                            "YOUR_UNIQUE_TRANSACTION_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "status": "completed" //{" "}
                                            <span className="text-green-600">
                                                "completed" | "pending" |
                                                "canceled" | "expired"
                                            </span>
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction":{"{"}
                                            <code className="font-pt-mono block ml-5">
                                                "transaction_id":
                                                "YOUR_UNIQUE_TRANSACTION_ID",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "status": "completed" //{" "}
                                                <span className="text-green-600">
                                                    "completed" | "pending" |
                                                    "canceled" | "expired"
                                                </span>
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "token": "xxxx-xxxx-x-x--xx--x",
                                                // reference of the order on
                                                Infinexia
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "wallet": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "amount": 100,
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "currency": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "reference": "#order-1"
                                            </code>
                                            {"}"}
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <SecondaryNav />
            </article>
        </>
    );
};

export default PaymentOld;
