import React, { useState, useEffect } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";

const Accounts = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            case "currencies-accounts":
                return translate("HELPCENTER", "Currencies_Accounts");
            case "safes-accounts":
                return translate("HELPCENTER", "Safes_Accounts");
            case "jackpots-accounts":
                return translate("HELPCENTER", "Jackpots_Accounts");
            case "subaccounts":
                return translate("HELPCENTER", "SubAccounts");
            default:
                return translate("HELPCENTER", "Accounts_Gestion");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            case "currencies-accounts":
                return translate("HELPCENTER", "Currencies_Accounts");
            case "safes-accounts":
                return translate("HELPCENTER", "Safes_Accounts");
            case "jackpots-accounts":
                return translate("HELPCENTER", "Jackpots_Accounts");
            case "subaccounts":
                return translate("REF_ACCOUNT", "SUB_ACCOUNTS");
            default:
                return translate("HELPCENTER", "Accounts_Gestion");
        }
    };

    const renderContentForStep = () => {
        switch (currentStep) {
            case "currencies-accounts":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="currencies-accounts"
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "The_Currencies_Accounts",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "More20_Currencies",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Add_Currencies_Account",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "My_Transactions",
                                            "WALLETS",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Start_Click_On",
                                        )}{" "}
                                        <strong>« + »</strong>{" "}
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "To_Add_Account",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Account_Type",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ACCOUNTS",
                                                "Currencie_Account",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Currencie",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                );
            case "safes-accounts":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="safes-accounts"
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "The_Safe_Accounts",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Objectif_Safe_Accounts",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Create_Safe",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "My_Transactions",
                                            "WALLETS",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Start_Click_On",
                                        )}{" "}
                                        <strong>« + »</strong>{" "}
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "To_Add_Account",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Account_Type",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ACCOUNTS",
                                                "Safe",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Currency",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Indicate_Name_Object",
                                        )}
                                    </li>
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER",
                                                "Validate",
                                            )}
                                            »
                                        </strong>{" "}
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "For_Create_Coffre",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "After_Safe_Creation",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "subaccounts":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="subaccounts"
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "The_SubAccounts",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "The_SubAccounts_Objectif",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Create_Sub",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "My_Transactions",
                                            "WALLETS",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Start_Click_On",
                                        )}{" "}
                                        <strong>« + »</strong>{" "}
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "To_Add_Account",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Account_Type",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ACCOUNTS",
                                                "Select_Sub",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Currency",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Add_Name_Sub",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER",
                                                "Validate",
                                            )}
                                            »
                                        </strong>{" "}
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "For_Create_Sub",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Note_Sub",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "jackpots-accounts":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="jackpots-accounts"
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "The_Jackpots",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "The_Jackpots_Objectif",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-0">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Create_Jackpot",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "My_Transactions",
                                            "WALLETS",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Start_Click_On",
                                        )}{" "}
                                        <strong>« + »</strong>{" "}
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "To_Add_Account",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Account_Type",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ACCOUNTS",
                                                "Jackpot",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Add_Image",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Currency",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Add_Name_Jackpot",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Add_Desc_Object",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Select_Switch",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER",
                                                "Validate",
                                            )}
                                            »
                                        </strong>{" "}
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "For_Create_Jackpot",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="space-y-0">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Share_Jackpot",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Step_Share_Jackpot",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "On_Page")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "My_Transactions",
                                                "WALLETS",
                                            )}
                                            »
                                        </strong>{" "}
                                        {translate("HELPCENTER", "Click_To")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ACCOUNTS",
                                                "Jackpot_Plus",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Click_Menu",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ACCOUNTS",
                                                "Settings",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Copy_Link",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Share_Link",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Jackpot_Note1",
                                    )}
                                </p>
                                <p className="pt-1 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Jackpot_Note2",
                                    )}
                                </p>
                                <p className="pt-1 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Jackpot_Note3",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ACCOUNTS",
                                        "Jackpot_Note4",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            default:
                return null; // Aucun contenu par défaut si le type de compte n'est pas reconnu
        }
    };

    return (
        <>
            <Banner title={getBannerTitle()} />
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/help-center/accounts-management`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("HELPCENTER", "Accounts_Gestion")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div>
                        <div className="text-slate-600 dark:text-slate-600 space-y-6">
                            {/* Affiche le contenu selon currentStep */}
                            {renderContentForStep()}
                        </div>
                    </div>
                    <div className="hidden md:block pt-10">
                        <p className="sm:!text-sm md:!text-base text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}{" "}
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700 font-normal"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                    <div className="md:hidden py-10">
                        <p className="sm:!text-sm md:!text-base text-center text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}
                        </p>
                        <p className="sm:!text-sm md:!text-base text-center text-black font-normal">
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Accounts;
