import React from "react";

const WalletSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 22 28"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.70"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-wallet"
        >
            <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />
            <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />
            <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />
        </svg>
    );
};

export default WalletSvg;
