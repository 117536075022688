import React, { useEffect, useState } from "react";

import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ChevronCloseSvg from "../../Components/Extra/Svg/ChevronCloseSvg";
import ChevronOpenSvg from "../../Components/Extra/Svg/ChevronOpenSvg";
import Samples from "../Layout/RequestSamples";
import {
    InitializePayment,
    CheckStatusPayment,
    CheckFees,
} from "../Layout/CodeRequestSamples";
import {
    CheckStatusPaymentSchema,
    GetFeesPaymentSchema,
    InitializePaymentSchema,
    RequestHeaderSchema,
} from "../Layout/CodeBodySchemas";
import BodySchema from "../Layout/BodySchemas";
import SamplesRequest from "../Layout/RequestSamples";
import SamplesResponses from "../Layout/ResponsesSamples";
import {
    CheckFeesResponses,
    CheckStatusPaymentResponses,
    InitializePaymentResponses,
} from "../Layout/CodeResponsesSamples";
import ResponsesSchema from "../Layout/ResponsesSchemas";
import {
    CheckFeesResponses200,
    CheckFeesResponses401,
    CheckStatusPaymentResponses200,
    CheckStatusPaymentResponses422,
    InitializePaymentResponses200,
    InitializePaymentResponses401,
    InitializePaymentResponses403,
    InitializePaymentResponses422,
} from "../Layout/CodeResponsesSchemas";

const Payment = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL
    const [isOpen, setIsOpen] = useState(false);
    const transaction_id = "{transaction_id}";

    type SectionKeys =
        | "base-url"
        | "initialize-endpoint"
        | "initialize-request"
        | "initialize-responses"
        | "initialize-200"
        | "initialize-401"
        | "initialize-422"
        | "initialize-403"
        | "check-endpoint"
        | "check-request"
        | "check-responses"
        | "check-200"
        | "check-422"
        | "get-fees-endpoint"
        | "get-fees"
        | "get-fees-responses"
        | "get-fees-200"
        | "get-fees-401";

    const [openSections, setOpenSections] = useState<
        Record<SectionKeys, boolean>
    >({
        "base-url": true,
        "initialize-endpoint": true,
        "initialize-request": true,
        "initialize-responses": true,
        "initialize-200": false,
        "initialize-401": false,
        "initialize-422": false,
        "initialize-403": false,
        "check-endpoint": true,
        "check-request": true,
        "check-responses": true,
        "check-200": false,
        "check-422": false,
        "get-fees-endpoint": true,
        "get-fees": true,
        "get-fees-responses": true,
        "get-fees-200": false,
        "get-fees-401": false,
    });

    const toggleSection = (section: SectionKeys) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1)); // Retire le '#' pour obtenir l'ID
            if (element) {
                element.scrollIntoView({ behavior: "smooth" }); // Défilement fluide
            }
        }
    }, [location]);

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "PAYMENT");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "PAYMENT");
        }
    };
    return (
        <>
            <Banner title={translate("DOC_API_PAGE", "PAYMENT")}></Banner>
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/documentation/introduction`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("DOC_API_PAGE", "DOCUMENTATION")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="w-full space-y-8 text-slate-600 dark:text-slate-400 font-normal sm:!text-sm md:!text-base">
                <div>
                    <header className="mb-5">
                        <h1
                            id="pay-with"
                            data-scrollspy-target=""
                            className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200"
                        >
                            {translate(
                                "DOC_API_PAGE",
                                "API_Service_Description",
                            )}
                        </h1>
                    </header>
                    {/**********  Fin du Header  **********/}
                    <div className="space-y-6">
                        {/**********  Initialize Payment Header  **********/}
                        <section
                            id="initialize-payment"
                            className="space-y-1 scroll-mt-64"
                        >
                            <SubTitle
                                id="initialize-payment"
                                title={translate(
                                    "DOC_API_PAGE",
                                    "API_Initialize_Payment",
                                )}
                            />
                            <p className="sm:!w-full md:!w-[50%]">
                                {translate(
                                    "DOC_API_PAGE",
                                    "API_Initialize_Payment_Description",
                                )}
                            </p>
                            {/**********  Initialize Payment Request  **********/}
                            <div className="grid grid-cols-1 md:!grid-cols-[55%_40%] gap-x-12 gap-y-8 !mt-5 sm:!w-full pb-12">
                                {/* Colonne gauche */}
                                <div>
                                    <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5">
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() =>
                                                toggleSection(
                                                    "initialize-request",
                                                )
                                            }
                                        >
                                            <SubTitle2 title="Request"></SubTitle2>
                                            {openSections[
                                                "initialize-request"
                                            ] ? (
                                                <ChevronCloseSvg />
                                            ) : (
                                                <ChevronOpenSvg />
                                            )}
                                        </div>
                                        {openSections["initialize-request"] && (
                                            <div className="mt-3 space-y-8">
                                                <div className="">
                                                    <BodySchema
                                                        title={
                                                            RequestHeaderSchema.title
                                                        }
                                                        fields={
                                                            RequestHeaderSchema.fields
                                                        }
                                                        isHeader={true}
                                                    />
                                                </div>
                                                <div className="pt-5 space-y-8">
                                                    <BodySchema
                                                        title={
                                                            InitializePaymentSchema.title
                                                        }
                                                        fields={
                                                            InitializePaymentSchema.fields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/*****  Initialize Payment Responses  *****/}
                                    <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5 !mt-5">
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() =>
                                                toggleSection(
                                                    "initialize-responses",
                                                )
                                            }
                                        >
                                            <SubTitle2 title="Responses"></SubTitle2>
                                            {openSections[
                                                "initialize-responses"
                                            ] ? (
                                                <ChevronCloseSvg />
                                            ) : (
                                                <ChevronOpenSvg />
                                            )}
                                        </div>
                                        {openSections[
                                            "initialize-responses"
                                        ] && (
                                            <div className="mt-3">
                                                <div className="border border-green-600 dark:border-green-600 rounded-lg px-5 py-3 mt-5">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-200",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="200 OK"></SubTitle3>
                                                        {openSections[
                                                            "initialize-200"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-200"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializePaymentResponses200.title
                                                                    }
                                                                    fields={
                                                                        InitializePaymentResponses200.fields
                                                                    }
                                                                    isSuccessResponse={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="border border-red-600  dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-401",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                                        {openSections[
                                                            "initialize-401"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-401"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializePaymentResponses401.title
                                                                    }
                                                                    fields={
                                                                        InitializePaymentResponses401.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-403",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="403 Forbidden"></SubTitle3>
                                                        {openSections[
                                                            "initialize-403"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-403"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializePaymentResponses403.title
                                                                    }
                                                                    fields={
                                                                        InitializePaymentResponses403.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-422",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                                        {openSections[
                                                            "initialize-422"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-422"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializePaymentResponses422.title
                                                                    }
                                                                    fields={
                                                                        InitializePaymentResponses422.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Colonne droite */}
                                <div className="space-y-5">
                                    <div className="bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
                                        <pre className="text-sm text-slate-400 bg-slate-800 overflow-x-auto">
                                            <div className="flex justify-between">
                                                <div>
                                                    <code className="text-sm font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                        POST
                                                    </code>{" "}
                                                    <code className="font-pt-mono">
                                                        https://api.infinexia.com/payment/initialize
                                                    </code>
                                                </div>
                                                <span className="text-sm text-teal-500">
                                                    <KpCopyClick
                                                        text="https://api.infinexia.com/payment/initialize"
                                                        message=""
                                                    />
                                                </span>
                                            </div>
                                        </pre>
                                    </div>
                                    <div className="pt-0">
                                        <SamplesRequest
                                            titleBox="Request samples"
                                            codeSamples={InitializePayment}
                                        />
                                    </div>
                                    <div className="pt-0">
                                        <SamplesResponses
                                            titleBox="Response samples"
                                            codeSamples={
                                                InitializePaymentResponses
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/**********  Check Status  **********/}
                        <section
                            id="check-status"
                            className="pt-12 scroll-mt-20 border-t dark:border-slate-200 dark:border-t"
                        >
                            <div className="space-y-0">
                                <SubTitle
                                    id="check-status"
                                    title={translate(
                                        "DOC_API_PAGE",
                                        "API_Check_Status",
                                    )}
                                />
                                <p className="sm:!w-full md:!w-[50%]">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "API_Check_Status_Description",
                                    )}
                                </p>
                                {/**********  Check Status  **********/}
                                <div className="grid grid-cols-1 md:!grid-cols-[55%_40%] gap-x-12 gap-y-8 !mt-5 sm:!w-full pb-12">
                                    {/* Colonne gauche */}
                                    <div>
                                        <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5">
                                            <div
                                                className="flex justify-between items-center cursor-pointer"
                                                onClick={() =>
                                                    toggleSection(
                                                        "check-request",
                                                    )
                                                }
                                            >
                                                <SubTitle2 title="Request"></SubTitle2>
                                                {openSections[
                                                    "check-request"
                                                ] ? (
                                                    <ChevronCloseSvg />
                                                ) : (
                                                    <ChevronOpenSvg />
                                                )}
                                            </div>
                                            {openSections["check-request"] && (
                                                <div className="mt-3 space-y-8">
                                                    <div className="">
                                                        <BodySchema
                                                            title={
                                                                RequestHeaderSchema.title
                                                            }
                                                            fields={
                                                                RequestHeaderSchema.fields
                                                            }
                                                            isHeader={true}
                                                        />
                                                    </div>
                                                    <div className="pt-5 space-y-8">
                                                        <BodySchema
                                                            title={
                                                                CheckStatusPaymentSchema.title
                                                            }
                                                            fields={
                                                                CheckStatusPaymentSchema.fields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/*****  Check Status Responses  *****/}
                                        <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5 !mt-5">
                                            <div
                                                className="flex justify-between items-center cursor-pointer"
                                                onClick={() =>
                                                    toggleSection(
                                                        "check-responses",
                                                    )
                                                }
                                            >
                                                <SubTitle2 title="Responses"></SubTitle2>
                                                {openSections[
                                                    "check-responses"
                                                ] ? (
                                                    <ChevronCloseSvg />
                                                ) : (
                                                    <ChevronOpenSvg />
                                                )}
                                            </div>
                                            {openSections[
                                                "check-responses"
                                            ] && (
                                                <div className="mt-3">
                                                    <div className="border border-green-600 dark:border-green-600 rounded-lg px-5 py-3 mt-5">
                                                        <div
                                                            className="flex justify-between items-center cursor-pointer"
                                                            onClick={() =>
                                                                toggleSection(
                                                                    "check-200",
                                                                )
                                                            }
                                                        >
                                                            <SubTitle3 title="200 OK"></SubTitle3>
                                                            {openSections[
                                                                "check-200"
                                                            ] ? (
                                                                <ChevronCloseSvg />
                                                            ) : (
                                                                <ChevronOpenSvg />
                                                            )}
                                                        </div>
                                                        {openSections[
                                                            "check-200"
                                                        ] && (
                                                            <div className="mt-3 space-y-8">
                                                                <div className="">
                                                                    <ResponsesSchema
                                                                        title={
                                                                            CheckStatusPaymentResponses200.title
                                                                        }
                                                                        fields={
                                                                            CheckStatusPaymentResponses200.fields
                                                                        }
                                                                        isSuccessResponse={
                                                                            true
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                        <div
                                                            className="flex justify-between items-center cursor-pointer"
                                                            onClick={() =>
                                                                toggleSection(
                                                                    "check-422",
                                                                )
                                                            }
                                                        >
                                                            <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                                            {openSections[
                                                                "check-422"
                                                            ] ? (
                                                                <ChevronCloseSvg />
                                                            ) : (
                                                                <ChevronOpenSvg />
                                                            )}
                                                        </div>
                                                        {openSections[
                                                            "check-422"
                                                        ] && (
                                                            <div className="mt-3 space-y-8">
                                                                <div className="">
                                                                    <ResponsesSchema
                                                                        title={
                                                                            CheckStatusPaymentResponses422.title
                                                                        }
                                                                        fields={
                                                                            CheckStatusPaymentResponses422.fields
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Colonne droite */}
                                    <div className="space-y-5">
                                        <div className="bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
                                            <pre className="text-sm text-slate-400 bg-slate-800 overflow-x-auto">
                                                <div className="flex justify-between">
                                                    <div>
                                                        <code className="text-sm font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                            POST
                                                        </code>{" "}
                                                        <code className="font-pt-mono">
                                                            https://api.infinexia.com/payment/check-status
                                                        </code>
                                                    </div>
                                                    <span className="text-sm text-teal-500">
                                                        <KpCopyClick
                                                            text="https://api.infinexia.com/payment/check-status"
                                                            message=""
                                                        />
                                                    </span>
                                                </div>
                                            </pre>
                                        </div>
                                        <div className="pt-0">
                                            <SamplesRequest
                                                titleBox="Request samples"
                                                codeSamples={CheckStatusPayment}
                                            />
                                        </div>
                                        <div className="pt-0">
                                            <SamplesResponses
                                                titleBox="Response samples"
                                                codeSamples={
                                                    CheckStatusPaymentResponses
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/**********  Get Fees & Wallets Header  **********/}
                        <section
                            id="get-fee-and-available-wallets"
                            className="pt-12 scroll-mt-20 border-t dark:border-slate-200 dark:border-t"
                        >
                            <div className="space-y-0">
                                <SubTitle
                                    id="get-fee-and-available-wallets"
                                    title={translate(
                                        "DOC_API_PAGE",
                                        "API_Get_Fee_And_Available_Wallets",
                                    )}
                                />
                                <p className="sm:!w-full md:!w-[50%]">
                                    {translate(
                                        "DOC_API_PAGE",
                                        "API_Get_Fee_And_Available_Wallets_Description",
                                    )}
                                </p>
                                {/*****  Get Fees & Wallets Request  *****/}
                                <div className="grid grid-cols-1 md:!grid-cols-[55%_40%] gap-x-12 gap-y-8 !mt-5 sm:!w-full pb-12">
                                    {/* Colonne gauche */}
                                    <div>
                                        <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5">
                                            <div
                                                className="flex justify-between items-center cursor-pointer"
                                                onClick={() =>
                                                    toggleSection("get-fees")
                                                }
                                            >
                                                <SubTitle2 title="Request"></SubTitle2>
                                                {openSections["get-fees"] ? (
                                                    <ChevronCloseSvg />
                                                ) : (
                                                    <ChevronOpenSvg />
                                                )}
                                            </div>
                                            {openSections["get-fees"] && (
                                                <div className="mt-3 space-y-8">
                                                    <div className="">
                                                        <BodySchema
                                                            title={
                                                                RequestHeaderSchema.title
                                                            }
                                                            fields={
                                                                RequestHeaderSchema.fields
                                                            }
                                                            isHeader={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {/*****  Get Fees & Wallets Responses  *****/}
                                        <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5 !mt-5">
                                            <div
                                                className="flex justify-between items-center cursor-pointer"
                                                onClick={() =>
                                                    toggleSection(
                                                        "get-fees-responses",
                                                    )
                                                }
                                            >
                                                <SubTitle2 title="Responses"></SubTitle2>
                                                {openSections[
                                                    "get-fees-responses"
                                                ] ? (
                                                    <ChevronCloseSvg />
                                                ) : (
                                                    <ChevronOpenSvg />
                                                )}
                                            </div>
                                            {openSections[
                                                "get-fees-responses"
                                            ] && (
                                                <div className="mt-3">
                                                    <div className="border border-green-600 dark:border-green-600 rounded-lg px-5 py-3 mt-5">
                                                        <div
                                                            className="flex justify-between items-center cursor-pointer"
                                                            onClick={() =>
                                                                toggleSection(
                                                                    "get-fees-200",
                                                                )
                                                            }
                                                        >
                                                            <SubTitle3 title="200 OK"></SubTitle3>
                                                            {openSections[
                                                                "get-fees-200"
                                                            ] ? (
                                                                <ChevronCloseSvg />
                                                            ) : (
                                                                <ChevronOpenSvg />
                                                            )}
                                                        </div>
                                                        {openSections[
                                                            "get-fees-200"
                                                        ] && (
                                                            <div className="mt-3 space-y-8">
                                                                <div className="">
                                                                    <ResponsesSchema
                                                                        title={
                                                                            CheckFeesResponses200.title
                                                                        }
                                                                        fields={
                                                                            CheckFeesResponses200.fields
                                                                        }
                                                                        isSuccessResponse={
                                                                            true
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                        <div
                                                            className="flex justify-between items-center cursor-pointer"
                                                            onClick={() =>
                                                                toggleSection(
                                                                    "initialize-401",
                                                                )
                                                            }
                                                        >
                                                            <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                                            {openSections[
                                                                "initialize-401"
                                                            ] ? (
                                                                <ChevronCloseSvg />
                                                            ) : (
                                                                <ChevronOpenSvg />
                                                            )}
                                                        </div>
                                                        {openSections[
                                                            "initialize-401"
                                                        ] && (
                                                            <div className="mt-3 space-y-8">
                                                                <div className="">
                                                                    <ResponsesSchema
                                                                        title={
                                                                            CheckFeesResponses401.title
                                                                        }
                                                                        fields={
                                                                            CheckFeesResponses401.fields
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Colonne droite */}
                                    <div className="space-y-5">
                                        <div className="bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
                                            <pre className="text-sm text-slate-400 bg-slate-800 overflow-x-auto">
                                                <div className="flex justify-between">
                                                    <div>
                                                        <code className="text-sm font-semibold text-green-500 border border-green-500 dark:border-green-500 px-2 rounded">
                                                            GET
                                                        </code>{" "}
                                                        <code className="font-pt-mono">
                                                            https://api.infinexia.com/payment
                                                        </code>
                                                    </div>
                                                    <span className="text-sm text-teal-500">
                                                        <KpCopyClick
                                                            text="https://api.infinexia.com/payment"
                                                            message=""
                                                        />
                                                    </span>
                                                </div>
                                            </pre>
                                        </div>
                                        <div className="pt-0">
                                            <SamplesRequest
                                                titleBox="Request samples"
                                                codeSamples={CheckFees}
                                            />
                                        </div>
                                        <div className="pt-0">
                                            <SamplesResponses
                                                titleBox="Response samples"
                                                codeSamples={CheckFeesResponses}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Payment;
