export const AuthenticationResponses = {
    200: `{
  "code": "ok",
  "message": "string",
}`,
    401: `{
  "code": INVALID_MERCHANT,
  "message": "Merchant invalid",
}`,
};

export const InitializePaymentResponses = {
    200: `{
  "payment_url": "https://..?ref=order-token", //redirect the user on this payment page
  "token": "xxxx-xxxx-x-x--xx--x", // reference of the order on Infinexia
  "code": PAYMENT_INITIALIZED,
  "status": "ok",
}`,
    401: `{
  "code": INVALID_MERCHANT,
  "message": "Merchant invalid",
}`,
    422: `{
  "code": INVALID_WALLET,
  "message": "Invalid wallet",
}`,
    403: `{
  "code": DISABLED_SERVICE,
  "message": "Payment is disabled, please enable it on your profile.",
}

{
  "code": INACTIVE_MERCHAND,
  "message": "Merchant status has been desactivated.",
}`,
};

export const CheckStatusPaymentResponses = {
    200: `{
  "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID",
  "status": "completed" // "completed" | "pending" | "canceled" | "expired"
  "transaction":{
    "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID",
    "status": "completed" // "completed" | "pending" | "canceled" | "expired"
    "token": "xxxx-xxxx-x-x--xx--x", // reference of the order on Infinexia
    "wallet": "EUR",
    "amount": 100,
    "currency": "EUR",
    "reference": "#order-1"
    }
}`,
    422: `{
  "code": INVALID_TRANSACTION,
  "message": "Invalid transaction",
}`,
};

export const CheckFeesResponses = {
    200: `{
  "fee": 2,5 //in percent
  "assets": [
{
    "name":"Euro",
    "abbr":"EUR", // wallet identifier
    "logo":"logo_url", // URL of the logo on https://api.infinexia.com
    }
]
}`,
    401: `{
  "code": INVALID_MERCHANT,
  "message": "Merchant invalid",
}`,
};

export const InitializeTransferResponses = {
    200: `{
  "message": "Transfert done with success",
  "code": "TRANSFERT_SUCCESS",
  "status": "SUCCESS",
  "transaction":{
    "inf_transaction_id": "ref#30000",// transaction ref on infinexia
    "status": "completed" // "completed" | "pending" | "canceled"
    "wallet": "EUR",
    "amount": 100,
    "partner": "INF00000320100",
    "transaction_id": "INF-trans-111" //YOUR_UNIQUE_TRANSACTION_ID
    }
}`,
    401: `{
  "code": INVALID_MERCHANT,
  "message": "Merchant invalid",
}`,
    422: `{
  "code": INVALID_WALLET,
  "message": "Invalid wallet",
}`,
    403: `{
  "code": "DISABLED_SERVICE",
  "message": "Merchant status has been desactivated.",
}

{
  "code": "INACTIVE_MERCHAND",
  "message": "Payment is disabled, please enable it on your profile.",
}`,
    400: `{
  "code": "INSUFFICIENT_BALANCE",
  "message": "Insufficient balance.",
}

{
  "code": "UNKNOWN_ERROR",
  "message": "An error was occured.",
}

{
  "code": "PARTNER_NOT_FOUND",
  "message": "An error was occured.",
}`,
    409: `{
  "code": DUPLICATE_TRANSACTION_ID,
  "message": "Duplicate transaction id.",
}`,
};

export const InitializeMultiTransferResponses = {
    200: `{
  "status": "success",
  "message": "Mass transfers processed successfully.",
  "transfers": [
    {
      "inf_transaction_id": "T12345",
      "recipient": "INF00000320101",
      "amount": 100.00,
      "currency": "EUR",
      "status": "completed" // "completed" | "pending" | "canceled"
    },
    {
      "inf_transaction_id": "T12346",
      "recipient": "INF00000320101",
      "amount": 200.00,
      "currency": "EUR",
      "status": "completed" // "completed" | "pending" | "canceled"
    }
  ]
}
`,
    400: `{
  "code": "INSUFFICIENT_BALANCE",
  "message": "Insufficient balance.",
}

{
  "code": "UNKNOWN_ERROR",
  "message": "An error was occured.",
}

{
  "code": "PARTNER_NOT_FOUND",
  "message": "An error was occured.",
}`,

    401: `{
  "code": INVALID_MERCHANT,
  "message": "Merchant invalid",
}`,

    403: `{
  "code": "DISABLED_SERVICE",
  "message": "Merchant status has been desactivated.",
}

{
  "code": "INACTIVE_MERCHAND",
  "message": "Payment is disabled, please enable it on your profile.",
}`,

    409: `{
  "code": DUPLICATE_TRANSACTION_ID,
  "message": "Duplicate transaction id.",
}`,

    422: `{
  "code": INVALID_WALLET,
  "message": "Invalid wallet",
}`,
};

export const CheckStatusTransferResponses = {
    200: `{
  "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID",
  "status": "completed" // "completed" | "pending" | "canceled"
  "transaction":{
    "inf_transaction_id": "ref#30000",// transaction ref on infinexia
    "status": "completed" // "completed" | "pending" | "canceled"
    "wallet": "EUR",
    "amount": 100,
    "partner": "INF00000320100",
    "transaction_id": "INF-trans-111" //YOUR_UNIQUE_TRANSACTION_ID
    }
}`,
    422: `{
  "code": INVALID_TRANSACTION,
  "message": "Invalid transaction",
}`,
};

export const WebhookResponses = {
    200: `{
  "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID",
  "status": "completed" // "completed" | "pending" | "canceled" | "expired"
  "transaction":{
    "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID",
    "status": "completed" // "completed" | "pending" | "canceled" | "expired"
    "token": "xxxx-xxxx-x-x--xx--x", // reference of the order on Infinexia
    "wallet": "USD",
    "amount": 100,
    "currency": "USD",
    "reference": "#order-1"
    }
}`,
    401: `{
  "code": INVALID_MERCHANT,
  "message": "Merchant invalid",
}`,
};
