import React, { createContext, useContext, useEffect, useState } from "react";
import { useAppProvider } from "./AppProvider";
import { useAssets } from "./AssetsProvider";

export enum FlyOutComponent {
    CHILD_EARNING = "CHILD_EARNING",
    CHILD_DEBIT = "CHILD_DEBIT",
    CHILD_CREDIT = "CHILD_CREDIT",
    CHILD_CHANGE_PASSWORD = "CHILD_CHANGE_PASSWORD",
    CHILD_CHANGE_TRANSACTION_PASSWORD = "CHILD_CHANGE_TRANSACTION_PASSWORD",
    CHILD_CONFIGURE_2FA = "CHILD_CONFIGURE_2FA",
    CHILD_DISABLE_2FA = "CHILD_DISABLE_2FA",
    CHILD_APP_KEY_AND_CONFIG = "CHILD_APP_KEY_AND_CONFIG",
    CHILD_REQUEST_ACCESS = "CHILD_REQUEST_ACCESS",
    CHILD_SUBSCRIBE_EPARGNES = "CHILD_SUBSCRIBE_EPARGNES",
    CHILD_CONFIG_2FA_TOKEN = "CHILD_CONFIG_2FA_TOKEN",
    CHILD_ADD_PARENT = "CHILD_ADD_PARENT",
    CHILD_CHANGE_BG = "CHILD_CHANGE_BG",
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW",
    SWAP = "SWAP",
    PROFIL_CHILD = "PROFIL_CHILD",
    SETTING_CHILD = "SETTING_CHILD",
    SETTING_USER_WALLET = "SETTING_USER_WALLET",
    ADD_CARD_VIRTUAL = "ADD_CARD_VIRTUAL",
    ADD_CARD_VIRTUAL2 = "ADD_CARD_VIRTUAL2",
    CARD_VIRTUAL_FORM = "CARD_VIRTUAL_FORM",
    PHYSICAL_CARD_FORM = "PHYSICAL_CARD_FORM",
    ACTIVE_DESACTIVE_CARD = "ACTIVE_DESACTIVE_CARD",
    ADD_BILL_SETTING = "ADD_BILL_SETTING",
    ADD_PAYMENT_INVOICE = "ADD_PAYMENT_INVOICE",
    UPDATE_MARKET_TREND = "UPDATE_MARKET_TREND",
    CHART_CRYPTO = "CHART_CRYPTO",
    DEPOSIT_WITHDRAW_STAKING = "DEPOSIT_WITHDRAW_STAKING",
    ADD_FRIEND_ASSET_CO = "ADD_FRIEND_ASSET_CO",
    VIEW_MY_SAVING_DETAIL = "VIEW_MY_SAVING_DETAIL",
    RENEW_CARD_ORDER = "RENEW_CARD_ORDER",
    VIEW_DETAIL_CARD = "VIEW_DETAIL_CARD",
}

interface FlyoutContextProps {
    flyoutOpen: boolean;
    setFlyoutOpen: (flyoutOpen: boolean) => void;
    flyoutOpenWidget: boolean;
    setFlyoutOpenWidget: (flyoutOpenWidget: boolean) => void;
    content: React.ReactNode;
    setContent: (content: React.ReactNode) => void;
    title: React.ReactNode;
    setTitle: (content: React.ReactNode) => void;
    activeComponent?: FlyOutComponent;
    setActiveComponent: (activeComponent?: FlyOutComponent) => void;
    isRefresh: boolean;
    setIsRefresh: (isRefresh: boolean) => void;
}

const FlyoutContext = createContext<FlyoutContextProps | undefined>(undefined);

export const FlyoutProvider = ({
    children,
    initialState = false,
}: {
    children: React.ReactNode;
    initialState?: boolean;
}) => {
    const [flyoutOpen, setFlyoutOpen] = useState<boolean>(initialState);
    const { action } = useAppProvider();
    const { setCurrentAsset } = useAssets();
    const [title, setTitle] = useState<React.ReactNode>();
    const [activeComponent, setActiveComponent] = useState<
        FlyOutComponent | undefined
    >(undefined);
    const [flyoutOpenWidget, setFlyoutOpenWidget] =
        useState<boolean>(initialState);
    const [content, setContent] = useState<React.ReactNode>();
    const [isRefresh, setIsRefresh] = useState<boolean>(initialState);
    useEffect(() => {
        const doc = document.getElementById("mobile-header");
        if (doc) {
            if (flyoutOpen || flyoutOpenWidget || activeComponent) {
                doc.classList.add("scrolling3");
                if (doc && !doc.classList.contains("scrolling2")) {
                    doc.classList.add("scrolling2");
                    doc.classList.add("via-flyout");
                }
            } else {
                if (doc && doc.classList.contains("via-flyout")) {
                    doc.classList.remove("scrolling2");
                    doc.classList.remove("via-flyout");
                }
                doc.classList.remove("scrolling3");
            }
        }
    }, [flyoutOpen, flyoutOpenWidget, activeComponent]);

    useEffect(() => {
        setFlyoutOpen(false);
        setIsRefresh(false);
        setFlyoutOpenWidget(false);
        setActiveComponent(undefined);
    }, [action]);

    useEffect(() => {
        if (!activeComponent) {
            setCurrentAsset(undefined);
        }
    }, [activeComponent]);

    return (
        <FlyoutContext.Provider
            value={{
                flyoutOpen,
                setFlyoutOpen,
                flyoutOpenWidget,
                setFlyoutOpenWidget,
                content,
                setContent,
                title,
                setTitle,
                activeComponent,
                setActiveComponent,
                isRefresh,
                setIsRefresh,
            }}
        >
            {children}
        </FlyoutContext.Provider>
    );
};

export const useFlyoutContext = () => {
    const context = useContext(FlyoutContext);
    if (!context) {
        throw new Error("useFlyout must be used within a FlyoutProvider");
    }
    return context;
};
