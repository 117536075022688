import React, { useState, useEffect } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";

const SettingsSecurity = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            case "edit-profile":
                return translate("HELPCENTER", "Modify_Profile");
            case "login-password":
                return translate("HELPCENTER", "Login_PassWord");
            case "transaction-password":
                return translate("HELPCENTER", "Transac_PassWord");
            case "2fa-authentication":
                return translate("HELPCENTER", "Authentication_2FA");
            default:
                return translate("HELPCENTER", "Settings_Security");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            case "edit-profile":
                return translate("Profil", "Profil");
            case "login-password":
                return translate("SignIn", "Password");
            case "transaction-password":
                return translate("SignIn", "Password");
            case "2fa-authentication":
                return translate("HELPCENTER", "Authentication_2FA");
            default:
                return translate("HELPCENTER", "Settings_Security");
        }
    };

    const renderContentForStep = () => {
        switch (currentStep) {
            case "edit-profile":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="edit-profile"
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Edit_profil_Sub",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Edit_profil_Sub2",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Modify_profil",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Settings",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER",
                                                "Modify_Profile",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Modify_Infos",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «{translate("HELPCENTER", "Save")}»
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Note_Edit_Profil",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Note_Edit_Profil2",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "login-password":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="login-password"
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Login_Pass_Sub",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Login_Pass_Sub2",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Modify_Login_Pass",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Settings",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_SETTINGS",
                                                "Modify_Pass",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Add_Old_Pass",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Add_New_Pass",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Confirm_New_Pass",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «{translate("HELPCENTER", "Save")}»
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Note_Login_Pass",
                                    )}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Forgot_Pass",
                                        )}
                                        »
                                    </strong>
                                    .
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Receive_Mail_Pass",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "transaction-password":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="transaction-password"
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Transac_Pass_Sub",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Login_Pass_Sub2",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Modif_Transac_Pass",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Settings",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_SETTINGS",
                                                "Transac_Pass",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Add_Old_Pass",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Add_New_Pass",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Confirm_New_Pass",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «{translate("HELPCENTER", "Save")}»
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Note_Transac_Pass",
                                    )}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Forgot_Pass",
                                        )}
                                        »
                                    </strong>
                                    .
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Receive_Mail_Pass",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "2fa-authentication":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="2fa-authentication"
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Auth_2FA_Sub",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Auth_2FA_Sub2",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-0">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Activate_2Fa",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER_ACCOUNTS",
                                            "Settings",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_SETTINGS",
                                                "Configure_2FA",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Download_App_2FA",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Scan_2FA_Key",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Copy_2FA_Key",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Add_2FA_Code",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_SETTINGS",
                                                "Activate_Auth_2FA",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="space-y-0">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_SETTINGS",
                                        "Deactivate_2Fa",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Step_Dectivate_2Fa",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_SETTINGS",
                                                "Deactivate_2FA_Auth",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_SETTINGS",
                                            "Add_2FA_Code",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_SETTINGS",
                                                "Deactivate_2FA_Auth",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Note_2FA",
                                    )}
                                </p>
                                <p className="pt-1 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_SETTINGS",
                                        "Note2_2FA",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            default:
                return null; // Aucun contenu par défaut si le type de compte n'est pas reconnu
        }
    };

    return (
        <>
            <Banner title={getBannerTitle()} />
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/help-center/settings-security`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("HELPCENTER", "Settings_Security")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div>
                        <div className="text-slate-600 dark:text-slate-600 space-y-6">
                            {/* Affiche le contenu selon currentStep */}
                            {renderContentForStep()}
                        </div>
                    </div>
                    <div className="hidden md:block pt-10">
                        <p className="sm:!text-sm md:!text-base text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}{" "}
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700 font-normal"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                    <div className="md:hidden py-10">
                        <p className="sm:!text-sm md:!text-base text-center text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}
                        </p>
                        <p className="sm:!text-sm md:!text-base text-center text-black font-normal">
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                </div>
            </article>
        </>
    );
};

export default SettingsSecurity;
