import React from "react";

import alert_message from "../../../Services/Alert/AlertMessage";
import CopySvg from "../Svg/CopySvg";
import translator from "../Translation/Translate";

const KpCopyClick = ({ text, message }: { text: string; message: string }) => {
    const { translate } = translator();
    return (
        <button
            className=" bg-transparent"
            type="button"
            onClick={() => {
                // Copier le texte dans le presse-papiers
                navigator.clipboard.writeText(text).then(
                    () => {
                        // Utiliser `translate` pour le message de succès
                        const successMessage =
                            message && message.trim() !== ""
                                ? message
                                : translate("DOC_API_PAGE", "Copy_Success"); // Traduction ou fallback
                        alert_message.success(successMessage);
                    },
                    () => {
                        // Gestion des erreurs éventuelles
                        alert_message.error(
                            translate("DOC_API_PAGE", "Échec de la copie"),
                        );
                    },
                );
            }}
        >
            <CopySvg />
        </button>
    );
};

export default KpCopyClick;
