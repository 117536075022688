import React, { useEffect, useState } from "react";

import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ChevronCloseSvg from "../../Components/Extra/Svg/ChevronCloseSvg";
import ChevronOpenSvg from "../../Components/Extra/Svg/ChevronOpenSvg";
import Samples from "../Layout/RequestSamples";
import {
    InitializeTransfer,
    CheckStatusTransfer,
} from "../Layout/CodeRequestSamples";
import BodySchema from "../Layout/BodySchemas";
import {
    RequestHeaderSchema,
    InitializePaymentSchema,
    CheckStatusPaymentSchema,
    InitializeTransferSchema,
} from "../Layout/CodeBodySchemas";
import SamplesResponses from "../Layout/ResponsesSamples";
import {
    CheckStatusTransferResponses,
    InitializeTransferResponses,
} from "../Layout/CodeResponsesSamples";
import SamplesRequest from "../Layout/RequestSamples";
import ResponsesSchema from "../Layout/ResponsesSchemas";
import {
    CheckStatusTransferResponses200,
    CheckStatusTransferResponses422,
    InitializeTransferResponses200,
    InitializeTransferResponses400,
    InitializeTransferResponses401,
    InitializeTransferResponses403,
    InitializeTransferResponses409,
    InitializeTransferResponses422,
} from "../Layout/CodeResponsesSchemas";

const Transfer = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL
    const [isOpen, setIsOpen] = useState(false);
    const transaction_id = "{transaction_id}";

    const schemas = [
        InitializePaymentSchema,
        CheckStatusPaymentSchema,
        InitializeTransferSchema,
    ];

    type SectionKeys =
        | "base-url"
        | "initialize-endpoint"
        | "initialize-single-transfer-request"
        | "initialize-single-transfer-responses"
        | "initialize-single-transfer-200"
        | "initialize-single-transfer-400"
        | "initialize-single-transfer-401"
        | "initialize-single-transfer-403"
        | "initialize-single-transfer-409"
        | "initialize-single-transfer-422"
        | "check-endpoint"
        | "check-request"
        | "check-responses"
        | "check-200"
        | "check-422"
        | "get-fees-endpoint"
        | "get-fees"
        | "get-fees-responses"
        | "get-fees-200"
        | "get-fees-401";

    const [openSections, setOpenSections] = useState<
        Record<SectionKeys, boolean>
    >({
        "base-url": true,
        "initialize-endpoint": true,
        "initialize-single-transfer-request": true,
        "initialize-single-transfer-responses": true,
        "initialize-single-transfer-200": false,
        "initialize-single-transfer-400": false,
        "initialize-single-transfer-401": false,
        "initialize-single-transfer-403": false,
        "initialize-single-transfer-409": false,
        "initialize-single-transfer-422": false,
        "check-endpoint": true,
        "check-request": true,
        "check-responses": true,
        "check-200": false,
        "check-422": false,
        "get-fees-endpoint": true,
        "get-fees": true,
        "get-fees-responses": true,
        "get-fees-200": false,
        "get-fees-401": false,
    });

    const toggleSection = (section: SectionKeys) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1)); // Retire le '#' pour obtenir l'ID
            if (element) {
                element.scrollIntoView({ behavior: "smooth" }); // Défilement fluide
            }
        }
    }, [location]);

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "Transfer");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "Transfer");
        }
    };
    return (
        <>
            <Banner title={translate("DOC_API_PAGE", "Transfer")}></Banner>
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/documentation/introduction`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("DOC_API_PAGE", "DOCUMENTATION")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="w-full space-y-8 text-slate-600 dark:text-slate-400 font-normal sm:!text-sm md:!text-base">
                <div>
                    <header className="mb-5">
                        <h1
                            id="pay-with"
                            data-scrollspy-target=""
                            className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200"
                        >
                            {translate(
                                "DOC_API_PAGE",
                                "API_TRANSFER_DESCRIPTION",
                            )}
                        </h1>
                    </header>
                    {/**********  Fin du Header  **********/}
                    <div className="space-y-6">
                        {/**********  Initialize Unique Transfert Header  **********/}
                        <section
                            id="initialize-single-transfer"
                            className="space-y-1 scroll-mt-64"
                        >
                            <SubTitle
                                id="initialize-single-transfer"
                                title={translate(
                                    "DOC_API_PAGE",
                                    "API_REQUEST_TRANSFER",
                                )}
                            />
                            <p>
                                {translate(
                                    "DOC_API_PAGE",
                                    "API_REQUEST_TRANSFER_DESCRIPTION",
                                )}
                            </p>
                            {/*****  Initialize Transfer Request  *****/}
                            <div className="grid grid-cols-1 md:!grid-cols-[55%_40%] gap-x-12 gap-y-8 !mt-5 sm:!w-full pb-12">
                                {/* Colonne gauche */}
                                <div>
                                    <div className="border-2 rounded-lg p-5 dark:border-slate-200 dark:border">
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() =>
                                                toggleSection(
                                                    "initialize-single-transfer-request",
                                                )
                                            }
                                        >
                                            <SubTitle2 title="Request"></SubTitle2>
                                            {openSections[
                                                "initialize-single-transfer-request"
                                            ] ? (
                                                <ChevronCloseSvg />
                                            ) : (
                                                <ChevronOpenSvg />
                                            )}
                                        </div>
                                        {openSections[
                                            "initialize-single-transfer-request"
                                        ] && (
                                            <div className="mt-3 space-y-8">
                                                <div className="">
                                                    <BodySchema
                                                        title={
                                                            RequestHeaderSchema.title
                                                        }
                                                        fields={
                                                            RequestHeaderSchema.fields
                                                        }
                                                        isHeader={true}
                                                    />
                                                </div>
                                                <div className="pt-5 space-y-8">
                                                    <BodySchema
                                                        title={
                                                            InitializeTransferSchema.title
                                                        }
                                                        fields={
                                                            InitializeTransferSchema.fields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/*****  Initialize Transfer Responses  *****/}
                                    <div className="border-2 rounded-lg p-5 !mt-5 dark:border-slate-200 dark:border">
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() =>
                                                toggleSection(
                                                    "initialize-single-transfer-responses",
                                                )
                                            }
                                        >
                                            <SubTitle2 title="Responses"></SubTitle2>
                                            {openSections[
                                                "initialize-single-transfer-responses"
                                            ] ? (
                                                <ChevronCloseSvg />
                                            ) : (
                                                <ChevronOpenSvg />
                                            )}
                                        </div>
                                        {openSections[
                                            "initialize-single-transfer-responses"
                                        ] && (
                                            <div className="mt-3">
                                                <div className="border border-green-600 dark:border-green-600 rounded-lg px-5 py-3 mt-5">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-single-transfer-200",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="200 OK"></SubTitle3>
                                                        {openSections[
                                                            "initialize-single-transfer-200"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-single-transfer-200"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializeTransferResponses200.title
                                                                    }
                                                                    fields={
                                                                        InitializeTransferResponses200.fields
                                                                    }
                                                                    isSuccessResponse={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-single-transfer-400",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="400 Bad Request"></SubTitle3>
                                                        {openSections[
                                                            "initialize-single-transfer-400"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-single-transfer-400"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializeTransferResponses400.title
                                                                    }
                                                                    fields={
                                                                        InitializeTransferResponses400.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-single-transfer-401",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                                        {openSections[
                                                            "initialize-single-transfer-401"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-single-transfer-401"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializeTransferResponses401.title
                                                                    }
                                                                    fields={
                                                                        InitializeTransferResponses401.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-single-transfer-403",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="403 Forbidden"></SubTitle3>
                                                        {openSections[
                                                            "initialize-single-transfer-403"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-single-transfer-403"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializeTransferResponses403.title
                                                                    }
                                                                    fields={
                                                                        InitializeTransferResponses403.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-single-transfer-409",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="409 Conflict"></SubTitle3>
                                                        {openSections[
                                                            "initialize-single-transfer-409"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-single-transfer-409"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializeTransferResponses409.title
                                                                    }
                                                                    fields={
                                                                        InitializeTransferResponses409.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "initialize-single-transfer-422",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                                        {openSections[
                                                            "initialize-single-transfer-422"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "initialize-single-transfer-422"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        InitializeTransferResponses422.title
                                                                    }
                                                                    fields={
                                                                        InitializeTransferResponses422.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Colonne droite */}
                                <div className="space-y-5">
                                    <div className="bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
                                        <pre className="text-sm text-slate-400 bg-slate-800 overflow-x-auto">
                                            <div className="flex justify-between">
                                                <div>
                                                    <code className="text-sm font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                        POST
                                                    </code>{" "}
                                                    <code className="font-pt-mono">
                                                        https://api.infinexia.com/payment/transfert
                                                    </code>
                                                </div>
                                                <span className="text-sm text-teal-500">
                                                    <KpCopyClick
                                                        text="https://api.infinexia.com/payment/transfert"
                                                        message=""
                                                    />
                                                </span>
                                            </div>
                                        </pre>
                                    </div>
                                    <div className="pt-0">
                                        <SamplesRequest
                                            titleBox="Request samples"
                                            codeSamples={InitializeTransfer}
                                        />
                                    </div>
                                    <div className="pt-0">
                                        <SamplesResponses
                                            titleBox="Response samples"
                                            codeSamples={
                                                InitializeTransferResponses
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/**********  Check Status Header **********/}
                        <section
                            id="check-status"
                            className="pt-12 scroll-mt-20 border-t dark:border-slate-200 dark:border-t"
                        >
                            <div className="space-y-0">
                                <SubTitle
                                    id="check-status"
                                    title={translate(
                                        "DOC_API_PAGE",
                                        "API_Check_Status",
                                    )}
                                />
                                <p>
                                    {translate(
                                        "DOC_API_PAGE",
                                        "API_Check_Transfer-Status_Description",
                                    )}
                                </p>
                                {/**********  Check Status  **********/}
                                <div className="grid grid-cols-1 md:!grid-cols-[55%_40%] gap-x-12 gap-y-8 !mt-5 sm:!w-full pb-12">
                                    {/* Colonne gauche */}
                                    <div>
                                        <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5">
                                            <div
                                                className="flex justify-between items-center cursor-pointer"
                                                onClick={() =>
                                                    toggleSection(
                                                        "check-request",
                                                    )
                                                }
                                            >
                                                <SubTitle2 title="Request"></SubTitle2>
                                                {openSections[
                                                    "check-request"
                                                ] ? (
                                                    <ChevronCloseSvg />
                                                ) : (
                                                    <ChevronOpenSvg />
                                                )}
                                            </div>
                                            {openSections["check-request"] && (
                                                <div className="mt-3 space-y-8">
                                                    <div className="">
                                                        <BodySchema
                                                            title={
                                                                RequestHeaderSchema.title
                                                            }
                                                            fields={
                                                                RequestHeaderSchema.fields
                                                            }
                                                            isHeader={true}
                                                        />
                                                    </div>
                                                    <div className="pt-5 space-y-8">
                                                        <BodySchema
                                                            title={
                                                                CheckStatusPaymentSchema.title
                                                            }
                                                            fields={
                                                                CheckStatusPaymentSchema.fields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {/*****  Check Status Responses  *****/}
                                        <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5 !mt-5">
                                            <div
                                                className="flex justify-between items-center cursor-pointer"
                                                onClick={() =>
                                                    toggleSection(
                                                        "check-responses",
                                                    )
                                                }
                                            >
                                                <SubTitle2 title="Responses"></SubTitle2>
                                                {openSections[
                                                    "check-responses"
                                                ] ? (
                                                    <ChevronCloseSvg />
                                                ) : (
                                                    <ChevronOpenSvg />
                                                )}
                                            </div>
                                            {openSections[
                                                "check-responses"
                                            ] && (
                                                <div className="mt-3">
                                                    <div className="border border-green-600 dark:border-green-600 rounded-lg px-5 py-3 mt-5">
                                                        <div
                                                            className="flex justify-between items-center cursor-pointer"
                                                            onClick={() =>
                                                                toggleSection(
                                                                    "check-200",
                                                                )
                                                            }
                                                        >
                                                            <SubTitle3 title="200 OK"></SubTitle3>
                                                            {openSections[
                                                                "check-200"
                                                            ] ? (
                                                                <ChevronCloseSvg />
                                                            ) : (
                                                                <ChevronOpenSvg />
                                                            )}
                                                        </div>
                                                        {openSections[
                                                            "check-200"
                                                        ] && (
                                                            <div className="mt-3 space-y-8">
                                                                <div className="">
                                                                    <ResponsesSchema
                                                                        title={
                                                                            CheckStatusTransferResponses200.title
                                                                        }
                                                                        fields={
                                                                            CheckStatusTransferResponses200.fields
                                                                        }
                                                                        isSuccessResponse={
                                                                            true
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                        <div
                                                            className="flex justify-between items-center cursor-pointer"
                                                            onClick={() =>
                                                                toggleSection(
                                                                    "check-422",
                                                                )
                                                            }
                                                        >
                                                            <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                                            {openSections[
                                                                "check-422"
                                                            ] ? (
                                                                <ChevronCloseSvg />
                                                            ) : (
                                                                <ChevronOpenSvg />
                                                            )}
                                                        </div>
                                                        {openSections[
                                                            "check-422"
                                                        ] && (
                                                            <div className="mt-3 space-y-8">
                                                                <div className="">
                                                                    <ResponsesSchema
                                                                        title={
                                                                            CheckStatusTransferResponses422.title
                                                                        }
                                                                        fields={
                                                                            CheckStatusTransferResponses422.fields
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Colonne droite */}
                                    <div className="space-y-5">
                                        <div className="bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
                                            <pre className="text-sm text-slate-400 bg-slate-800 overflow-x-auto">
                                                <div className="flex justify-between">
                                                    <div>
                                                        <code className="text-sm font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                            POST
                                                        </code>{" "}
                                                        <code className="font-pt-mono">
                                                            https://api.infinexia.com/payment/transfert/check-status
                                                        </code>
                                                    </div>
                                                    <span className="text-sm text-teal-500">
                                                        <KpCopyClick
                                                            text="https://api.infinexia.com/payment/transfert/check-status"
                                                            message=""
                                                        />
                                                    </span>
                                                </div>
                                            </pre>
                                        </div>
                                        <div className="pt-0">
                                            <SamplesRequest
                                                titleBox="Request samples"
                                                codeSamples={
                                                    CheckStatusTransfer
                                                }
                                            />
                                        </div>
                                        <div className="pt-0">
                                            <SamplesResponses
                                                titleBox="Response samples"
                                                codeSamples={
                                                    CheckStatusTransferResponses
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Transfer;
