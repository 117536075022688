import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import HelmetComponent from "../../Admin/Layout/HelmetComponent/HelmetComponent";
import CubeSvg from "../../Components/Extra/Svg/CubeSvg";
import BannerIntro from "../Layout/BannerIntro";
import BookSidebarFaqSvg from "../../Components/Extra/Svg/BookSidebarFaqSvg";
import BookIntroFaqSvg from "../../Components/Extra/Svg/BookIntroFaqSvg";
import BannerCategory from "../Layout/BannerCategory";

interface CategoryItem {
    title: string;
    description: string;
    url: string;
}

const CategoryCards = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const navigate = useNavigate();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const handleBannerClick = () => {
        navigate("/help-center/intro"); // Remplacez par l'URL cible
    };
    const getBannerTitle = () => {
        switch (currentStep) {
            default:
                return translate("HELPCENTER", "Bank_Cards");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            default:
                return translate("HELPCENTER", "Bank_Cards");
        }
    };

    // Liste des données des liens
    const categoryData: CategoryItem[] = [
        {
            title: translate("HELPCENTER", "Physical_Cards"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Order_Physical")}`,
            url: "/help-center/cards/physical-cards",
        },
        {
            title: translate("HELPCENTER", "Cartes virtuelles"),
            description: `${translate("HELPCENTER", "Guide")} ${translate("HELPCENTER", "Generate_Virtual")}`,
            url: "/help-center/cards/virtual-cards",
        },
    ];
    return (
        <>
            <HelmetComponent
                title={translate("HELPCENTER", "Help_Center")}
                description={translate("SEO", "Start_Infinexia_Desc")}
                link="/help-center/cards/"
                keywords={translate("SEO", "Keys_Home")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            />
            <div className="">
                <BannerCategory
                    title={getBannerTitle()}
                    onClick={handleBannerClick}
                />
                <div className="breadcrumb md:hidden flex items-center mb-8">
                    <div className="flex items-center text-sm">
                        {/* Lien vers l'accueil Help Center */}
                        <NavLink
                            to={`${lang ? `/${lang}` : ""}/help-center/intro`}
                            className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                        >
                            {translate("HELPCENTER", "Help_Center")}
                        </NavLink>

                        {/* Flèche de séparation */}
                        <svg
                            className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                            width="8"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                        </svg>

                        {/* Titre de l'étape */}
                        <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                            {getBreadcrumb()}
                        </span>
                    </div>
                </div>
                {/* Fin du breadcrumb */}
                <section className="flex xl:space-x-12">
                    <div className="w-full">
                        <div className="w-full rounded-lg mx-auto grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                            {categoryData.map((item, index) => (
                                <div
                                    key={index}
                                    className="box bg-white text-left p-4 rounded-lg shadow-lg !border !border-gray-300 dark:border-gray-300 zoom-in w-full"
                                    onClick={() => navigate(item.url)}
                                >
                                    <div className="flex flex-col h-[50px] overflow-hidden">
                                        {/* Title Section */}
                                        <h2 className="text-base md:text-lg font-bold flex items-center mb-2 truncate">
                                            <BookIntroFaqSvg />
                                            <span className="">
                                                {item.title}
                                            </span>
                                        </h2>
                                        {/* Description */}
                                        <p className="text-sm md:text-base text-black font-normal overflow-hidden text-ellipsis line-clamp-1">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default CategoryCards;
