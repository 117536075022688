import React from "react";
import loadable from "@loadable/component";

import AnonymousRouteProvider from "./RouteProvider/AnonymousRouteProvider";
import DocBase from "../Doc/Layout/DocBase";
import LandingBase from "../Layouts/Accueil/Base/base";
import PricesBusiness from "../Layouts/Accueil/pages/pricesBusiness";
import About from "../Layouts/Accueil/pages/about";
import Business from "../Layouts/Accueil/pages/business";
import Landing from "../Layouts/Accueil/pages/home";
import Partners from "../Layouts/Accueil/pages/partners";
import Support from "../Layouts/Accueil/pages/support";
import InitializePayment from "../Doc/pages/InitializePayment";
import Introduction from "../Doc/pages/Introduction";
import Authentication from "../Doc/pages/Authentication";
import Payment from "../Doc/pages/Payment";
import Transfer from "../Doc/pages/Transfer";
import Webhook from "../Doc/pages/Webhook";
import HelpCenterBase from "../Help Center/Layout/HelpCenterBase";
import InfinexiaPayment from "../Landing/Payment/InfinexiaPayment";
import Register from "../Components/Authentication/Register/Register";
import Login from "../Components/Authentication/Login/Login";
import Error from "../Layouts/Error/Error";
import Prices from "../Layouts/Accueil/pages/prices";
import TermAndConditions from "../Layouts/Accueil/pages/term-and-conditions";
import PrivacyPolicy from "../Layouts/Accueil/pages/privacyPolicy";
import Legal from "../Layouts/Accueil/pages/legal";
import Pots from "../Layouts/Pots/Pots";
import LanguageProvider from "../App/LanguageProvider";
import IndividualFees from "../Layouts/Accueil/pages/Individual-fees";
import BusinessFees from "../Layouts/Accueil/pages/Business-fees";
import Referrals from "../Layouts/Accueil/pages/referrals";
import HelpCenterIntro from "../Help Center/pages/HelpCenterIntro";
import GettingStart from "../Help Center/pages/GettingStart";
import Accounts from "../Help Center/pages/Accounts";
import Transactions from "../Help Center/pages/Transactions";
import Cards from "../Help Center/pages/Cards";
import SettingsSecurity from "../Help Center/pages/SettingsSecurity";
import Sponsorship from "../Help Center/pages/Sponsorship";
import PaymentApi from "../Help Center/pages/PaymentApi";
import EstimatesInvoice from "../Help Center/pages/Estimates-Invoice";
import Various from "../Help Center/pages/Various";
import CategoryAccounts from "../Help Center/pages/CategoryAccounts";
import CategoryTransactions from "../Help Center/pages/CategoryTransactions";
import CategoryCards from "../Help Center/pages/CategoryCards";
import CategorySettingsSecurity from "../Help Center/pages/CategorySettingsSecurity";
import CategorySponsorship from "../Help Center/pages/CategorySponsorship";
import CategoryPaymentApi from "../Help Center/pages/CategoryPaymentApi";
import CategoryVarious from "../Help Center/pages/CategoryVarious";
import CategoryGettingStart from "../Help Center/pages/CategoryGettingStart";
import CategoryEstimatesInvoices from "../Help Center/pages/CategoryEstimatesInvoices";
import TransferOld from "../Doc/pages/TransferOld";
import PaymentOld from "../Doc/pages/PaymentOld";
import TermsLinkcyConsumer from "../Layouts/Accueil/pages/Terms Linkcy Consumer";

const ForgotPassword = loadable(
    () =>
        import(
            "../Components/Authentication/Password/ForgotPassword/ForgotPassword"
        ),
);
const NewPassword = loadable(
    () =>
        import("../Components/Authentication/Password/NewPassword/NewPassword"),
);

const PaymentSuccess = loadable(
    () => import("../Layouts/Payment/PaymentSuccess"),
);

const PaymentError = loadable(() => import("../Layouts/Payment/PaymentError"));

const TemplateEmail = loadable(
    () => import("../Components/Extra/Email/TemplateEmail"),
);

const Maintenance = loadable(() => import("../Layouts/Maintenace/Maintenance"));

const PublicRoutes = [
    {
        path: "/",
        element: <LanguageProvider />,
        children: [
            {
                path: "",
                element: <LandingBase />,
                children: [
                    {
                        path: "/home",
                        element: <Landing />,
                    },
                    {
                        path: "/accueil",
                        element: <Landing />,
                    },
                    {
                        path: "/",
                        element: <Landing />,
                    },
                    {
                        path: "/support",
                        element: <Support />,
                    },
                    {
                        path: "/about",
                        element: <About />,
                    },
                    {
                        path: "/prices",
                        element: <Prices />,
                    },
                    {
                        path: "/business/prices",
                        element: <PricesBusiness />,
                    },
                    {
                        path: "/partners",
                        element: <Partners />,
                    },
                    {
                        path: "/business",
                        element: <Business />,
                    },
                    {
                        path: "/legal",
                        element: <Legal />,
                    },
                    {
                        path: "/terms-and-conditions-consumer",
                        element: <TermsLinkcyConsumer />,
                    },
                    {
                        path: "/terms-and-conditions",
                        element: <TermAndConditions />,
                    },
                    {
                        path: "/privacy-policy",
                        element: <PrivacyPolicy />,
                    },
                    {
                        path: "/fees",
                        element: <IndividualFees />,
                    },
                    {
                        path: "/business/fees",
                        element: <BusinessFees />,
                    },
                    {
                        path: "/referral",
                        element: <Referrals />,
                    },
                ],
            },
            {
                path: "help-center",
                element: <HelpCenterBase />,
                children: [
                    {
                        path: "intro",
                        element: <HelpCenterIntro />,
                    },
                    {
                        path: "getting-start",
                        element: <CategoryGettingStart />,
                    },
                    {
                        path: "getting-start/:stepType",
                        element: <GettingStart />,
                    },
                    {
                        path: "accounts-management",
                        element: <CategoryAccounts />,
                    },
                    {
                        path: "accounts-management/:stepType",
                        element: <Accounts />,
                    },
                    {
                        path: "transactions",
                        element: <CategoryTransactions />,
                    },
                    {
                        path: "transactions/:stepType",
                        element: <Transactions />,
                    },
                    {
                        path: "cards",
                        element: <CategoryCards />,
                    },
                    {
                        path: "cards/:stepType",
                        element: <Cards />,
                    },
                    {
                        path: "settings-security",
                        element: <CategorySettingsSecurity />,
                    },
                    {
                        path: "settings-security/:stepType",
                        element: <SettingsSecurity />,
                    },
                    {
                        path: "sponsorship",
                        element: <CategorySponsorship />,
                    },
                    {
                        path: "sponsorship/:stepType",
                        element: <Sponsorship />,
                    },
                    {
                        path: "estimates-invoices",
                        element: <CategoryEstimatesInvoices />,
                    },
                    {
                        path: "estimates-invoices/:stepType",
                        element: <EstimatesInvoice />,
                    },
                    {
                        path: "payment-api",
                        element: <CategoryPaymentApi />,
                    },
                    {
                        path: "payment-api/:stepType",
                        element: <PaymentApi />,
                    },
                    {
                        path: "various",
                        element: <CategoryVarious />,
                    },
                    {
                        path: "various/:stepType",
                        element: <Various />,
                    },
                ],
            },
            {
                path: "documentation",
                element: <DocBase />,
                children: [
                    {
                        path: "pay-with-infinexia",
                        element: <InitializePayment />,
                    },
                    {
                        path: "introduction",
                        element: <Introduction />,
                    },
                    {
                        path: "Authentication",
                        element: <Authentication />,
                    },
                    {
                        path: "payment",
                        element: <Payment />,
                    },
                    {
                        path: "transfer",
                        element: <Transfer />,
                    },
                    {
                        path: "webhook",
                        element: <Webhook />,
                    },
                    {
                        path: "payment-old",
                        element: <PaymentOld />,
                    },
                    {
                        path: "transfer-old",
                        element: <TransferOld />,
                    },
                ],
            },
            {
                path: "payment-error",
                element: <PaymentError />,
            },
            {
                path: "payment-checkout",
                element: <InfinexiaPayment />,
            },
            {
                path: "thank-you",
                element: <PaymentSuccess />,
            },
            {
                element: <AnonymousRouteProvider />,
                children: [
                    {
                        path: "login",
                        element: <Login />,
                    },
                    {
                        path: "register/:parrain?",
                        element: <Register />,
                    },
                    {
                        path: "forgot-password",
                        element: <ForgotPassword />,
                    },
                    {
                        path: "reset-password",
                        element: <NewPassword />,
                    },
                ],
            },
            {
                path: "email",
                element: <TemplateEmail />,
            },
            {
                path: "maintenance",
                element: <Maintenance />,
            },
            {
                path: "pots/:slug",
                element: <Pots />,
            },
            {
                path: ":lang",
                children: [
                    {
                        path: "pots/:slug",
                        element: <Pots />,
                    },
                    {
                        path: "maintenance",
                        element: <Maintenance />,
                    },
                    {
                        path: "help-center",
                        element: <HelpCenterBase />,
                        children: [
                            {
                                path: "intro",
                                element: <HelpCenterIntro />,
                            },
                            {
                                path: "getting-start",
                                element: <CategoryGettingStart />,
                            },
                            {
                                path: "getting-start/:stepType",
                                element: <GettingStart />,
                            },
                            {
                                path: "accounts-management",
                                element: <CategoryAccounts />,
                            },
                            {
                                path: "accounts-management/:stepType",
                                element: <Accounts />,
                            },
                            {
                                path: "transactions",
                                element: <CategoryTransactions />,
                            },
                            {
                                path: "transactions/:stepType",
                                element: <Transactions />,
                            },
                            {
                                path: "cards",
                                element: <CategoryCards />,
                            },
                            {
                                path: "cards/:stepType",
                                element: <Cards />,
                            },
                            {
                                path: "settings-security",
                                element: <CategorySettingsSecurity />,
                            },
                            {
                                path: "settings-security/:stepType",
                                element: <SettingsSecurity />,
                            },
                            {
                                path: "sponsorship",
                                element: <CategorySponsorship />,
                            },
                            {
                                path: "sponsorship/:stepType",
                                element: <Sponsorship />,
                            },
                            {
                                path: "estimates-invoices",
                                element: <CategoryEstimatesInvoices />,
                            },
                            {
                                path: "estimates-invoices/:stepType",
                                element: <EstimatesInvoice />,
                            },
                            {
                                path: "payment-api",
                                element: <CategoryPaymentApi />,
                            },
                            {
                                path: "payment-api/:stepType",
                                element: <PaymentApi />,
                            },
                            {
                                path: "various",
                                element: <CategoryVarious />,
                            },
                            {
                                path: "various/:stepType",
                                element: <Various />,
                            },
                        ],
                    },
                    {
                        path: "documentation",
                        element: <DocBase />,
                        children: [
                            {
                                path: "pay-with-infinexia",
                                element: <InitializePayment />,
                            },
                            {
                                path: "introduction",
                                element: <Introduction />,
                            },
                            {
                                path: "Authentication",
                                element: <Authentication />,
                            },
                            {
                                path: "payment",
                                element: <Payment />,
                            },
                            {
                                path: "transfer",
                                element: <Transfer />,
                            },
                            {
                                path: "webhook",
                                element: <Webhook />,
                            },
                            {
                                path: "payment-old",
                                element: <PaymentOld />,
                            },
                            {
                                path: "transfer-old",
                                element: <TransferOld />,
                            },
                        ],
                    },
                    {
                        path: "payment-error",
                        element: <PaymentError />,
                    },
                    {
                        path: "payment-checkout",
                        element: <InfinexiaPayment />,
                    },
                    {
                        path: "thank-you",
                        element: <PaymentSuccess />,
                    },
                    {
                        element: <AnonymousRouteProvider />,
                        children: [
                            {
                                path: "login",
                                element: <Login />,
                            },
                            {
                                path: "register/:parrain?",
                                element: <Register />,
                            },
                            {
                                path: "forgot-password",
                                element: <ForgotPassword />,
                            },
                            {
                                path: "reset-password",
                                element: <NewPassword />,
                            },
                        ],
                    },
                    {
                        path: "",
                        element: <LandingBase />,
                        children: [
                            {
                                path: "home",
                                element: <Landing />,
                            },
                            {
                                path: "accueil",
                                element: <Landing />,
                            },
                            {
                                path: "",
                                element: <Landing />,
                            },
                            {
                                path: "support",
                                element: <Support />,
                            },
                            {
                                path: "about",
                                element: <About />,
                            },
                            {
                                path: "prices",
                                element: <Prices />,
                            },
                            {
                                path: "business/prices",
                                element: <PricesBusiness />,
                            },
                            {
                                path: "partners",
                                element: <Partners />,
                            },
                            {
                                path: "business",
                                element: <Business />,
                            },
                            {
                                path: "legal",
                                element: <Legal />,
                            },
                            {
                                path: "terms-and-conditions-consumer",
                                element: <TermsLinkcyConsumer />,
                            },
                            {
                                path: "terms-and-conditions",
                                element: <TermAndConditions />,
                            },
                            {
                                path: "privacy-policy",
                                element: <PrivacyPolicy />,
                            },
                            {
                                path: "fees",
                                element: <IndividualFees />,
                            },
                            {
                                path: "business/fees",
                                element: <BusinessFees />,
                            },
                            {
                                path: "referral",
                                element: <Referrals />,
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        path: "*",
        element: <Error />,
    },
];

export default PublicRoutes;
