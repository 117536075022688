import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import FidelityModel from "../../Models/Fidelity";
import UserCard, { CARD_TYPE, USER_CARD_STATUS } from "../../Models/UserCard";
import FidelityService from "../../Services/FidelityService/FidelityService";
import UserCardService from "../../Services/UserCard/UserCardService";
import UtilsService from "../../Services/UtilsService";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import OrderCard from "../../Models/OrderCard";
import { mapOrderCard } from "../../Services/OrderCard/OrderCardService";

interface ContextProps {
    kalypayCards: FidelityModel[];
    refreshKalypayCards: () => any;
    userCards: UserCard[];
    refreshUserCards: () => any;
    getActiveCard: () => any;
    getActiveKpCard: () => any;
    refreshLoyalty: () => any;
    selectedCard: UserCard | null;
    currentLoyalty: FidelityModel | null;
    setSelectedCard: Dispatch<SetStateAction<UserCard | null>>;
    cardFilter: USER_CARD_STATUS | null;
    setCardFilter: Dispatch<SetStateAction<USER_CARD_STATUS | null>>;
    setCurrentLoyalty: Dispatch<SetStateAction<FidelityModel | null>>;
    userCards2: UserCard[];

    currentFidelity: UserCard | null;
    currentLoyaltyOrderCardRequest: FidelityModel | null;
    setCurrentLoyaltyOrderCardRequest: Dispatch<
        SetStateAction<FidelityModel | null>
    >;
}

const CardContext = createContext<ContextProps>({
    kalypayCards: [],
    refreshKalypayCards: (): any => false,
    userCards: [],
    refreshUserCards: (): any => false,
    getActiveCard: (): UserCard | undefined => undefined,
    getActiveKpCard: (): FidelityModel | undefined => undefined,
    currentLoyalty: null,
    selectedCard: null,
    setSelectedCard: () => {
        return false;
    },
    cardFilter: null,
    setCardFilter: () => {
        return false;
    },
    setCurrentLoyalty: () => {
        return false;
    },
    refreshLoyalty: (): any => false,
    userCards2: [],
    currentFidelity: null,
    currentLoyaltyOrderCardRequest: null,
    setCurrentLoyaltyOrderCardRequest: () => {
        return false;
    },
});

export default function CardProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [kalypayCards, setKalypayCards] = useState<FidelityModel[]>([]);
    const [userCards, setUserCards] = useState<UserCard[]>([]);
    const [selectedCard, setSelectedCard] = useState<UserCard | null>(null);
    const [cardFilter, setCardFilter] = useState<USER_CARD_STATUS | null>(null);

    const { user } = useAuthentication();
    const [currentLoyalty, setCurrentLoyalty] = useState<FidelityModel | null>(
        null,
    );
    // const [currentLoyalty2, setCurrentLoyalty2] = useState<
    //     FidelityModel[] | undefined
    // >();
    const [currentLoyalty2, setCurrentLoyalty2] = useState<
        OrderCard[] | undefined
    >();

    const [currentFidelity, setCurrentFidelity] = useState<
        OrderCard | undefined
    >();

    const [currentLoyaltyOrderCardRequest, setCurrentLoyaltyOrderCardRequest] =
        useState<FidelityModel | null>(null);
    const getLoyalty = async () => {
        try {
            const res = await FidelityService.getLastValidateCard();
            if (res) setCurrentLoyalty(res);
        } catch (err: any) {}
    };
    const getLastActiveOrderCardRequest = async () => {
        try {
            const res = await FidelityService.getLastActiveOrderCardRequest();
            if (res) setCurrentLoyaltyOrderCardRequest(res);
        } catch (err: any) {}
    };

    const getCurrentFidelity = async () => {
        try {
            const res = await FidelityService.getLastValidateCardV2();
            if (res) setCurrentFidelity(res);
        } catch (err: any) {}
    };

    const getLoyalty2 = async () => {
        try {
            const res = await FidelityService.getOrderCardsDelivered();
            setCurrentLoyalty2(res);
        } catch (err: any) {}
    };

    const refreshKalypayCards = async () => {
        try {
            const res = await FidelityService.getAll();
            setKalypayCards(res);
        } catch (err: any) {}
    };

    const getActiveCard = () => {
        // if (selectedCard && selectedCard.status === USER_CARD_STATUS.ACTIVE)
        //     return selectedCard;

        const black = userCards.find(
            (card) =>
                card.status === USER_CARD_STATUS.ACTIVE &&
                card.card_type === CARD_TYPE.BLACK,
        );

        if (black) return black;

        const gold = userCards.find(
            (card) =>
                card.status === USER_CARD_STATUS.ACTIVE &&
                card.card_type === CARD_TYPE.GOLD,
        );

        if (gold) return gold;

        const blue = userCards.find(
            (card) =>
                card.status === USER_CARD_STATUS.ACTIVE &&
                card.card_type === CARD_TYPE.BLUE,
        );

        return blue;
    };

    const getActiveKpCard = () => {
        const card = getActiveCard();
        let type: string = card ? card.card_type : CARD_TYPE.BLUE;

        if (type == CARD_TYPE.BLUE) {
            type = "blue";
        }
        return kalypayCards.find((card) =>
            card.name.toUpperCase().includes(type.toUpperCase()),
        );
    };

    const refreshUserCards = async () => {
        // getUserCard();
        getCurrentFidelity();
        getLoyalty2();
        getLoyalty();
        getLastActiveOrderCardRequest();
    };

    useEffect(() => {
        refreshKalypayCards();
        // getUserCard();
        getLoyalty();
        getLoyalty2();
        getCurrentFidelity();
        getLastActiveOrderCardRequest();
    }, []);

    const currentCard = [];

    const currentLoy = currentLoyalty;

    if (currentLoy) {
        currentCard.push(
            new UserCard(
                currentLoy.getCardType(),
                USER_CARD_STATUS.ACTIVE,
                "1234",
                0,
                user!.first_name,
                user!.last_name,
                UtilsService.getPhotoUrl(currentLoy.photo || ""),
                currentLoy,
            ),
        );
    }

    let currentFidel: UserCard | null = null;
    const currentOrderCardRequest = [];
    if (currentFidelity) {
        const fd = currentFidelity?.fidelity as FidelityModel | undefined;
        const type = currentFidelity.type;
        let name_card;

        if (
            type === CARD_TYPE.VIRTUAL_CARD ||
            type === CARD_TYPE.VIRTUAL_CARD_U
        ) {
            name_card = type;
        }

        if (fd) {
            const fidelityInstance = new FidelityModel(
                fd.name,
                fd.configuration,
                fd.price,
                fd.klc_value,
            );

            const currentCard: UserCard = new UserCard(
                name_card ? name_card : fidelityInstance.getCardType(),
                USER_CARD_STATUS.ACTIVE,
                "1234",
                0,
                user?.first_name ?? "",
                user?.last_name ?? "",
                UtilsService.getPhotoUrl(fd.photo ?? ""),
                fidelityInstance,
                currentFidelity,
            );

            currentFidel = currentCard;
            currentOrderCardRequest.push(currentCard);
        } else {
            console.log("No fidelity data available.");
        }
    } else {
        console.log("Element is null or undefined.");
    }

    const currentCard2: UserCard[] =
        currentLoyalty2
            ?.map((element) => {
                const fd = element?.fidelity as FidelityModel;
                const type = element.type;
                const is_active = element.is_active;
                let name_card;
                if (
                    type === CARD_TYPE.VIRTUAL_CARD ||
                    type === CARD_TYPE.VIRTUAL_CARD_U
                ) {
                    name_card = element.type;
                }
                if (fd) {
                    const fidelityInstance = new FidelityModel(
                        fd.name,
                        fd.configuration,
                        fd.price,
                        fd.klc_value,
                    );
                    return new UserCard(
                        name_card ? name_card : fidelityInstance.getCardType(),
                        is_active
                            ? USER_CARD_STATUS.ACTIVE
                            : USER_CARD_STATUS.BLOQUED,
                        "1234",
                        0,
                        user?.first_name ?? "",
                        user?.last_name ?? "",
                        UtilsService.getPhotoUrl(fd.photo ?? ""),
                        fidelityInstance,
                        element,
                    );
                }
                return null;
            })
            .filter((card): card is UserCard => card !== null) ?? [];

    return (
        <CardContext.Provider
            value={{
                kalypayCards,
                refreshKalypayCards,
                // userCards: [...currentCard, ...userCards, ...currentCard2],
                userCards: currentCard2,
                selectedCard,
                setSelectedCard,
                cardFilter,
                setCardFilter,
                refreshUserCards,
                getActiveCard,
                getActiveKpCard,
                // currentLoyalty: currentLoy,
                currentLoyalty,
                setCurrentLoyalty,
                refreshLoyalty: getLoyalty,
                userCards2: currentCard2,
                currentFidelity: currentFidel,
                currentLoyaltyOrderCardRequest,
                setCurrentLoyaltyOrderCardRequest,
            }}
        >
            {children}
        </CardContext.Provider>
    );
}

export const useCardContext = () => useContext(CardContext);
