import React from "react";
import { Transition } from "@headlessui/react";
import { useEffect, useRef } from "react";
import SidebarLink from "./SidebarLink";
import SidebarLinkSubgroup from "./SidebarLinkSubGroup";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppProvider } from "../../App/Provider/AppProvider";
import translator from "../../Components/Extra/Translation/Translate";

export default function SupportSidebar({
    onStepChange,
}: {
    onStepChange: (stepId: string) => void;
}) {
    const sidebar = useRef<HTMLDivElement>(null);
    const { sidebarOpen, setSidebarOpen } = useAppProvider();
    const { pathname } = useLocation();
    const { lang } = useParams();
    const { translate } = translator();
    const navigate = useNavigate();

    const segments = pathname;

    // Récupération des segments de l'URL active
    const location = useLocation();

    // Gestion de la fermeture de la sidebar
    useEffect(() => {
        const clickHandler = ({ target }: { target: EventTarget | null }) => {
            if (!sidebar.current || sidebar.current.contains(target as Node))
                return;
            setSidebarOpen(false);
        };

        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    }, [sidebarOpen]);

    return (
        <>
            {/* Backdrop */}
            <Transition
                className="md:hidden absolute inset-0 z-10 bg-slate-900 bg-opacity-20 transition-opacity"
                show={sidebarOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-out duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                aria-hidden="true"
            />

            {/* Sidebar */}
            <div ref={sidebar}>
                <Transition
                    show={sidebarOpen}
                    unmount={false}
                    as="aside"
                    id="sidebar"
                    className="absolute left-0 top-0 bottom-0 w-72 h-full border-r border-slate-200 md:left-auto md:shrink-0 z-10 md:!opacity-100 md:!block dark:border-slate-800 dark:bg-slate-900"
                    enter="transition ease-out duration-200 transform"
                    enterFrom="opacity-0 -translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition ease-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {/* Gradient bg displaying on light layout only */}
                    <div
                        className="absolute inset-0 -left-[9999px] bg-gradient-to-b from-slate-50 to-white pointer-events-none -z-10 dark:hidden"
                        aria-hidden="true"
                    ></div>

                    <div className="fixed top-0 bottom-0 w-72 px-0 sm:px-6 md:pl-0 md:pr-0 overflow-y-auto no-scrollbar">
                        <div className="pt-24 md:pt-32 pb-8">
                            {/* Docs nav */}
                            <nav className="md:block w-full">
                                {/* 1st level */}
                                <SidebarLinkGroup
                                    open={segments.includes("documentation")}
                                >
                                    {(handleClick, open) => {
                                        return (
                                            <>
                                                <a
                                                    href="#0"
                                                    className={`relative flex items-center text-lg font-[650] text-slate-800 p-1 before:absolute before:inset-0 before:rounded before:bg-gradient-to-tr before:from-blue-400 before:to-purple-500 before:opacity-20 before:-z-10 before:pointer-events-none dark:text-black ${
                                                        !segments.includes(
                                                            "documentation",
                                                        ) && "before:hidden"
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(
                                                            "/documentation/introduction",
                                                        );
                                                    }}
                                                >
                                                    <svg
                                                        className="ml-5 mr-2 shrink-0"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            className="fill-blue-400"
                                                            d="M19.888 7.804a.88.88 0 0 0-.314-.328l-7.11-4.346a.889.889 0 0 0-.927 0L4.426 7.476a.88.88 0 0 0-.314.328L12 12.624l7.888-4.82Z"
                                                        />
                                                        <path
                                                            className="fill-white dark:fill-slate-800"
                                                            d="M4.112 7.804a.889.889 0 0 0-.112.43v7.892c0 .31.161.597.426.758l7.11 4.346c.14.085.3.13.464.13v-8.736l-7.888-4.82Z"
                                                        />
                                                        <path
                                                            className="fill-blue-600"
                                                            d="M19.888 7.804c.073.132.112.28.112.43v7.892c0 .31-.161.597-.426.758l-7.11 4.346c-.14.085-.3.13-.464.13v-8.736l7.888-4.82Z"
                                                        />
                                                    </svg>
                                                    <span className="dark:!text-slate-200">
                                                        {translate(
                                                            "DOC_API_PAGE",
                                                            "DOCUMENTATION_API",
                                                        )}
                                                    </span>
                                                </a>
                                            </>
                                        );
                                    }}
                                </SidebarLinkGroup>
                                {/* Authentication */}
                                <ul className="text-sm text-black dark:text-black">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "Authentication",
                                        )}
                                        open={segments.includes("")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/documentation/authentication`
                                                    : "/documentation/authentication"
                                            }
                                        >
                                            <span className="flex items-center space-x-2">
                                                <span>
                                                    {translate(
                                                        "DOC_API_PAGE",
                                                        "Authentication",
                                                    )}
                                                </span>
                                                <span className="absolute right-3 text-xs font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                    POST
                                                </span>
                                            </span>
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Paiement */}
                                <ul className="text-sm text-black dark:text-black space-y-4">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "Payment",
                                        )}
                                        open={segments.includes("")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/documentation/payment/#initialize-payment`
                                                    : "/documentation/payment/#initialize-payment"
                                            }
                                        >
                                            <span className="flex justify-between items-center ">
                                                <span>
                                                    {translate(
                                                        "DOC_API_PAGE",
                                                        "Initialize_Payment",
                                                    )}
                                                </span>
                                                <span className="absolute right-3 text-xs font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                    POST
                                                </span>
                                            </span>
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/documentation/payment/#check-status`
                                                    : "/documentation/payment/#check-status"
                                            }
                                        >
                                            <span className="flex items-center space-x-2">
                                                <span>
                                                    {translate(
                                                        "DOC_API_PAGE",
                                                        "API_Check_Status",
                                                    )}
                                                </span>
                                                <span className="absolute right-3 text-xs font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                    POST
                                                </span>
                                            </span>
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/documentation/payment/#get-fee-and-available-wallets`
                                                    : "/documentation/payment/#get-fee-and-available-wallets"
                                            }
                                        >
                                            <span className="flex items-center space-x-2">
                                                <span>
                                                    {translate(
                                                        "DOC_API_PAGE",
                                                        "Get_Fee_And_Wallets",
                                                    )}
                                                </span>
                                                <span className="absolute right-3 text-xs font-semibold text-green-500 border border-green-500 dark:border-green-500 px-2 rounded">
                                                    GET
                                                </span>
                                            </span>
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Transfer */}
                                <ul className="text-sm text-black dark:text-black">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "Transfer",
                                        )}
                                        open={segments.includes("")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/documentation/transfer/#initialize-single-transfer`
                                                    : "/documentation/transfer/#initializesingle-transfer"
                                            }
                                        >
                                            <span className="flex items-center space-x-2">
                                                <span>
                                                    {translate(
                                                        "DOC_API_PAGE",
                                                        "REQUEST_TRANSFER",
                                                    )}
                                                </span>
                                                <span className="absolute right-3 text-xs font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                    POST
                                                </span>
                                            </span>
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/documentation/transfer/#check-status`
                                                    : "/documentation/transfer/#check-status"
                                            }
                                        >
                                            <span className="flex items-center space-x-2">
                                                <span>
                                                    {translate(
                                                        "DOC_API_PAGE",
                                                        "API_Check_Status",
                                                    )}
                                                </span>
                                                <span className="absolute right-3 text-xs font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                    POST
                                                </span>
                                            </span>
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Authentication */}
                                <ul className="text-sm text-black dark:text-black">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "Webhook",
                                        )}
                                        open={segments.includes("")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/documentation/webhook`
                                                    : "/documentation/webhook"
                                            }
                                        >
                                            <span className="flex items-center space-x-2">
                                                <span>
                                                    {translate(
                                                        "DOC_API_PAGE",
                                                        "Webhook",
                                                    )}
                                                </span>
                                                <span className="absolute right-3 text-xs font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 py-0 rounded">
                                                    POST
                                                </span>
                                            </span>
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </Transition>
            </div>
        </>
    );
}
