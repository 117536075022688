import React from "react";

const Banner = ({ title }: { title: string }) => {
    return (
        <div className="h-16 flex items-center mb-6">
            <svg
                width="64"
                height="54"
                viewBox="0 0 64 54"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="fill-blue-400"
                    d="M43.832 7.206a1.32 1.32 0 0 0-.47-.492L32.694.195a1.333 1.333 0 0 0-1.39 0L20.64 6.714c-.198.12-.36.29-.471.492L32 14.436l11.832-7.23Z"
                ></path>
                <path
                    className="fill-white dark:fill-slate-800"
                    d="M20.168 7.206c-.11.197-.168.42-.168.645V19.69c0 .464.242.895.639 1.137l10.666 6.519c.21.128.45.195.695.196V14.437L20.168 7.206Z"
                ></path>
                <path
                    className="fill-blue-600"
                    d="M43.832 7.206c.11.197.168.42.168.645V19.69c0 .464-.242.895-.639 1.137l-10.666 6.519c-.21.128-.45.195-.695.196V14.437l11.832-7.231Z"
                ></path>
                <path
                    className="fill-blue-400"
                    d="M63.832 19.451a1.32 1.32 0 0 0-.47-.492l-10.667-6.518a1.333 1.333 0 0 0-1.39 0L40.64 18.959c-.198.12-.36.29-.471.492L52 26.683l11.832-7.232Z"
                ></path>
                <path
                    className="fill-white dark:fill-slate-800"
                    d="M40.168 19.451c-.11.198-.168.42-.168.647v11.837c0 .465.242.896.639 1.138l10.666 6.518c.21.128.45.196.695.196V26.683l-11.832-7.232Z"
                ></path>
                <path
                    className="fill-blue-600"
                    d="M63.832 19.451c.11.198.168.42.168.647v11.837c0 .465-.242.896-.639 1.138L52.695 39.59c-.21.128-.45.196-.695.196V26.683l11.832-7.232Z"
                ></path>
                <path
                    className="fill-blue-400"
                    d="M23.832 19.451a1.32 1.32 0 0 0-.47-.492l-10.667-6.518a1.333 1.333 0 0 0-1.39 0L.64 18.959c-.198.12-.36.29-.471.492L12 26.683l11.832-7.232Z"
                ></path>
                <path
                    className="fill-white dark:fill-slate-800"
                    d="M.168 19.451c-.11.198-.168.42-.168.647v11.837c0 .465.242.896.639 1.138l10.666 6.518c.21.128.45.196.695.196V26.683L.168 19.451Z"
                ></path>
                <path
                    className="fill-blue-600"
                    d="M23.832 19.451c.11.198.168.42.168.647v11.837c0 .465-.242.896-.639 1.138L12.695 39.59c-.21.128-.45.196-.695.196V26.683l11.832-7.232Z"
                ></path>
                <path
                    className="fill-blue-400"
                    d="M43.832 32.769a1.32 1.32 0 0 0-.47-.492l-10.667-6.52a1.333 1.333 0 0 0-1.39 0l-10.666 6.52c-.198.12-.36.29-.471.492L32 39.999l11.832-7.23Z"
                ></path>
                <path
                    className="fill-white dark:fill-slate-800"
                    d="M20.168 32.769c-.11.197-.168.42-.168.645V45.25c0 .465.242.896.639 1.138l10.666 6.518c.21.128.45.196.695.196V40l-11.832-7.23Z"
                ></path>
                <path
                    className="fill-blue-600"
                    d="M43.832 32.769c.11.197.168.42.168.645V45.25c0 .465-.242.896-.639 1.138l-10.666 6.518c-.21.128-.45.196-.695.196V40l11.832-7.23Z"
                ></path>
            </svg>
            <span className="font-bold sm:!text-xl md:!text-2xl text-blue-600 ml-4">
                {title}
            </span>
        </div>
    );
};

export default Banner;
