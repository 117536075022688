import React from "react";

const BookOpenCoverSvg = () => {
    return (
        <svg
            className="md:w-[36px] md:h-[36px] fill-blue-600"
            id="icon-books"
            width="34"
            height="34"
            viewBox="0 0 32 32"
        >
            <path d="M15 25.875v-19.625c0 0-2.688-2.25-6.5-2.25s-6.5 2-6.5 2v19.875c0 0 2.688-1.938 6.5-1.938s6.5 1.938 6.5 1.938zM29 25.875v-19.625c0 0-2.688-2.25-6.5-2.25s-6.5 2-6.5 2v19.875c0 0 2.688-1.938 6.5-1.938s6.5 1.938 6.5 1.938zM31 8h-1v19h-12v1h-5v-1h-12v-19h-1v20h12v1h7.062l-0.062-1h12v-20z"></path>
        </svg>
    );
};

export default BookOpenCoverSvg;
