import React, { useEffect, useState } from "react";

import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ChevronCloseSvg from "../../Components/Extra/Svg/ChevronCloseSvg";
import ChevronOpenSvg from "../../Components/Extra/Svg/ChevronOpenSvg";
import Samples from "../Layout/RequestSamples";
import SamplesRequest from "../Layout/RequestSamples";
import { WebhookRequestSamples } from "../Layout/CodeRequestSamples";
import BodySchema from "../Layout/BodySchemas";
import { RequestHeaderSchema, WebhookSchema } from "../Layout/CodeBodySchemas";
import SamplesResponses from "../Layout/ResponsesSamples";
import { WebhookResponses } from "../Layout/CodeResponsesSamples";
import ResponsesSchema from "../Layout/ResponsesSchemas";
import {
    CheckStatusTransferResponses200,
    WebhookResponses200,
    WebhookResponses401,
    WebhookResponses500,
} from "../Layout/CodeResponsesSchemas";

const Webhook = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL
    const [isOpen, setIsOpen] = useState(false);
    const webhookEndpoint = "{webhookEndpoint}";

    const schemas = [RequestHeaderSchema, WebhookSchema];

    type SectionKeys =
        | "webhook"
        | "webhook-responses"
        | "webhook-200"
        | "webhook-401"
        | "webhook-500";

    const [openSections, setOpenSections] = useState<
        Record<SectionKeys, boolean>
    >({
        webhook: true,
        "webhook-responses": true,
        "webhook-200": false,
        "webhook-401": false,
        "webhook-500": false,
    });

    const toggleSection = (section: SectionKeys) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1)); // Retire le '#' pour obtenir l'ID
            if (element) {
                element.scrollIntoView({ behavior: "smooth" }); // Défilement fluide
            }
        }
    }, [location]);

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "Webhook");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "Webhook");
        }
    };
    return (
        <>
            <Banner title={translate("DOC_API_PAGE", "Webhook")}></Banner>
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/documentation/introduction`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("DOC_API_PAGE", "DOCUMENTATION")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="w-full space-y-8 text-slate-600 dark:text-slate-400 font-normal sm:!text-sm md:!text-base">
                <div>
                    <header className="mb-6">
                        <h1
                            id="pay-with"
                            data-scrollspy-target=""
                            className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200"
                        >
                            {translate(
                                "DOC_API_PAGE",
                                "API_Service_Description",
                            )}
                        </h1>
                    </header>
                    <div className="space-y-6">
                        {/**********  Webhook  **********/}
                        <section id="webhook" className="space-y-1">
                            <SubTitle id="webhook" title="Webhook" />
                            <p className="sm:!w-full md:!w-[50%]">
                                {translate(
                                    "DOC_API_PAGE",
                                    "API_Webhook_Description",
                                )}
                            </p>

                            <div className="grid grid-cols-1 md:!grid-cols-[55%_40%] gap-x-12 gap-y-8 !mt-5 sm:!w-full pb-12">
                                {/* Colonne gauche */}
                                <div>
                                    <div className="border-2 rounded-lg p-5 dark:border-slate-200 dark:border">
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() =>
                                                toggleSection("webhook")
                                            }
                                        >
                                            <SubTitle2 title="Data" />
                                            {openSections["webhook"] ? (
                                                <ChevronCloseSvg />
                                            ) : (
                                                <ChevronOpenSvg />
                                            )}
                                        </div>
                                        {openSections["webhook"] && (
                                            <div className="mt-3 space-y-8">
                                                <div className="">
                                                    <BodySchema
                                                        title={
                                                            WebhookSchema.title
                                                        }
                                                        fields={
                                                            WebhookSchema.fields
                                                        }
                                                        isWebhook={true}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Colonne droite */}
                                <div className="space-y-5">
                                    <div className="pt-0">
                                        <SamplesRequest
                                            titleBox="Request data"
                                            codeSamples={WebhookRequestSamples}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Webhook;
