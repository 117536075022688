import FidelityModel from "./Fidelity";
import User from "./User";

export interface IOrderCardRequestPayload {
    // shipping: OrderCardRequestShipping;
    // type: OrderCardRequestType;
    debit_wallet?: string;
    agent_uid?: string;
    address?: string;
    transaction_password: string;
    is_express?: boolean;
}

export enum OrderCardRequestShipping {
    COMMERCIAL = "commercial",
    SALE_MANAGER = "sale_manager",
    HOME = "home",
}

export enum OrderCardRequestStatus {
    PENDING = "pending",
    DECLINED = "declined",
    SAVED = "saved",
    DELIVERED_TO_AGENT = "delivered_to_agent",
    DELIVERED = "delivered",
}
export enum ReasonCardRequest {
    LOST_CARD = "lost_card",
    STOLEN_CARD = "stolen_card",
    DEFAULT = "default",
}

export default class OrderCard {
    id?: number;
    user_name: string;
    last_name: string;
    first_name: string;
    uid: string;
    email: string;
    agent_user_name?: string;
    agent_last_name?: string;
    agent_first_name?: string;
    agent_uid?: string;
    agent_email?: string;
    // type: CARD_TYPE;
    type: any;
    status: OrderCardRequestStatus;
    shipping: OrderCardRequestShipping;
    address?: string;
    postal_code?: string;
    city?: string;
    country?: string;
    created_at: Date;
    is_express?: boolean;
    home_address?: string;
    fidelity?: FidelityModel;
    user?: User;
    is_active?: boolean;
    reason?: ReasonCardRequest;
    constructor(
        uid: string,
        user_name: string,
        last_name: string,
        first_name: string,
        email: string,
        type: CARD_TYPE,
        status: OrderCardRequestStatus,
        shipping: OrderCardRequestShipping,
        created_at: Date,
    ) {
        this.uid = uid;
        this.user_name = user_name;
        this.last_name = last_name;
        this.first_name = first_name;
        this.email = email;
        this.type = type;
        this.status = status;
        this.shipping = shipping;
        this.created_at = created_at;
    }
}

export interface OrderData {
    last_name?: string;
    first_name?: string;
    email?: string;
    address?: string;
    city?: string;
    country?: string;
    post_code?: string;
    phone_country_code?: string;
    phone?: string;
    dob?: string;
}

export interface PayloadSaveCard {
    accountId: string;
    otherAccountId: string;
    firstName: string;
    lastName: string;
    secondName?: string;
    email: string;
    address: string;
    city: string;
    phoneNumber: string;
    phoneCountryCode: string;
    country: string;
    state: string;
    dateOfBirth: string;
    postalCode: string;
}

export interface IDelivered {
    url?: string;
}

export enum CARD_TYPE {
    BLUE = "blue",
    GOLD = "gold",
    BLACK = "black",
    VIRTUAL_CARD = "virtual_card",
    VIRTUAL_CARD_U = "virtual_card_u",
}
