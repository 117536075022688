import React, { useState, useEffect } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";

const img_settings = "/dist/image/Settings.png";
const img_modif = "/dist/image/Modif-estimates-invoices.png";
const img_send = "/dist/image/Send-estimates-invoices.png";
const img_transform = "/dist/image/Transform-estimates.png";
const img_payments = "/dist/image/Invoices-payments.png";

const EstimatesInvoice = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            case "configure-estimates-invoices":
                return translate("HELPCENTER", "Config_Estimates_Invoices");
            case "create-send-estimates":
                return translate("HELPCENTER", "Create_Estimates");
            case "create-send-invoices":
                return translate("HELPCENTER", "Create_Invoices");
            default:
                return translate("HELPCENTER", "Estimates_Invoices");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            case "configure-estimates-invoices":
                return translate("HELPCENTER", "Estimates_Invoices_Configure");
            case "create-send-estimates":
                return translate("ESTIMATES_INVOICES", "Estimates");
            case "create-send-invoices":
                return translate("ESTIMATES_INVOICES", "Invoices");
            default:
                return translate("HELPCENTER", "Estimates_Invoices");
        }
    };

    const renderContentForStep = () => {
        switch (currentStep) {
            case "configure-estimates-invoices":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="configure-estimates-invoices"
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Invoices_Sub",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Invoices_Sub2",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Invoices_Sub3",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "1. Configurer Devis/Factures",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER",
                                            "Estimates_Invoices",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        <span className="flex justify-start">
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Invoices_Click_Top",
                                            )}
                                            <img
                                                className="-mt-1 ml-1 h-8 w-auto align-middle"
                                                src={img_settings}
                                            />
                                        </span>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Invoices_Logo",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Invoices_Color",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Invoices_TVA",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Invoices_Payment_Method",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Invoices_Accompte",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Invoices_Note_Bottom",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «{translate("HELPCENTER", "Modify")}
                                            »
                                        </strong>
                                        .
                                    </li>
                                </ul>
                                <p className="pt-5 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Invoices_Configured",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "create-send-estimates":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="create-send-estimates"
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_After_Config",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_After_Config_Sub",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Crea_1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER",
                                            "Estimates_Invoices",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}
                                        <strong>
                                            {" "}
                                            «
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Crea_2",
                                            )}
                                            ».
                                        </strong>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Date",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Client_Infos",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Object",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Object2",
                                            )}
                                            ».
                                        </strong>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Line",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Line2",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Line3",
                                            )}
                                            ».
                                        </strong>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Val-Date",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Save_Dowdnload",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Modif1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Modif2",
                                    )}
                                </p>
                                <p className="flex justify-start sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Modif3",
                                    )}
                                    <img
                                        className="-mt-1 ml-1 h-8 w-auto align-middle"
                                        src={img_modif}
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Send1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Send2",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Send3",
                                        )}
                                    </li>
                                    <li>
                                        <span className="flex justify-start">
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Send4",
                                            )}
                                            <img
                                                className="-mt-1 ml-1 h-8 w-auto align-middle"
                                                src={img_send}
                                            />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Transform1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Transform2",
                                    )}
                                </p>
                                <p className="flex justify-start  sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Modif3",
                                    )}
                                    <img
                                        className="-mt-1 ml-1 h-8 w-auto align-middle"
                                        src={img_transform}
                                    />
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "create-send-invoices":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="create-send-estimates"
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_After_Config",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_After_Config_Sub",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_Crea_1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "HELPCENTER",
                                            "Estimates_Invoices",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}
                                        <strong>
                                            {" "}
                                            «
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Invoices_Crea_2",
                                            )}
                                            ».
                                        </strong>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Date",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Client_Infos",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Object",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Object2",
                                            )}
                                            ».
                                        </strong>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Line",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Line2",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Line3",
                                            )}
                                            ».
                                        </strong>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Val-Date",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Estimates_Save_Dowdnload",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_Modif1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_Modif2",
                                    )}
                                </p>
                                <p className="flex justify-start  sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Estimates_Modif3",
                                    )}
                                    <img
                                        className="-mt-1 ml-1 h-8 w-auto align-middle"
                                        src={img_modif}
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_Send1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_Send2",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Invoices_Send3",
                                        )}
                                    </li>
                                    <li>
                                        <span className="flex justify-start">
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Send4",
                                            )}
                                            <img
                                                className="-mt-1 ml-1 h-8 w-auto align-middle"
                                                src={img_send}
                                            />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_Payment1",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_ESTIMATES-INVOICES",
                                        "Invoices_Payment2",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        <span className="flex justify-start">
                                            {translate(
                                                "HELPCENTER_ESTIMATES-INVOICES",
                                                "Estimates_Modif3",
                                            )}
                                            <img
                                                className="-mt-1 ml-1 h-8 w-auto align-middle"
                                                src={img_payments}
                                            />
                                        </span>
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Invoices_Payment3",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Invoices_Payment4",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_ESTIMATES-INVOICES",
                                            "Invoices_Payment5",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «{translate("HELPCENTER", "Save")}»
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                );
            default:
                return null; // Aucun contenu par défaut si le type de compte n'est pas reconnu
        }
    };

    return (
        <>
            <Banner title={getBannerTitle()} />
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/help-center/estimates-invoices`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("HELPCENTER", "Estimates_Invoices")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape (Fundamentals ou autre) */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div>
                        <div className="text-slate-600 dark:text-slate-600 space-y-6">
                            {/* Affiche le contenu selon currentStep */}
                            {renderContentForStep()}
                        </div>
                    </div>
                    <div className="hidden md:block pt-10">
                        <p className="sm:!text-sm md:!text-base text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}{" "}
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700 font-normal"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                    <div className="md:hidden py-10">
                        <p className="sm:!text-sm md:!text-base text-center text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}
                        </p>
                        <p className="sm:!text-sm md:!text-base text-center text-black font-normal">
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                </div>
            </article>
        </>
    );
};

export default EstimatesInvoice;
