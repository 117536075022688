import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HighNoticeService from "../../../Services/HighNoticeService/HighNoticeService";
import HighNotice from "../../../Models/HighNotice";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";

const Anniversaire = "/dist/image/Anniversaire.webp";
const voyage = "/dist/image/Vacances.webp";
const facture = "/dist/image/Factures.webp";
const family = "/dist/image/Épargne-enfants.webp";
const salaire = "/dist/image/Salaire.webp";

export const CarouselComponent = () => {
    const { translate } = translator();
    return (
        <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay={true}
            infinite
            autoPlaySpeed={3500}
            customTransition="all 1s"
            centerMode={false}
            className="mb-9 bg-transparent md:!mx-20 mx-5 pb-16 !overflow-visible"
            containerClass="container-with-dots"
            dotListClass="custom-dot-list-style mb-20"
            draggable
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000, //3000
                        min: 1024,
                    },
                    items: 3,
                },
                mobile: {
                    breakpoint: {
                        max: 767,
                        min: 0,
                    },
                    items: 1,
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 768,
                    },
                    items: 1,
                },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {/* Premier */}
            <div className="bg-transparent px-[10px] md:!px-[10px] lg:!px[20px] xl:!px-[25px]">
                <img
                    src={salaire}
                    alt=""
                    className="bg-transparent w-full rounded-lg object-fill user-drag-none "
                />
                <div className="bg-transparent grid justify-center m-auto font-medium absolute bottom-[0.25rem] md:!bottom-[0.75rem] left-1/2 translate-x-[-50%] text-white w-full">
                    <div className="box p-1 my-1 relative py-1 md:!py-2 w-72 md:!w-72 dark:!bg-white">
                        <div className="absolute top-2 left-[5px] bg-green-500 w-2 h-2 rounded-full"></div>
                        <div className="flex justify-between mb-1  mt-3 px-2">
                            <div className="text-black font-bold text-xs">
                                {translate("HOME_PAGE", "C5_title")}
                            </div>
                            <div className="text-green-600 text-xs">
                                + 2 147 EUR
                            </div>
                        </div>
                        <div className="flex justify-between px-2">
                            <div className="text-xs text-slate-500 block w-full h-[25px]">
                                {translate("HOME_PAGE", "C5_desc")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Deuxième */}
            <div className="bg-transparent px-[10px] md:!px-[10px] lg:!px[20px] xl:!px-[25px]">
                <img
                    src={facture}
                    alt=""
                    className="bg-transparent w-full rounded-lg object-fill user-drag-none "
                />
                <div className="bg-transparent grid justify-center m-auto font-medium absolute bottom-[0.25rem] md:!bottom-[0.75rem] left-1/2 translate-x-[-50%] text-white w-full">
                    <div className="box p-1 my-1 relative py-1 md:!py-2 w-72 md:!w-72 dark:!bg-white">
                        <div className="absolute top-2 left-[5px] bg-green-500 w-2 h-2 rounded-full"></div>
                        <div className="flex justify-between mb-1  mt-3 px-2">
                            <div className="text-black font-bold text-xs">
                                {translate("HOME_PAGE", "C1_title")}
                            </div>
                            <div className="text-red-600 text-xs">
                                - 780 EUR
                            </div>
                        </div>
                        <div className="flex justify-between px-2">
                            <div className="text-xs text-slate-500 block w-full h-[25px]">
                                {translate("HOME_PAGE", "C1_desc")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Troisième */}
            <div className="bg-transparent px-[10px] md:!px-[10px] lg:!px[20px] xl:!px-[25px]">
                <img
                    src={family}
                    alt=""
                    className="bg-transparent w-full rounded-lg object-fill user-drag-none "
                />
                <div className="bg-transparent grid justify-center m-auto font-medium absolute bottom-[0.25rem] md:!bottom-[0.75rem] left-1/2 translate-x-[-50%] text-white w-full">
                    <div className="box p-1 my-1 relative py-1 md:!py-2 w-72 md:!w-72 dark:!bg-white">
                        <div className="absolute top-2 left-[5px] bg-green-500 w-2 h-2 rounded-full"></div>
                        <div className="flex justify-between mb-1  mt-3 px-2">
                            <div className="text-black font-bold text-xs">
                                {translate("HOME_PAGE", "C3_title")}
                            </div>
                            <div className="text-green-600 text-xs">
                                + 150 EUR
                            </div>
                        </div>
                        <div className="flex justify-between px-2">
                            <div className="text-xs text-slate-500 block w-full h-[25px]">
                                {translate("HOME_PAGE", "C3_desc")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Quatrième */}
            <div className="bg-transparent px-[10px] md:!px-[10px] lg:!px[20px] xl:!px-[25px]">
                <img
                    src={voyage}
                    alt=""
                    className="bg-transparent w-full rounded-lg object-fill user-drag-none "
                />
                <div className="bg-transparent grid justify-center m-auto font-medium absolute bottom-[0.25rem] md:!bottom-[0.75rem] left-1/2 translate-x-[-50%] text-white w-full">
                    <div className="box p-1 my-1 relative py-1 md:!py-2 w-72 md:!w-72 dark:!bg-white">
                        <div className="absolute top-2 left-[5px] bg-green-500 w-2 h-2 rounded-full"></div>
                        <div className="flex justify-between mb-1  mt-3 px-2">
                            <div className="text-black font-bold text-xs">
                                {translate("HOME_PAGE", "C2_title")}
                            </div>
                            <div className="text-red-600 text-xs">
                                - 2 100 EUR
                            </div>
                        </div>
                        <div className="flex justify-between px-2">
                            <div className="text-xs text-slate-500 block w-full h-[25px]">
                                {translate("HOME_PAGE", "C2_desc")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Cinquième */}
            <div className="bg-transparent px-[10px] md:!px-[10px] lg:!px[20px] xl:!px-[25px]">
                <img
                    src={Anniversaire}
                    alt=""
                    className="bg-transparent w-full rounded-lg object-fill user-drag-none "
                />
                <div className="bg-transparent grid justify-center m-auto font-medium absolute bottom-[0.25rem] md:!bottom-[0.75rem] left-1/2 translate-x-[-50%] text-white w-full">
                    <div className="box p-1 my-1 relative py-1 md:!py-2 w-72 md:!w-72 dark:!bg-white">
                        <div className="absolute top-2 left-[5px] bg-green-500 w-2 h-2 rounded-full"></div>
                        <div className="flex justify-between mb-1  mt-3 px-2">
                            <div className="text-black font-bold text-xs">
                                {translate("HOME_PAGE", "C4_title")}
                            </div>
                            <div className="text-green-600 text-xs">
                                + 200 EUR
                            </div>
                        </div>
                        <div className="flex justify-between px-2">
                            <div className="text-xs text-slate-500 block w-full h-[25px]">
                                {translate("HOME_PAGE", "C4_desc")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel>
    );
};
