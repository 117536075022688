import React, { useState, useEffect } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";

const Transactions = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            case "receive-money":
                return translate("HELPCENTER", "Recevoir de l'argent");
            case "send-money":
                return translate("HELPCENTER", "Envoyer de l'argent");
            case "bank-transfers-and-direct-debits":
                return translate("HELPCENTER", "Virements & prélèvements");
            default:
                return translate("HELPCENTER", "Transactions");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            case "receive-money":
                return translate("HELPCENTER", "Recevoir de l'argent");
            case "send-money":
                return translate("HELPCENTER", "Envoyer de l'argent");
            case "bank-transfers-and-direct-debits":
                return translate("HELPCENTER", "Virements & prélèvements");
            default:
                return translate("HELPCENTER", "Transactions");
        }
    };

    const renderContentForStep = () => {
        switch (currentStep) {
            case "receive-money":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="receive-money"
                                    title={translate(
                                        "HELPCENTER",
                                        "Si vous n'avez pas encore de compte Infinexia, voici comment procéder pour en ouvrir un facilement :",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Vous pouvez ouvrir un compte Infinexia gratuitement sans aucune conditions de revenus.",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Pour cela vous devez avoir plus de 18 Ans et de résider légalement dans des pays pris en charge.",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "1. Créez votre compte",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Rendez-vous sur le site ou via l'application et suivez les étapes suivantes pour créer votre profil :",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Cliquez sur Créer un compte.",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Renseignez vos informations personnelles (Email, nom et prénom).",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Créez un mot de passe de connexion.",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Puis validez en cliquant sur Créer un compte.",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "2. Completez vos informations personelles",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Après avoir créé votre profil, renseignez le reste de vos informations personelles :",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Renseignez votre date de naissance, numéro de téléphone et adresse.",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Selectionnez la langue d'affichage que vous souhaitez.",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Selectionnez la devise de votre compte principal.",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Puis validez en cliquant sur Enregistrer.",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "3. Alimentez votre compte",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Alimentez votre compte pour commencer à utiliser les services Infinexia immédiatement.",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "send-money":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="send-money"
                                    title={translate(
                                        "HELPCENTER",
                                        "Après avoir créé votre profil, il est temps de choisir l'offre Infinexia qui correspond à vos besoins :",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Vous avez le choix entre plusieurs types de comptes adaptés à vos besoins, que ce soit pour un usage personnel ou professionnel",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "1. Souscrire une offre",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Rendez-vous sur la page abonnements et suivez les étapes suivantes :",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Selectionnez l'offre qui correspond le mieux à vos besoins.",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Choisissez si vous souhaitez payer mensuellement ou annuellement.",
                                        )}{" "}
                                        {translate(
                                            "HELPCENTER",
                                            "(2 mois offerts en optant pour l'abonnement annuel)",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Puis validez en cliquant sur Souscrire.",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "2. Vérifiez votre identité",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Suivez les étapes suivantes pour vérifier votre identité :",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Effectuer un selfie pour confirmer que vous êtes réellement derrière la caméra",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Fournir un document d'identité officiel en cours de validité (passeport ou carte d’identité).",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note :")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Après la validation de votre identité, vous serez informé par email et notification.",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Vous aurez accès à votre IBAN et pourrez commander une carte physique ou générer une carte virtuelle.",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "bank-transfers-and-direct-debits":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="bank-transfers-and-direct-debits"
                                    title={translate(
                                        "HELPCENTER",
                                        "Après avoir créé votre profil, il est temps de choisir l'offre Infinexia qui correspond à vos besoins :",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Vous avez le choix entre plusieurs types de comptes adaptés à vos besoins, que ce soit pour un usage personnel ou professionnel",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "1. Souscrire une offre",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Rendez-vous sur la page abonnements et suivez les étapes suivantes :",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Selectionnez l'offre qui correspond le mieux à vos besoins.",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Choisissez si vous souhaitez payer mensuellement ou annuellement.",
                                        )}{" "}
                                        {translate(
                                            "HELPCENTER",
                                            "(2 mois offerts en optant pour l'abonnement annuel)",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Puis validez en cliquant sur Souscrire.",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER",
                                        "2. Vérifiez votre identité",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Suivez les étapes suivantes pour vérifier votre identité :",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Effectuer un selfie pour confirmer que vous êtes réellement derrière la caméra",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "Fournir un document d'identité officiel en cours de validité (passeport ou carte d’identité).",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note :")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Après la validation de votre identité, vous serez informé par email et notification.",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER",
                                        "Vous aurez accès à votre IBAN et pourrez commander une carte physique ou générer une carte virtuelle.",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            default:
                return null; // Aucun contenu par défaut si le type de compte n'est pas reconnu
        }
    };

    return (
        <>
            <Banner title={getBannerTitle()} />
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/help-center/transactions`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("HELPCENTER", "Transactions")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape (Fundamentals ou autre) */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div>
                        <div className="text-slate-600 dark:text-slate-600 space-y-6">
                            {/* Affiche le contenu selon currentStep */}
                            {renderContentForStep()}
                        </div>
                    </div>
                    <div className="hidden md:block pt-10">
                        <p className="sm:!text-sm md:!text-base text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}{" "}
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700 font-normal"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                    <div className="md:hidden py-10">
                        <p className="sm:!text-sm md:!text-base text-center text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}
                        </p>
                        <p className="sm:!text-sm md:!text-base text-center text-black font-normal">
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Transactions;
