import React, { useState } from "react";
import APICircleCheckSvg from "../../Components/Extra/Svg/APICircleCheckSvg";

interface Field {
    name: string;
    required?: string;
    type: string;
    description?: string;
    enum?: string | number | (string | number)[];
    value?: string | number;
    subfields?: Field[];
}

interface BodySchemaProps {
    title: string;
    fields: Field[];
    isRequestBody?: boolean;
    isWebhook?: boolean;
    isHeader?: boolean; // Ajout du prop pour contrôler le titre
}

const BodySchema: React.FC<BodySchemaProps> = ({
    title,
    fields,
    isRequestBody = true,
    isWebhook = false,
    isHeader = false,
}) => {
    const [openFields, setOpenFields] = useState<Record<number, boolean>>({}); // Track open/close state

    const toggleSubfields = (index: number) => {
        setOpenFields((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    return (
        <div className="sm:!px-0 py-3 md:!px-4">
            {/* Titre conditionnel */}
            <h3 className="text-base font-bold mb-2 flex items-center">
                <APICircleCheckSvg />
                {isWebhook
                    ? "Webhook Data"
                    : isHeader
                      ? "Header Parameters"
                      : "Body Parameters"}
            </h3>

            {/* Wrapper pour les champs */}
            <div className="grid grid-cols-1 md:grid-cols-2 ml-2.5 border-l dark:border-slate-200">
                {fields.map((field, index) => (
                    <div key={index} className="py-0">
                        <div className="flex items-start justify-between w-full sm:!pl-2 md:!pl-4 pt-1 pb-2 text-slate-800 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 border-l border-b dark:border-slate-200">
                            {/* Column 1: Field name and required status */}
                            <div className="mt-2 w-full md:!w-2/5">
                                {field.name && (
                                    <p className="text-sm font-bold text-slate-800 dark:text-slate-200">
                                        {field.name}
                                    </p>
                                )}
                                {field.required && (
                                    <p className="text-sm font-normal text-red-500">
                                        {field.required}
                                    </p>
                                )}
                                {/* Bouton "Détails" pour les sous-champs */}
                                {field.subfields &&
                                    field.subfields.length > 0 && (
                                        <button
                                            className="text-sm text-blue-500"
                                            onClick={() =>
                                                toggleSubfields(index)
                                            }
                                        >
                                            {openFields[index] ? (
                                                <>Hide Details ▼</>
                                            ) : (
                                                <>Show Details ►</>
                                            )}
                                        </button>
                                    )}
                            </div>

                            {/* Column 2: Field type, example, and description */}
                            <div className="mt-2 w-full md:!w-3/5">
                                {field.type && (
                                    <p className="text-sm text-slate-800 dark:text-slate-200">
                                        <strong>Type:</strong> {field.type}
                                    </p>
                                )}
                                {field.value && (
                                    <p className="text-sm text-slate-800 dark:text-slate-200">
                                        <strong>Value:</strong>{" "}
                                        <code>{field.value}</code>
                                    </p>
                                )}
                                {field.enum && (
                                    <p className="text-sm text-slate-800 dark:text-slate-200">
                                        <strong>Enum:</strong>{" "}
                                        <code>
                                            {Array.isArray(field.enum)
                                                ? field.enum.join(", ")
                                                : field.enum}
                                        </code>
                                    </p>
                                )}
                                {field.description && (
                                    <p className="text-sm text-slate-800 dark:text-slate-200">
                                        {field.description}
                                    </p>
                                )}
                            </div>
                        </div>

                        {/* Affichage des sous-champs */}
                        {field.subfields && openFields[index] && (
                            <div className="grid grid-cols-1 md:grid-cols-2  dark:border-slate-200">
                                {field.subfields.map((subfield, subIndex) => (
                                    <div key={subIndex} className="py-0">
                                        {/* Grille en 2 colonnes pour les sous-champs */}
                                        <div className="flex items-start justify-between w-full sm:!pl-2 md:!pl-12 pt-1 pb-2 text-slate-800 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 border-b dark:border-slate-200">
                                            {/* Colonne 1: Nom et Requis */}
                                            <div className="mt-2 w-full md:!w-2/5">
                                                <p className="text-sm font-bold text-slate-800 dark:text-slate-200">
                                                    {subfield.name}
                                                </p>
                                                {subfield.required && (
                                                    <p className="text-sm text-red-500">
                                                        {subfield.required}
                                                    </p>
                                                )}
                                            </div>

                                            {/* Colonne 2: Détails (Type, Value, etc.) */}
                                            <div className="mt-2 w-full md:!w-3/5">
                                                {subfield.type && (
                                                    <p className="text-sm text-slate-800 dark:text-slate-200">
                                                        <strong>Type:</strong>{" "}
                                                        {subfield.type}
                                                    </p>
                                                )}
                                                {subfield.value && (
                                                    <p className="text-sm text-slate-800 dark:text-slate-200">
                                                        <strong>Value:</strong>{" "}
                                                        <code>
                                                            {subfield.value}
                                                        </code>
                                                    </p>
                                                )}
                                                {subfield.description && (
                                                    <p className="text-sm text-slate-800 dark:text-slate-200">
                                                        {subfield.description}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BodySchema;
