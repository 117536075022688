import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";

const bg = "/dist/image/bg-top.webp";

const TermsLinkcyConsumer = () => {
    const { translate } = translator();
    return (
        <section className=" text-base text-[#333] font-medium break-words  ">
            <HelmetComponent
                title={translate("SEO", "Title_Terms")}
                description={translate("SEO", "Desc_Terms")}
                link="/terms-and-conditions"
                keywords={translate("SEO", "Keys_Terms")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <div
                className="  h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                style={{ backgroundImage: `url(${bg})` }}
            >
                <h1 className=" pt-10 md:!text-3xl text-2xl font-bold tracking-wide text-center ">
                    {translate("TERMS_PAGE", "MAIN_TITLE")}
                </h1>
            </div>
            <div className=" bg-white py-[100px] ">
                <div className=" w-[80%] max-w-[1080px] m-auto sm:text-base py-6 text-base   ">
                    <div className=" pb-10 mb-[36px]    ">
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            1. General provisions
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The present Agreement is concluded between LinkCy
                            and the Client.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-2">
                            2. Purpose :
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The purpose of these General Terms and Conditions of
                            Use is to define, on the one hand, the terms and
                            conditions under which LinkCy the LinkCy Services
                            available on the Application to its Users and, on
                            the other hand, the terms and conditions of use of
                            the Services.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            They are accessible and printable at any time, by
                            means of a link located on the Application, enabling
                            them to be displayed on a standalone web page.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            They may be supplemented, where applicable, by terms
                            of use specific to certain Services, which
                            supplement these General Terms and Conditions of Use
                            and, in the event of contradiction, prevail over the
                            latter.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            All terms and expressions appearing hereafter are
                            defined in article 3 of these General Terms and
                            Conditions of Use.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            3. Operator of the services:
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Services are operated by the Company LinkCy SAS
                            (hereafter “LinkCy”) with a capital of 13 089 €,
                            having its registered office at 42 Rue Boursault,
                            75017 Paris, France registered in the Trade and
                            Companies Register of Paris under the number
                            852295732.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy operates as an Agent for the provision of
                            Payment Services by Paynovate SA/NV (“PAYNOVATE”) is
                            a limited partnership incorporated since 11/12/2014
                            under Belgian law. Its registered office is located
                            at 47 Cantersteen 1000 Brussels, Belgium. PAYNOVATE
                            is registered with the Belgian Crossroad Bank for
                            Enterprises under number 0506.763.929. PAYNOVATE
                            qualifies as a Belgian electronic money institution
                            (“EMI”) under the Law of 11 March 2018 relating to
                            the status and control of payment institutions and
                            electronic money institutions, to the access to
                            payment service provider activity and electronic
                            money issuing activity and to the access to payment
                            system, It is supervised in this capacity by the
                            National Bank of Belgium (“NBB”) since 26/03/2019.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In its capacity as an Agent, LinkCy is authorized
                            and registered with the National Bank of Belgium
                            under number 852295732.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Thus, the Payment Services are provided and marketed
                            by LinkCy on behalf of and for Paynovate SA.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Payments Services are provided to the customers
                            of a LinkCy’s Partner, who become LinkCy customers.
                        </p>
                        <p className="text-base text-left font-normal">
                            LinkCy’s Partner customer service can be consulted :
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal pb-[5px]">
                                By email at the following address:
                                support@infinexia.com
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy customer service can be consulted by email at
                            the following address : support@linkcy.io
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            4. Definitions :
                        </p>
                        <ul className="list-disc pl-5 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                Agreement: means the Agreement between the
                                Client and LinkCy, which includes the present
                                Terms and conditions for provision of payment
                                Accounts for clients, and any other conditions
                                and documents (supplements, agreements, rules,
                                declarations, etc.).
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Agent: means a natural or legal person who acts
                                on behalf of a Payment Services Provider in
                                providing Payment Services. In this case, this
                                concept refers to LinkCy. Application: means the
                                free mobile or Web application provided by the
                                Partner, available or downloadable on the User's
                                Terminal enabling them to access the LinkCy
                                Services, once the User has subscribed to the
                                Service.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Account: means to the screen pages assigned to
                                the User from which he can access to the LinkCy
                                Services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Account Holder: means any natural or legal
                                person with a Payment Account.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Account Holder’s representative: means any
                                natural person holding powers to manage the
                                Payment Account in the name and on behalf of the
                                Account Holder
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Business Day: means a day when Paynovate
                                provides its services, set by Paynovate.
                                Paynovate can set different business days for
                                different services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Card: refers to a payment card issued by
                                Paynovate SA and associated with the Payment
                                Account.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Cardholder: means a natural person who has
                                received the card and is authorized to use the
                                card as provided for in the GTCU.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Client/Customer/User: means a legal person or a
                                natural person who has concluded the Agreement
                                on LinkCy services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Client’s Representative: means the executive
                                manager of the Client, or the Client’s other
                                representative, who is authorized to represent
                                the Client in cooperation with Paynovate under
                                legal acts and/or activity documents of the
                                legal person.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Client Identification: means verification of the
                                identity of the Client and/or their beneficial
                                owners under the procedure laid down in the
                                System.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Commission Fee: means a fee charged by Paynovate
                                for a Payment Transaction and/or related
                                services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Consent: means consent of the Payer to perform a
                                Payment Transaction submitted under the
                                procedure set forth by Article 8 of the
                                Agreement.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Electronic Money: means the Client’s money
                                charged or transferred to and held in a
                                Paynovate Account, designated for Payment
                                Transactions via the System.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Framework Contract: means a payment service
                                contract which governs the future execution of
                                individual and successive payment transactions
                                and which may contain the obligation and
                                conditions for setting up a payment account.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                LinkCy: refers to the Company “LinkCy SAS”
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Party: means LinkCy or the Client.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Partner: means a third-party company with its
                                own customer base and with which LinkCy has
                                entered into a partnership agreement for the
                                provision of the Services detailed in these
                                GTCU. The Partner hereby acts solely as a
                                business contributor and is responsible for
                                first level customer service, but does not
                                provide Payment Services under any
                                circumstances.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Password (Passwords): means any code created by
                                the Client in the system, a code created by the
                                Client and used for Strong Client
                                Authentication, or a code provided to the Client
                                for access to the Profile and/or the Paynovate
                                Account, or for the initiation, confirmation,
                                and management of separate services of
                                Paynovate, and/or initiation, authorization,
                                implementation, confirmation, and reception of a
                                Payment Transaction.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Transfer: means a payment transaction
                                wherein funds are transferred to a payment
                                account of the Recipient under the initiative of
                                the Payer.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Order: means an order (payment transfer)
                                from the Payer or the Recipient to the Provider
                                of Payment Services to execute a Payment
                                Transaction.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Transaction: means a money transfer or
                                cash-in/out operation initiated by the Payer, in
                                the Payer’s name, or by the Recipient.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Service: means services, during the
                                provision of which conditions to deposit to and
                                withdraw cash from the payment account are
                                created, as well as all transactions related to
                                the management of the payment account; payment
                                transactions, including transfer of money held
                                on the payment account opened in Paynovate of
                                the payment service provider of the User of
                                payment services, or in another payment
                                institution; payment transactions when money is
                                given to the User of payment services under a
                                credit line: payment transactions using a
                                payment card or a similar instrument and/or
                                credit transfers, including periodic transfers;
                                issuance and/or acceptance of payment
                                instruments; money remittances; payment
                                initiation services; account information
                                services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Instrument: means any payment instrument
                                which the System allows to link to the Paynovate
                                Account and use it to perform Payment Transfers.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payer: means a natural (private) or legal
                                person, or other organization or its branch,
                                that has a payment account and allows to execute
                                a payment order from this account, or, in the
                                absence of a payment account, submits a Payment
                                Order.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Paynovate: means Paynovate SA company and other
                                legal persons who may be hired for the purpose
                                of service provision. Paynovate is a Belgian
                                E-Money Institution under the prudential
                                Authority of the National Bank of Belgium,
                                incorporated under the laws of Belgium and
                                registered at the Belgian Crossroads Bank of
                                enterprises under the number 0506.763.929.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Paynovate Account or Account: means an account
                                opened in the System in the name of the Client
                                and used to make payments and other Payment
                                Transactions. An Account is opened only upon
                                identification of the Client. Paynovate
                                Application (also mentioned below as “System” or
                                “App”): means a mobile/software application for
                                Paynovate account management, installed on
                                mobile devices and used for the provision of
                                Paynovate services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Pin: means a four-digit personal identification
                                number for use with the Card.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Personal Data: means any information related to
                                the natural (private) person whose identity is
                                known or can be directly or indirectly
                                determined by using a personal code (national ID
                                number) and one or more physical, physiological,
                                psychological, economic, cultural, or social
                                features specific to the individual.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Pricing: means prices for Paynovate services and
                                transactions confirmed by Paynovate in
                                accordance with the established regulations.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Profile: means the result of registration in the
                                computer system, during which personal data of
                                the registered person is saved, a login name is
                                created, and their rights in the system are
                                defined.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Recipient: means a natural or legal person, or
                                another organization or its branch, specified in
                                the Payment Order as a recipient of the funds of
                                the Payment transaction.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                SEPA Mandate: means the specific form of Mandate
                                required to be used in order to effect Credit or
                                Direct Debits in SEPA.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Services: means the service of issuance and
                                redemption of electronic money and other
                                services provided by Paynovate included
                                providing IBAN accounts, the possibility of
                                making SEPA and direct debit transfers ; a
                                payment service provided by Paynovate, also any
                                other service provided by Paynovate.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Strong Client Authentication: means the
                                procedure of verification of the identity of a
                                natural or legal person based on the use of two
                                or more elements categorized as knowledge (e.g.
                                static password, code, personal identification
                                number), possession (e.g. token, smart card,
                                mobile phone) and inherence (e.g. biometric
                                characteristics, such as a fingerprint). This
                                procedure is applied when the Client is logging
                                in to their payment account online or through
                                other means of remote access, initiates an
                                electronic payment transaction and, through the
                                means of remote access, carries out any action
                                that might be related to the risk of fraud in
                                carrying out a payment or any other types of
                                misuse.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Terminal: means the terminals under iOs or
                                Android compatible with the use of the
                                Application.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Unique Identifier: means a combination of
                                letters, numbers, or symbols which Paynovate, as
                                a provider of payment services, provides to the
                                User of payments services, and which is used to
                                identify the User of payment services
                                participating in the Payment Transaction, and/or
                                the account of the User used in the Payment
                                Transaction.
                            </li>
                        </ul>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            5. Acceptance:
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            These GTCU must be accepted by any User wishing to
                            access the Services throughout the Application. The
                            mere use of the Services by the User implies full
                            and complete acceptance of these GTCU, who
                            henceforth undertakes to comply with them.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Nevertheless, when using the Services via the
                            registration form, each User expressly accepts these
                            GTCU by ticking the box preceding the following
                            text: "By ticking this box, I acknowledge that I
                            have read and understood the General Terms and
                            Conditions of Use and accept them". The fact of
                            ticking the box will be deemed to have the same
                            value as a handwritten signature on the part of the
                            User.
                        </p>
                        <p className="text-base text-left font-normal">
                            Thus, the Services are accessible:
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                To any natural person with full legal capacity
                                to engage under the present GTCU. A natural
                                person who does not have full legal capacity may
                                only access the Application and the Services
                                with the consent of his legal representative
                                (Account Holder Representative);
                            </li>
                            <li className="text-base text-left font-normal">
                                To any legal entity (company, association,
                                foundation) acting through a natural person
                                having the legal capacity to contract in the
                                name and on behalf of the legal entity (Account
                                Holder Representative).
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal pb-[10px]">
                            When the User subscribes to these GTCU for the needs
                            of third parties (including legal entities), he
                            certifies on his honor that he is authorized and has
                            all the necessary powers and authorizations to
                            subscribe to the Contract and authorize LinkCy to
                            provide him with the Services.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Consequently, the User fully guarantees LinkCy
                            against all the consequences of any claims from
                            third parties concerned (including legal entities)
                            relating to this authorization and/or in the event
                            of fraud by the User.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            6. Description of the Services
                        </p>
                        <p className="text-base text-left font-normal">
                            6.1 Payment Services
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Application allows the User to access some or
                            all of the following Payment Services (depending on
                            the services that the Partner has integrated).
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal">
                            <li className="text-base text-left font-normal">
                                The opening of a Payment Account;
                            </li>
                            <li className="text-base text-left font-normal">
                                Carrying out the following operations:
                            </li>
                        </ul>
                        <ul className="list-disc pl-20 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                Withdrawal
                            </li>
                            <li className="text-base text-left font-normal">
                                Transfers (in/out)
                            </li>
                            <li className="text-base text-left font-normal">
                                Direct debits (in / out)
                            </li>
                            <li className="text-base text-left font-normal">
                                Payment transactions through a Card;
                            </li>
                        </ul>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[20px]">
                            <li className="text-base text-left font-normal">
                                Issuing of a Card, associated with the Payment
                                Account;
                            </li>
                            <li className="text-base text-left font-normal">
                                Card acquiring
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal">
                            Payment Account
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Payment Account is opened by Paynovate SA in the
                            name of an Account Holder. To each Payment Account,
                            is attached a unique number (IBAN).
                        </p>
                        <p className="text-base text-left font-normal">
                            The account is a personal Payment Account in euros:
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                Euros may be credited and/or debited subject to
                                any limitations provided by LinkCy (see Annex
                                4).
                            </li>
                            <li className="text-base text-left font-normal">
                                Funds may be used by the User.
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal">
                            The balance of the Payment Account is the difference
                            between credit and debit transactions, and it must
                            always be in credit. Indeed, Payment Account does
                            not allow overdrafts. It is up to the User to ensure
                            that his Payment Account is sufficiently funded.
                            Thus, the User expressly acknowledges and accepts
                            that
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[20px]">
                            <li className="text-base text-left font-normal">
                                Any payment order having the effect of making
                                his Payment Account debtor will be automatically
                                blocked;
                            </li>
                            <li className="text-base text-left font-normal">
                                LinkCy reserves the right to block a User's
                                payment orders if it appears that his Payment
                                Account is not sufficiently funded for the price
                                of the Services to be paid.
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal">
                            Payment Transactions
                        </p>
                        <p className="text-base text-left font-normal">
                            From his Payment Account, the User can benefit from
                            the following Payment Services, subject to any
                            limitations provided by LinkCy (see Annex 4):
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[20px]">
                            <li className="text-base text-left font-normal">
                                the receipt of Payment Transactions by transfer
                                or direct debit and the acquisition of Payment
                                Orders by card credited to the Payment Account;
                            </li>
                            <li className="text-base text-left font-normal">
                                the execution of Payment Transactions by
                                transfer or direct debit and the execution of
                                Payment Orders by card debited from the Payment
                                Account;
                            </li>
                        </ul>

                        <p className="text-base text-left font-normal">Card</p>
                        <p className="text-base text-left font-normal">
                            The Card is a MASTERCARD Card, issued by Paynovate
                            SA.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Card allows its Cardholder, subject to any
                            limitations provided by LinkCy (see Annex 4):
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                To make cash withdrawals from ATMs displaying
                                one of the Payment Card Network's brands on the
                                Card;
                            </li>
                            <li className="text-base text-left font-normal">
                                To pay for purchases of goods or services
                                directly at merchants and service providers
                                equipped with a card acceptance point displaying
                                one of the Payment Card Network's brands on the
                                Card, or remotely.
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Card is a personal card, which cannot be lent by
                            its Cardholder. Its use requires the prior signature
                            of the Cardholder on the back and its activation via
                            the Application.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Card operates by means of a 4-digit PIN code
                            that will be transmitted to the Cardholder via the
                            Application and after the Card has been activated.
                            If the PIN code or cryptogram is entered incorrectly
                            three times, the Card is automatically deactivated.
                            It can be reactivated by contacting the customer
                            service department of LinkCy or its Partner.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Card has a validity period beyond which it will
                            become inactive. A new Card will be sent to the
                            Cardholder prior to its expiry.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            At any time, the Cardholder may block the Card by
                            means of the Application or by contacting the
                            customer service department of LinkCy’s Partner.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In the event of theft or loss of the Card, any
                            Cardholders is required to deactivate his Card via
                            the Application and to file an opposition as soon as
                            possible.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            6.2 Other Services
                        </p>
                        <p className="text-base text-left font-normal">
                            The Application also allows the User access to the
                            following others Services:
                        </p>

                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                Consulting the balance of the Payment Account;
                            </li>
                            <li className="text-base text-left font-normal">
                                Consulting the history of transactions carried
                                out through the Payment Account;
                            </li>
                            <li className="text-base text-left font-normal">
                                Registering beneficiary accounts;
                            </li>
                            <li className="text-base text-left font-normal">
                                Setting up notifications and alerts;
                            </li>
                            <li className="text-base text-left font-normal">
                                Management of the Card (and in particular, any
                                opposition to it);
                            </li>
                            <li className="text-base text-left font-normal">
                                Printing of the Bank Identity Account Statement;
                            </li>
                            <li className="text-base text-left font-normal">
                                Updating personal information;
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy reserves the right to offer any other service
                            it deems useful, in a form and according to the
                            functionalities and technical means that it deems
                            most appropriate for providing said services.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            7. Technical Compatiblity
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            To benefit from access to the Application and, if
                            subscribing to the Services, Users must have a
                            compatible Terminal connected to the Internet. It is
                            up to the User to check this compatibility.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            It is up to the User to carry out software updates
                            on his Terminal to ensure the proper functioning of
                            the Application and, if he subscribes to the latter,
                            of the Services.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            All costs necessary for access to the Application
                            and the Services (Terminal, software, Internet
                            connection, etc.) are the responsibility of the
                            User.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            No technical assistance is provided by LinkCy for
                            the User, whether by electronic or telephone means.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            8. Access to the Services
                        </p>
                        <p className="text-base text-left font-normal pb-[20px]">
                            To access the Services, the User must register under
                            the following conditions.
                        </p>
                        <p className="text-base text-left font-normal">
                            8.1 Access to the Application
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User may register by any means that Partner
                            makes available to him, in particular via the
                            Application downloaded from his Terminal (connection
                            costs due to download vary according to the mobile
                            offer) from one of the download platforms that
                            distributes it, this download being subject to the
                            conditions of use specific to each of these
                            platforms.
                        </p>
                        <p className="text-base text-left font-normal pb-[20px]">
                            LinkCy cannot under any circumstances be held
                            responsible for difficulties in downloading the
                            Application.
                        </p>
                        <p className="text-base text-left font-normal">
                            8.2 Access to the Services
                        </p>
                        <p className="text-base text-left font-normal">
                            To access to the Services, the User undertake to:
                        </p>

                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                Complete the information requested by the
                                Application;
                            </li>
                            <li className="text-base text-left font-normal">
                                Transmit all the information and documents
                                requested by LinkCy for the purpose of
                                validating and checking the identity of the
                                User;
                            </li>
                            <li className="text-base text-left font-normal">
                                Carry out the ultimate verification through
                                GetID, a SAAS software publisher, expert in
                                highly secure online identity verification in
                                order to avoid any attempt at fraud and/or
                                identity theft;
                            </li>
                            <li className="text-base text-left font-normal">
                                Expressly accept the General Terms and
                                Conditions (see article 4);
                            </li>
                            <li className="text-base text-left font-normal">
                                Sign any documents that may be requested, in
                                particular by Paynovate SA.
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal pb-[10px]">
                            At the end of the application process, the
                            application form will then be processed and analyzed
                            by LinkCy.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            If the application form is accepted and validated by
                            LinkCy, the Payment Account will then be created and
                            activated and the Card ordered and sent to the
                            address provided by the User.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Once the Payment Account has been created and
                            activated, the User will have the option to fund the
                            Payment Account by bank transfer or card payment.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In the event that the information requested when
                            creating the Payment Account is modified in whole or
                            in part, the User must update this information as
                            soon as possible via the Application or the website
                            Partner.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy reserves the right to request and additional
                            information deemed necessary to enhance customer
                            knowledge and to validate the registration file.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy and Paynovate SA/NV may accept or refuse to
                            open a Payment Account without any refusal diving
                            rise to compensation or motivation.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            9. Strictly personal use
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Only one Account can be created per User. LinkCy
                            reserves the right to delete any additional accounts
                            created for the same User.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User therefore agrees to use the Services
                            personally, and not to allow any third party to use
                            them in his place or for his own account, unless he
                            bears full responsibility for doing so.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User is responsible for maintaining the
                            confidentiality of his password and security code
                            and expressly acknowledges that any use of the
                            Services from his Account will be deemed to have
                            been carried out by himself.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            When the Account is opened by a legal entity, the
                            latter recognizes that its Account may be operated
                            by one or more authorized persons, using its login
                            details. In this case, the legal entity undertakes
                            to ensure that the latter respects the strictest
                            confidentiality of its login details.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            10. User obligations
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Without prejudice to the other obligations provided
                            for herein, Users undertake to comply with the
                            following obligations.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Users are solely responsible for their use of the
                            Services.
                        </p>
                        <p className="text-base text-left font-normal">
                            They undertake, in their use of the Services, to
                            comply with the laws and regulations in force and
                            not to infringe the rights of third parties or
                            public order. In particular, they undertake to
                            comply with the regulations applicable to the fight
                            against money laundering and terrorist financing. To
                            this end, they undertake to cooperate with LinkCy’s
                            Partner, LinkCy and, where applicable, with
                            Paynovate SA as part of the "Know Your Customer"
                            process.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            They also undertake not to damage the Application
                            and/or Services in any way whatsoever and not to use
                            it in a way that is not in accordance with its
                            purpose.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Users further undertake to fulfill their obligations
                            under the Paynovate SA Framework Contract.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Users acknowledge that in the Application they have
                            taken note of the characteristics and constraints,
                            in particular technical constraints, of all the
                            Services.
                        </p>
                        <p className="text-base text-left font-normal">
                            Within the framework of the Services, Users
                            undertake to provide Partner and LinkCy only with
                            information or data of any kind which is accurate,
                            up-to-date and sincere and that is not misleading or
                            likely to mislead. They undertake to update this
                            information so that it always corresponds to the
                            aforementioned criteria.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            They undertake to provide LinkCy with all the
                            information necessary for the proper performance of
                            the Services and, more generally, to cooperate
                            actively with LinkCy for the proper performance of
                            these GTCU.
                        </p>
                        <p className="text-base text-left font-normal">
                            Users guarantee to LinkCy that they have all the
                            rights and authorizations necessary for the
                            distribution of content of any kind (editorial,
                            graphic or other, including the name) within the
                            framework of the Services.
                        </p>
                        <p className="text-base text-left font-normal">
                            They thus undertake in particular to ensure that
                            such content:
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                are not infringing or detrimental to the image
                                of a third party;
                            </li>
                            <li className="text-base text-left font-normal">
                                are in no way misleading or likely to mislead
                                Users.
                            </li>
                        </ul>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            11. User Warranties
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Users guarantee LinkCy against any complaints,
                            claims, actions and/or demands whatsoever that
                            LinkCy may suffer as a result of Users breaching any
                            of their obligations or warranties under the terms
                            of these GTCU.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            They undertake to indemnify LinkCy for any prejudice
                            it may suffer and to pay LinkCy all costs, charges
                            and/or sentences it may have to bear as a result.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            12. Liabilities and warranties of LinkCy
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy undertakes to provide state of the art
                            Services, it being specified that it has an
                            obligation of means, to the exclusion of any
                            obligation of result, which the Users expressly
                            recognize and accept.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy cannot be held responsible for the
                            non-functioning, impossibility of access or
                            malfunctioning of the Application or the Services
                            due to unsuitable equipment, malfunctioning of the
                            services of the User's access provider or those of
                            the Internet network. The same shall apply for all
                            other reasons outside LinkCy.
                        </p>
                        <p className="text-base text-left font-normal">
                            Specifically, LinkCy holds no responsibility and
                            damages to you or others caused by the following
                            scenarios:
                        </p>
                        <p className="text-base text-left font-normal">
                            (a) your access or use of the Services inconsistent
                            with the Documentation;
                        </p>
                        <p className="text-base text-left font-normal">
                            (b) any unauthorized access of servers,
                            infrastructure, or Data used in connection with the
                            Services;
                        </p>
                        <p className="text-base text-left font-normal">
                            (c) interruptions to or cessation of the Services;
                        </p>
                        <p className="text-base text-left font-normal">
                            (d) any bugs, viruses, or other harmful code that
                            may be transmitted to or through the Services;
                        </p>
                        <p className="text-base text-left font-normal">
                            (e) any errors, inaccuracies, omissions, or losses
                            in or to any Data provided to us;
                        </p>
                        <p className="text-base text-left font-normal">
                            (f) third-party content provided by you; or
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            (g) the defamatory, offensive, or illegal conduct of
                            others.
                        </p>
                        <p className="text-base text-left font-normal">
                            In addition, the User acknowledges that the
                            characteristics and constraints of the Internet
                            network do not guarantee the availability and proper
                            functioning of the Application and Services.
                            Consequently, LinkCy does not guarantee that the
                            Application and the Services will function without
                            interruption, defects and/or errors.
                        </p>
                        <p className="text-base text-left font-normal">
                            As such, it is specified that:
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                The Application and the Services are accessible
                                24 hours a day, 7 days a week, except in cases
                                of force majeure or events beyond the control of
                                LinkCy;
                            </li>
                            <li className="text-base text-left font-normal">
                                LinkCy undertakes to carry out regular checks in
                                order to verify the functioning and
                                accessibility of the Services. Thus, access to
                                the Application and the Services may be
                                interrupted or suspended by LinkCy without prior
                                notice or justification for maintenance reasons
                                necessary for the proper functioning of the
                                Application and the Services and without this
                                giving any right to compensation by LinkCy.
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy undertakes to use its best efforts to ensure
                            the security of the User Account. It guarantees its
                            exclusivity and refrains from communicating its
                            content to any third party. It undertakes to inform
                            the Users of any foreseeable difficulty, in
                            particular with regard to the implementation of the
                            Services.
                        </p>
                        <p className="text-base text-left font-normal">
                            However, LinkCy cannot be held liable in the event
                            of malicious introduction on this Account, since it
                            is not demonstrated that the security measures it
                            has implemented were seriously defective.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In addition, LinkCy cannot be held responsible for
                            any lack of vigilance on the part of Users in
                            maintaining the confidentiality of their logins,
                            passwords and security code;
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy does not guarantee to the User that the
                            Services, being standard and in no way proposed
                            solely for the benefit of a given User according to
                            his own personal constraints, will specifically meet
                            his needs and expectations.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy undertakes to use the documents, elements,
                            data and information provided to it by the User only
                            for the purposes of carrying out the Services and
                            not to communicate them to any third party, except
                            at its Partner and except at the express request of
                            the User and/or if this communication is required by
                            the legal authorities or results from the legal
                            obligations incumbent on LinkCy, in particular in
                            its capacity as Agent of Payment Service Provider.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy undertakes to implement all necessary means
                            to preserve the confidentiality and security of the
                            data transmitted by the User.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy certifies that it holds an insurance policy
                            covering its professional civil liability. It
                            undertakes to keep this insurance policy in force
                            for the duration of the Services.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            13. Withdrawal
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User, a natural person acting for purposes that
                            do not fall within the scope of his professional
                            activity, has a withdrawal period of fourteen (14)
                            calendar days to exercise his right of withdrawal,
                            without having to justify his reasons or bear any
                            penalties, which runs from the day of his acceptance
                            of these GTCU.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User must notify his decision to withdrawal by
                            e-mail to the following address support@linkcy.io
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Any use of the withdrawal period by the User
                            automatically entails termination of these GTCU,
                            with immediate effect.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The start of performance does not deprive the User
                            of the right of withdrawal. Any request for
                            withdrawal must be accompanied by the communication
                            of a bank identity statement to enable LinkCy to
                            transfer the balance of the Payment Account, less
                            any advantage that the User would have benefited
                            from.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            14. Duration, Suspension and Termination
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The present GTCU are concluded for an indefinite
                            period of time as from their acceptance by the User.
                        </p>
                        <p className="text-base text-left font-normal">
                            14.1 Termination by Any User
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Any User has the right to terminate it and, more
                            generally, to end the Services upon simple request
                            made by email to the LinkCy’s Partner customer
                            service department at the following address :
                            contact@infinexia.com
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In this event, LinkCy will take note of the
                            termination within five (5) working days following
                            receipt of any email or letter to this effect.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The credit balance of the Payment Account will be
                            returned by LinkCy to the User, after a maximum
                            period of thirty (30) working days following receipt
                            of the email and/or letter, by bank transfer to the
                            bank account whose details will be communicated by
                            the User to LinkCy. In the absence of communication
                            of the details of the bank account receiving the
                            funds, LinkCy will keep the sums while they obtain
                            the said details.
                        </p>
                        <p className="text-base text-left font-normal pb-[16px]">
                            Closing of the Account will automatically and by
                            right, without any additional formality, result in
                            the Card being inactive.
                        </p>
                        <p className="text-base text-left font-normal">
                            14.2 Suspension or Termination by LinkCy
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy also has the right to suspend, delete or
                            prevent access to the User's Services with thirty
                            (30) days' notice, without reasons, without charge
                            and by simple notification by email.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            However, LinkCy is exempted from the notice period
                            and may immediately suspend, delete or prevent
                            access to the User’s Services in the event of
                            seriously reprehensible behavior by the User, in
                            particular, in the event of refusal by the User to
                            provide information, provision of false documents,
                            observe irregular operations on the Payment Account
                            (fraud, repeated non-payment, money laundering,
                            etc.) or non-compliance with these GTCU or in the
                            event of closure of the Account by Paynovate SA.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The choice between termination and suspension is at
                            LinkCy's discretion.
                        </p>
                        <p className="text-base text-left font-normal">
                            In the event of termination, LinkCy will invite the
                            User to transmit the details of another bank account
                            in order to transfer the credit balance of the
                            Payment Account, which will be returned to the User
                            at the end of a maximum period of thirty (30)
                            working days following receipt of the User's reply,
                            by bank transfer, after deduction of any fees
                            relating to the Services. The Payment Account will
                            then be closed, resulting in the deactivation of the
                            Services. The closure of the Account is irrevocable
                            and prevents the User from opening another Payment
                            Account at least for a period of 1 year or
                            definitively in the event of violation of these GTCU
                            or in the event of legally reprehensible acts.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The amount of the fees invoiced by LinkCy for the
                            termination, in the context of improper use, is
                            indicated in Annex 4.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In the event of suspension, the Account may be
                            reactivated at the request of the User within a
                            period of fifteen (15) days following the
                            suspension, provided that the cause behind the
                            suspension has been lifted.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Any death of a User automatically entails the
                            immediate suspension of the Card and the Payment
                            Account as soon as LinkCy is informed of it, and its
                            closure within thirty (30) days following the
                            transfer of the balance to the notary in charge of
                            the succession.
                        </p>
                        <p className="text-base text-left font-normal">
                            In the event of breach of any of the provisions of
                            these GTCU, or more generally, of violation of laws
                            and regulations by Users, LinkCy also reserves the
                            right to:
                        </p>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                delete any content related to the breach or
                                infringement in question, in whole or in part;
                            </li>
                            <li className="text-base text-left font-normal">
                                take all appropriate measures and take any legal
                                action;
                            </li>
                            <li className="text-base text-left font-normal">
                                if necessary, notify the competent authorities,
                                cooperate with them and provide them with all
                                relevant information for the investigation and
                                suppression of illegal or unlawful activities.
                            </li>
                        </ul>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            15. Intellectual property
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            All intellectual property rights belonging to LinkCy
                            or Paynovate SA remain their exclusive property.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Indeed, the systems, software, structures,
                            infrastructures, databases and content of any kind
                            (texts, images, visuals, music, logos, brands, in
                            particular) used by LinkCy within the Application
                            are original works and constitute protected
                            intellectual property rights which are and remain
                            the full and exclusive property of LinkCy.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Furthermore, these GCTU do not confer on the User
                            any property right over the Services, the
                            Application and its content.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Any disassembly, decompilation, decryption,
                            extraction, reuse, copy and more generally, any act
                            of reproduction, representation, distribution and
                            use of any of these elements, in whole or in part,
                            without the authorization of LinkCy is strictly
                            prohibited and may be subject to legal proceedings.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            16. Personal data
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The processing of personal data complies with
                            Regulation (EU) 2016/679 of 27 April 2016 (the
                            "General Data Protection Regulation" or "GDPR").
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy undertakes to process all of the Personal
                            Data of any User to which it has access in the
                            context of the execution hereof in accordance with
                            the Regulation cited above.
                        </p>
                        <p className="text-base text-left font-normal">
                            In this context, LinkCy undertakes to:
                        </p>

                        <ul className="list-disc pl-10 text-base text-left font-normal">
                            <li className="text-base text-left font-normal">
                                process the Personal Data solely for the
                                purposes of the execution of these GTCU, to the
                                exclusion of any other purpose;
                            </li>
                            <li className="text-base text-left font-normal">
                                implement the appropriate technical and
                                organizational measures to protect the Data in a
                                permanent and documented manner, against
                                accidental or illegal destruction, accidental
                                loss, alteration, dissemination or unauthorized
                                access, and to ensure its security, integrity
                                and confidentiality;
                            </li>
                            <li className="text-base text-left font-normal">
                                ensure that the persons authorized to process
                                Personal Data under these GTCU, in particular,
                                its employees:
                            </li>
                        </ul>
                        <ul className="list-disc pl-20 text-base text-left font-normal">
                            <li className="text-base text-left font-normal">
                                undertake to respect confidentiality or are
                                subject to an appropriate obligation of
                                confidentiality; and
                            </li>
                            <li className="text-base text-left font-normal">
                                receive the necessary training in the protection
                                of Personal Data.
                            </li>
                        </ul>
                        <ul className="list-disc pl-10 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                not communicate the Personal Data to any third
                                party whatsoever, except for third parties to
                                whom it would be strictly necessary to transmit
                                such data for the purposes of the execution of
                                these GTCU;
                            </li>
                            <li className="text-base text-left font-normal">
                                take into account, with regard to its tools,
                                products, applications or services, the
                                principles of data protection by design sand of
                                data protection by default;
                            </li>
                            <li className="text-base text-left font-normal">
                                inform the User, on simple request, of the
                                location of the places where Personal Data of
                                any kind are processed (hosting, backup,
                                maintenance, administration, helpdesk, etc.);
                            </li>
                            <li className="text-base text-left font-normal">
                                inform any User, within a maximum period of 24
                                hours, of any violation of his Personal Data.
                            </li>
                        </ul>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Any User is informed by LinkCy that he has, with
                            regard to Personal Data, the rights provided for by
                            the legislation, in particular, the right to access,
                            rectify, delete, oppose, limit processing, the right
                            to the portability of Personal Data and the right
                            not to be subject to an individual automated
                            decision by sending a written communication to the
                            following e-mail address: [...] specifying the
                            reference “Personal Data / Exercise of Rights". The
                            applicant must also provide proof of identity by
                            attaching a copy of any national identity document
                            or an equivalent document (passport).
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The conditions for the implementation of these
                            processing operations are described in the Personal
                            Data Protection Policy (see Annex 5).
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            17. Confidentiality information
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In its capacity as Agent of a Payment Service
                            Provider, LinkCy is bound by professional secrecy
                            and undertakes not to disclose any banking
                            information belonging to the User that it may
                            receive in the context of the execution of these
                            GTCU.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User expressly authorizes Paynovate SA to
                            transmit to LinkCy any information on the Payment
                            Account and the Card, without any limitation, and
                            notwithstanding the legal provisions on banking
                            secrecy that the User expressly waives with regard
                            to LinkCy and its agents (legal representatives and
                            employees), and this for the sole purpose of
                            executing these GTCU.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Notwithstanding the first paragraph, LinkCy is
                            authorized to transmit any information to (i) any
                            service provider and subcontractors contractually
                            linked to it, for the purposes of the proper
                            execution of the GTCU and/or the provision of the
                            Services or any new service that it would develops,
                            as well as (ii) any administrative or judicial
                            authority having a right of access to this
                            information in application of the applicable legal
                            and regulatory provisions.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User also authorizes LinkCy to analyze any
                            information obtained via the Application and/or the
                            Services, in an anonymous manner, for the purposes
                            of developing new services.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            18. Force majeure
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The responsibility of LinkCy and the User in the
                            execution of its obligations arising from these GTCU
                            cannot be engaged in case of force majeure
                            (external, unforeseeable and irresistible event).
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In the event that LinkCy would consider invoking a
                            case of force majeure, it must inform any User in
                            advance, in writing, at the latest within five (5)
                            calendar days following the occurrence of the
                            invoked event.
                        </p>
                        <p className="text-base text-left font-normal">
                            In any event, in the event of a case of force
                            majeure, each party shall do everything in its power
                            to minimize the effects of the said case of force
                            majeure.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Neither party will be liable for any delays in
                            processing or other nonperformance caused by
                            telecommunications, utility, failures, or equipment
                            failures, labor strife, riots, war or terrorists
                            attacks, pandemic context, nonperformance of our
                            vendors or suppliers, fires or acts of nature, or
                            any other event over which the respective party has
                            no reasonable control.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            However, nothing in this section will affect or
                            excuse your liabilities or your obligation to pay
                            fees, fines, disputes, refunds, reversals or returns
                            under this agreement.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            19. Non-transferability
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            These GTCU cannot be transferred in whole or in part
                            by the User. The User may be held liable in the
                            event of failure to comply with this provision and
                            LinkCy may terminate the GTCU without delay.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            20. Modification - evolution - update
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy reserves the right to modify these GTCU at
                            any time and without notice in order to adapt them
                            to changes in the Application and/or its operation
                            and the Services.
                        </p>
                        <p className="text-base text-left font-normal">
                            Users will be informed of these modifications by any
                            useful means.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Users who do not accept the amended GTCU must
                            unsubscribe from the Services in accordance with the
                            terms set out in Article 15.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Any User who uses the Services after the amended
                            GTCU comes into force is deemed to have accepted
                            these modifications.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            21. Independence of clauses
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The nullity, illegality or inapplicability of any
                            clause of these GTCU shall not entail the nullity,
                            illegality or inapplicability of the other clauses.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In the event of the nullity, illegality or
                            inapplicability of a clause, LinkCy will replace it
                            with new valid, lawful or applicable stipulations
                            which will have an effect as close as possible to
                            that of the null, unlawful or inapplicable clause.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Users may not claim damages due to such nullity,
                            illegality or unenforceability of any clause hereof.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            22. Communication
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The language used for drafting contractual documents
                            and subsequent communications between the Parties
                            shall be English.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            In the event of a translation of these GTCU into one
                            or more languages, the language of interpretation
                            shall be English in the event of any contradiction
                            or dispute as to the meaning of a term or provision.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User ensures that the e-mail address entered in
                            his Account is correct and that it is indeed his own
                            e-mail address, insofar as correspondence and
                            content provided under the Services will be
                            accessible through this email address.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The User consequently releases LinkCy from all
                            liability and guarantees LinkCy from all
                            consequences resulting directly or indirectly from
                            an error in the email address.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            23. Contacts
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            For any question, difficulty or complaint related to
                            the Use of the Services, the User may contact the
                            LinkCy’s Partner customer service department as a
                            priority at the following email address:
                            support@infinexia.com
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy can also be contacted at the following email
                            address: support@linkcy.io
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            24. Mediation
                        </p>
                        <p className="text-base text-left font-normal">
                            In the event of failure to make a claim to LinkCy or
                            its Partner or in the absence of a response within
                            two months, the User may submit the dispute relating
                            to these GTCU to the European Commission’s online
                            dispute resolution (“ODR”) who will attempt, with
                            complete independence and impartiality, to bring the
                            parties together with a view to reaching an amicable
                            solution. The ODR platform can be found at:
                        </p>
                        <p className="text-base text-left font-normal pb-[10px] text-blue-600 underline">
                            <a href="https://ec.europa.eu/consumers/odr/main/?event=main.adr.show">
                                https://ec.europa.eu/consumers/odr/main/?event=main.adr.show
                            </a>
                            .
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Parties to the contract remain free to accept or
                            refuse recourse to mediation and, in the event of
                            recourse to mediation, to accept or refuse the
                            solution proposed by the mediator.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            25. Applicable law and competent jurisdiction
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            These GTCU are governed and interpreted in
                            accordance with French law, without regard to the
                            principles of conflict of laws.
                        </p>
                        <p className="text-base text-left font-normal">
                            In the event of any dispute regarding the validity,
                            interpretation and/or execution of these GTCU, and
                            after the failure of any attempt to find an amicable
                            solution, express jurisdiction is assigned to the
                            competent court of Paris.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Any dispute arising out of or in connection with the
                            Agreement which shall not be amicably settled by the
                            Parties through good faith negotiation within three
                            (3) months after notification in writing by any of
                            the parties shall belong to the exclusive
                            jurisdiction of the Courts of Paris (France), even
                            in case of side claim or counterclaim.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            26. Entire agreement
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            This Agreement and all policies and procedures that
                            are incorporated by reference constitute the entire
                            agreement between you and LinkCy for provision and
                            use of the Services. Except where expressly stated
                            otherwise in a writing executed between you and
                            LinkCy, this Agreement will prevail over any
                            conflicting policy or agreement for the provision or
                            use of the Services. This Agreement sets forth your
                            exclusive remedies with respect to the Services. If
                            any provision or portion of this Agreement is held
                            to be invalid or unenforceable under Law, then it
                            will be reformed and interpreted to accomplish the
                            objectives of such provision to the greatest extent
                            possible, and all remaining provisions will continue
                            in full force and effect.
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            27. Amendments:
                        </p>
                        <p className="text-base text-left font-normal">
                            The contractual relationship between you and LinkCy
                            shall commence immediately after you have been
                            successfully registered as a Customer.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            LinkCy is entitled to unilaterally amend the
                            provisions of these General Terms and Conditions.
                            The Client shall be informed of any changes to the
                            General Terms and Conditions by means of a
                            notification on the App and a copy of the new
                            General Terms and Conditions shall be sent to the
                            Client on a durable medium, at his request.
                        </p>
                        <p className="text-base text-left font-normal">
                            Unless otherwise agreed, any changes to these
                            General Terms and Conditions or other agreements
                            shall take effect at the earliest within two (2)
                            weeks from the date of their notification to the
                            Customer.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The Client shall be deemed to have accepted
                            amendments and additions to the Agreement unless it
                            notifies LinkCy that it does not accept them before
                            the expiry of the notice period, which shall result
                            in the automatic termination of the Agreement.
                            Immediate or no notice shall be given in case LinkCy
                            amends in any way or adds any provision to the
                            Agreement where the amendment or addition is
                            required in the event of any changes imposed on
                            LinkCy by any Competent Authority or any applicable
                            law.
                        </p>

                        <p className="text-base text-center font-normal mt-[50px] pb-[10px]">
                            Annex 1 : Framework Annex for provisions of payment
                            accounts
                        </p>
                        <p className="text-base text-center font-bold pb-[10px]">
                            PAYNOVATE TERMS
                        </p>
                        <p className="text-sm text-center font-bold pb-[10px]">
                            SA “PAYNOVATE”
                        </p>
                        <p className="text-sm text-center font-bold">
                            TERMS AND CONDITIONS
                        </p>
                        <p className="text-sm text-center font-bold pb-[5px]">
                            ELECTRONIC MONEY ACCOUNTS
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            1. General provisions
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The present Agreement is concluded between Paynovate
                            and the Client.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            1.1 Object of the Agreement:
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            The present Agreement determines the main terms and
                            conditions between the Client and Paynovate when the
                            Client opens an Account at Paynovate by registering
                            in its System and uses other services provided by
                            Paynovate. Conditions of separate services provided
                            by Paynovate are set under the Supplements to the
                            Agreement, other agreements and rules which are an
                            integral part of the present Agreement.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            These conditions apply to the Client after the
                            Client becomes acquainted with the terms of the
                            agreement and starts using the respective services.
                            In addition to the present Agreement, the
                            relationship between Paynovate and the Client
                            related to provision of Services is also governed by
                            legal acts applicable to the Client, agreements
                            concluded with the Client, other agreements, rules
                            and principles of reasonableness, justice, and
                            fairness.
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            Paynovate shall keep under secrecy any data that
                            have become known to it from its relations with the
                            Client, included data about the Client, their
                            Account, and payment transactions (hereinafter the
                            Client’s data subject to banking secrecy) for an
                            unspecified term. Paynovate may disclose the
                            Client’s data subject to banking secrecy to a third
                            party if it arises from the legislation or from the
                            general terms and conditions of Paynovate.
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            1.2 Definitions:
                        </p>
                        <ul className="list-disc pl-5 text-base text-left font-normal pb-[10px]">
                            <li className="text-base text-left font-normal">
                                Agreement: means the Agreement between the
                                Client and Paynovate, which includes the present
                                Terms and conditions for provision of payment
                                Accounts for clients, and any other conditions
                                and documents (supplements, agreements, rules,
                                declarations, etc.).
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Business Day: means a day when Paynovate
                                provides its services, set by Paynovate.
                                Paynovate can set different business days for
                                different services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Client / Customer: means a legal person or a
                                natural person who has concluded the Agreement
                                on Paynovate services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Client’s Representative: means the executive
                                manager of the Client, or the Client’s other
                                representative, who is authorized to represent
                                the Client in cooperation with Paynovate under
                                legal acts and/or activity documents of the
                                legal person.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Client Identification: means verification of the
                                identity of the Client and/or their beneficial
                                owners under the procedure laid down in the
                                System.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Commission Fee: means a fee charged by Paynovate
                                for a Payment Transaction and/or related
                                services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Consent: means consent of the Payer to perform a
                                Payment Transaction submitted under the
                                procedure set forth by Article 8 of the
                                Agreement.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Electronic Money: means the Client’s money
                                charged or transferred to and held in a
                                Paynovate Account, designated for Payment
                                Transactions via the System.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Party: means Paynovate or the Client.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Password (Passwords): means any code created by
                                the Client in the system, a code created by the
                                Client and used for Strong Client
                                Authentication, or a code provided to the Client
                                for access to the Profile and/or the Paynovate
                                Account, or for the initiation, confirmation,
                                and management of separate services of
                                Paynovate, and/or initiation, authorization,
                                implementation, confirmation, and reception of a
                                Payment Transaction.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Transfer: means a payment transaction
                                wherein funds are transferred to a payment
                                account of the Recipient under the initiative of
                                the Payer.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Order: means an order (payment transfer)
                                from the Payer or the Recipient to the Provider
                                of Payment Services to execute a Payment
                                Transaction.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Transaction: means a money transfer or
                                cash-in/out operation initiated by the Payer, in
                                the Payer’s name, or by the Recipient.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Service: means services, during the
                                provision of which conditions to deposit to and
                                withdraw cash from the payment account are
                                created, as well as all transactions related to
                                the management of the payment account; payment
                                transactions, including transfer of money held
                                on the payment account opened in Paynovate of
                                the payment service provider of the User of
                                payment services, or in another payment
                                institution; payment transactions when money is
                                given to the User of payment services under a
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Credit line: payment transactions using a
                                payment card or a similar instrument and/or
                                credit transfers, including periodic transfers;
                                issuance and/or acceptance of payment
                                instruments; money remittances; payment
                                initiation services; account information
                                services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payment Instrument: means any payment instrument
                                which the System allows to link to the Paynovate
                                Account and use it to perform Payment Transfers.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Payer: means a natural (private) or legal
                                person, or other organization or its branch,
                                that has a payment account and allows to execute
                                a payment order from this account, or, in the
                                absence of a payment account, submits a Payment
                                Order.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Paynovate: means Paynovate company and other
                                legal persons who may be hired for the purpose
                                of service provision. Paynovate is a Belgian
                                E-Money Institution under the prudential
                                Authority of the National Bank of Belgium,
                                incorporated under the laws of Belgium and
                                registered at the Belgian Crossroads Bank of
                                enterprises under the number 0506.763.929.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Paynovate Account or Account: means an account
                                opened in the System in the name of the Client
                                and used to make payments and other Payment
                                Transactions. An Account is opened only upon
                                identification of the Client.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Paynovate Application (also mentioned below as
                                “System” or “App”): means a mobile/software
                                application for Paynovate account management,
                                installed on mobile devices and used for the
                                provision of Paynovate services.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Personal Data: means any information related to
                                the natural (private) person whose identity is
                                known or can be directly or indirectly
                                determined by using a personal code (national ID
                                number) and one or more physical, physiological,
                                psychological, economic, cultural, or social
                                features specific to the individual.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Pricing: means prices for Paynovate services and
                                transactions confirmed by Paynovate in
                                accordance with the established regulations.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Profile: means the result of registration in the
                                computer system, during which personal data of
                                the registered person is saved, a login name is
                                created, and their rights in the system are
                                defined.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Recipient: means a natural or legal person, or
                                another organization or its branch, specified in
                                the Payment Order as a recipient of the funds of
                                the Payment transaction.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                SEPA Mandate: means the specific form of Mandate
                                required to be used in order to effect Credit or
                                Direct Debits in SEPA.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Services: means the service of issuance and
                                redemption of electronic money and other
                                services provided by Paynovate included
                                providing IBAN accounts, the possibility of
                                making SEPA and direct debit transfers; a
                                payment service provided by Paynovate, also any
                                other service provided by Paynovate.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Strong Client Authentication: means the
                                procedure of verification of the identity of a
                                natural or legal person based on the use of two
                                or more elements categorized as knowledge (e.g.
                                static password, code, personal identification
                                number), possession (e.g. token, smart card,
                                mobile phone) and inherence (e.g. biometric
                                characteristics, such as a fingerprint). This
                                procedure is applied when the Client is logging
                                in to their payment account online or through
                                other means of remote access, initiates an
                                electronic payment transaction and, through the
                                means of remote access, carries out any action
                                that might be related to the risk of fraud in
                                carrying out a payment or any other types of
                                misuse.
                            </li>
                            <li className="text-base text-left font-normal pb-[10px]">
                                Unique Identifier: means a combination of
                                letters, numbers, or symbols which Paynovate, as
                                a provider of payment services, provides to the
                                User of payments services, and which is used to
                                identify the User of payment services
                                participating in the Payment Transaction, and/or
                                the account of the User used in the Payment
                                Transaction.
                            </li>
                        </ul>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            1.3 Registration in the System:
                        </p>

                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            Title
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGR")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            <a href="https://infinexia.com/privacy-policy/">
                                {translate("TERMS_PAGE", "")}
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsLinkcyConsumer;
