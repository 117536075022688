import React, { useState } from "react";

interface SidebarLinkSubgroupProps {
    children: React.ReactNode; // Contenu du sous-groupe (liens, éléments de navigation, etc.)
    title: string; // Titre du sous-groupe
    open: boolean; // Indique si le sous-groupe est initialement ouvert
}

export default function SidebarLinkSubgroup({
    children,
    title,
    open,
}: SidebarLinkSubgroupProps) {
    // État pour gérer si le sous-groupe est ouvert ou fermé
    const [linkOpen, setLinkOpen] = useState<boolean>(open);

    return (
        <li className="mt-3">
            {/* Titre du sous-groupe, clic pour ouvrir/fermer */}
            <a
                className="flex items-center space-x-3 text-slate-800 font-bold dark:text-slate-200"
                href="#0"
                onClick={(e) => {
                    e.preventDefault(); // Empêche la navigation par défaut
                    setLinkOpen(!linkOpen); // Alterne l'état ouvert/fermé
                }}
                aria-expanded={linkOpen} // Accessibilité : indique si le groupe est ouvert
            >
                {/* Icône pour indiquer l'état (ouverte ou fermée) */}
                <svg
                    className={`fill-slate-400 shrink-0 ml-2 ${
                        linkOpen && "rotate-90"
                    }`} // Rotation pour indiquer visuellement l'état
                    width="8"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z" />
                </svg>
                <span>{title}</span> {/* Titre du sous-groupe */}
            </a>
            {/* Liste des éléments du sous-groupe */}
            <ul
                className={`mb-3 ml-1 pl-4 border-l border-slate-200 dark:border-slate-200 pt-2 space-y-2 ${
                    !linkOpen && "hidden" // Cache les éléments si le groupe est fermé
                }`}
            >
                {children}
            </ul>
        </li>
    );
}
