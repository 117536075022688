import React, { useState } from "react";
import ChevronCloseSvg from "../../Components/Extra/Svg/ChevronCloseSvg";
import ChevronOpenSvg from "../../Components/Extra/Svg/ChevronOpenSvg";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";

type CodeSamplesRequest = Record<string, string>; // Définition manquante ajoutée

interface SamplesRequestProps {
    titleBox: string; // Nom de la propriété modifié
    codeSamples: CodeSamplesRequest; // Ensemble des exemples de code
}

const SamplesRequest: React.FC<SamplesRequestProps> = ({
    titleBox,
    codeSamples,
}) => {
    const [activeTab, setActiveTab] = useState<keyof typeof codeSamples>(
        Object.keys(codeSamples)[0] as keyof typeof codeSamples, // Par défaut, sélectionner le premier
    );

    const languages = Object.keys(codeSamples) as string[]; // Contraindre les clés à être des chaînes

    type SectionKeys =
        | "samples"
        | "RequestHeader"
        | "initialize-endpoint"
        | "initialize-request"
        | "initialize-responses"
        | "initialize-200"
        | "initialize-401"
        | "initialize-422"
        | "initialize-403"
        | "check-endpoint"
        | "check-request"
        | "check-responses"
        | "check-200"
        | "check-422"
        | "get-fees-endpoint"
        | "get-fees"
        | "get-fees-responses"
        | "get-fees-200"
        | "get-fees-401";

    const [openSections, setOpenSections] = useState<
        Record<SectionKeys, boolean>
    >({
        samples: true,
        RequestHeader: true,
        "initialize-endpoint": true,
        "initialize-request": true,
        "initialize-responses": true,
        "initialize-200": false,
        "initialize-401": false,
        "initialize-422": false,
        "initialize-403": false,
        "check-endpoint": true,
        "check-request": true,
        "check-responses": true,
        "check-200": false,
        "check-422": false,
        "get-fees-endpoint": true,
        "get-fees": true,
        "get-fees-responses": true,
        "get-fees-200": false,
        "get-fees-401": false,
    });

    const toggleSection = (section: SectionKeys) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    return (
        <div className="w-full bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
            <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleSection("samples")}
            >
                <h3>{titleBox}</h3> {/* Titre affiché */}
                {openSections["samples"] ? (
                    <ChevronCloseSvg />
                ) : (
                    <ChevronOpenSvg />
                )}
            </div>
            {openSections["samples"] && (
                <div className="mt-3">
                    <div className="mt-3 flex space-x-1 border-b border-slate-700 pb-2 mb-4 overflow-x-auto">
                        {languages.map((lang) => (
                            <button
                                key={lang}
                                onClick={() =>
                                    setActiveTab(
                                        lang as keyof typeof codeSamples,
                                    )
                                }
                                className={`text-xs px-4 py-3 rounded-t-lg ${
                                    activeTab === lang
                                        ? "bg-slate-700 text-white font-bold"
                                        : "text-slate-400"
                                }`}
                            >
                                {lang.toUpperCase()}
                            </button>
                        ))}
                    </div>
                    {/* Code Sample */}
                    <div className="relative">
                        <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-900 py-5 px-4 rounded h-auto">
                            <code>{codeSamples[activeTab]}</code>
                        </pre>
                        {/* Intégration de KpCopyClick */}
                        <div className="absolute top-2 right-2 text-sm text-teal-500">
                            <KpCopyClick
                                text={codeSamples[activeTab] || ""}
                                message=""
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SamplesRequest;
