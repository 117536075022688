import React, { useState } from "react";
import ChevronCloseSvg from "../../Components/Extra/Svg/ChevronCloseSvg";
import ChevronOpenSvg from "../../Components/Extra/Svg/ChevronOpenSvg";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";

type CodeSamplesResponse = Record<string, string>; // Définition manquante ajoutée

interface SamplesResponsesProps {
    titleBox: string; // Nom de la propriété modifié
    codeSamples: CodeSamplesResponse; // Ensemble des exemples de code
}

const SamplesResponses: React.FC<SamplesResponsesProps> = ({
    titleBox,
    codeSamples,
}) => {
    const [activeTab, setActiveTab] = useState<keyof typeof codeSamples>(
        Object.keys(codeSamples)[0] as keyof typeof codeSamples, // Par défaut, sélectionner le premier
    );

    const languages = Object.keys(codeSamples) as string[]; // Contraindre les clés à être des chaînes

    type SectionKeys =
        | "samples"
        | "initialize-responses"
        | "initialize-200"
        | "initialize-401"
        | "initialize-422"
        | "initialize-403"
        | "check-responses"
        | "check-200"
        | "check-422"
        | "get-fees-responses"
        | "get-fees-200"
        | "get-fees-401"
        | "webhook-responses";

    const [openSections, setOpenSections] = useState<
        Record<SectionKeys, boolean>
    >({
        samples: true,
        "initialize-responses": true,
        "initialize-200": true,
        "initialize-401": true,
        "initialize-422": true,
        "initialize-403": true,
        "check-responses": true,
        "check-200": true,
        "check-422": true,
        "get-fees-responses": true,
        "get-fees-200": true,
        "get-fees-401": true,
        "webhook-responses": true,
    });

    const toggleSection = (section: SectionKeys) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const isGreenTab = (lang: string) => {
        // Vérifie si la langue est l'une des clés liées aux réponses 200
        return /(200)$/i.test(lang); // Correspond à "200" à la fin de la clé (insensible à la casse)
    };

    return (
        <div className="w-full bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
            <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleSection("samples")}
            >
                <h3>{titleBox}</h3> {/* Titre affiché */}
                {openSections["samples"] ? (
                    <ChevronCloseSvg />
                ) : (
                    <ChevronOpenSvg />
                )}
            </div>
            {openSections["samples"] && (
                <div className="mt-3">
                    <div className="mt-3 flex space-x-6 border-b border-slate-700 pb-2 mb-4 text-base overflow-x-auto">
                        {languages.map((lang) => {
                            // Log pour déboguer les clés
                            console.log("Lang:", lang);

                            const isActive = activeTab === lang;

                            return (
                                <button
                                    key={lang}
                                    onClick={() =>
                                        setActiveTab(
                                            lang as keyof typeof codeSamples,
                                        )
                                    }
                                    className={`text-sm px-6 py-3 rounded-t-lg font-bold ${
                                        isGreenTab(lang)
                                            ? isActive
                                                ? "bg-slate-700 text-green-500"
                                                : "text-green-500"
                                            : isActive
                                              ? "bg-slate-700 text-red-600"
                                              : "text-red-600"
                                    }`}
                                >
                                    {lang.toUpperCase()}
                                </button>
                            );
                        })}
                    </div>
                    {/* Code Sample */}
                    <div className="relative">
                        <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-900 py-5 px-4 rounded h-auto">
                            <code>{codeSamples[activeTab]}</code>
                        </pre>
                        {/* Intégration de KpCopyClick */}
                        <div className="absolute top-2 right-2 text-sm text-teal-500">
                            <KpCopyClick
                                text={codeSamples[activeTab] || ""}
                                message=""
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SamplesResponses;
