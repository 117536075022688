export const RequestHeader = {
    curl: `curl -X POST https://api.infinexia.com/payment \\
    -H "Content-Type: application/json" \\
    -H "App-id: YOUR_APP_ID" \\
    -H "App-key: YOUR_APP_KEY"`,

    javascript: `fetch("https://api.infinexia.com/payment", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        "App-id": "YOUR_APP_ID",
        "App-key": "YOUR_APP_KEY",
    },
})
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error));
`,

    python: `import requests

headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
}

response = requests.post("https://api.infinexia.com/payment", headers=headers)
print(response.text)
`,

    java: `import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;

public class Main {
    public static void main(String[] args) throws Exception {
        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
            .uri(URI.create("https://api.infinexia.com/payment"))
            .header("Content-Type", "application/json")
            .header("App-id", "YOUR_APP_ID")
            .header("App-key", "YOUR_APP_KEY")
            .POST(HttpRequest.BodyPublishers.noBody()) // No body is sent in this request
            .build();

        HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
        System.out.println(response.body());
    }
}
`,

    php: `<?php

$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://api.infinexia.com/payment");
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    "Content-Type: application/json",
    "App-id: YOUR_APP_ID",
    "App-key: YOUR_APP_KEY",
]);

$response = curl_exec($ch);
curl_close($ch);

echo $response;
?>
`,

    nodejs: `const axios = require("axios");

const headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
};

axios.post("https://api.infinexia.com/payment", {}, { headers })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));
`,

    csharp: `using System;
using System.Net.Http;
using System.Threading.Tasks;

class Program {
    static async Task Main() {
        using HttpClient client = new HttpClient();
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
        client.DefaultRequestHeaders.Add("App-id", "YOUR_APP_ID");
        client.DefaultRequestHeaders.Add("App-key", "YOUR_APP_KEY");

        var response = await client.PostAsync("https://api.infinexia.com/payment", null);
        Console.WriteLine(await response.Content.ReadAsStringAsync());
    }
}
`,
};

export const InitializePayment = {
    curl: `curl -X POST https://api.infinexia.com/payment/initialize \\
    -H "Content-Type: application/json" \\
    -H "App-id: YOUR_APP_ID" \\
    -H "App-key: YOUR_APP_KEY" \\
    -d '{
        "transaction_id": "{transaction_id}",  // Replace with your dynamic value
        "wallet": "EUR",
        "amount": 100,
        "currency": "EUR",
        "call_back_url": "string",
        "success_url": "string",
        "fail_url": "string",
        "description": "First Payment",
        "payee_name": "John",
        "reference": "#order-1"
    }'`,

    javascript: `const transactionId = '{transaction_id}'; // Replace with your dynamic value
fetch("https://api.infinexia.com/payment/initialize", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
    },
    body: JSON.stringify({
        transaction_id: transactionId,
        wallet: "EUR",
        amount: 100,
        currency: "EUR",
        call_back_url: "string",
        success_url: "string",
        fail_url: "string",
        description: "First Payment",
        payee_name: "John",
        reference: "#order-1"
    })
})
    .then(response => {
        if (!response.ok) {
            throw new Error(\`HTTP error! status: \${response.status}\`);
        }
        return response.json();
    })
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));`,

    python: `import requests

transaction_id = '{transaction_id}'  # Replace with your dynamic value
url = "https://api.infinexia.com/payment/initialize"
headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
}
payload = {
    "transaction_id": transaction_id,
    "wallet": "EUR",
    "amount": 100,
    "currency": "EUR",
    "call_back_url": "string",
    "success_url": "string",
    "fail_url": "string",
    "description": "First Payment",
    "payee_name": "John",
    "reference": "#order-1"
}

try:
    response = requests.post(url, headers=headers, json=payload)
    response.raise_for_status()  # Raise an error for HTTP errors
    print(response.json())
except requests.exceptions.RequestException as e:
    print(f"An error occurred: {e}")`,

    java: `import java.net.HttpURLConnection;
import java.net.URL;
import java.io.OutputStream;

public class Main {
    public static void main(String[] args) throws Exception {
        String transactionId = "{transaction_id}"; // Replace with your dynamic value
        URL url = new URL("https://api.infinexia.com/payment/initialize");
        HttpURLConnection connection = (HttpURLConnection) url.openConnection();
        
        connection.setRequestMethod("POST");
        connection.setRequestProperty("Content-Type", "application/json");
        connection.setRequestProperty("App-id", "YOUR_APP_ID");
        connection.setRequestProperty("App-key", "YOUR_APP_KEY");
        connection.setDoOutput(true);

        String jsonInput = "{ \\\"transaction_id\\\": \\\"" + transactionId + "\\\", \\\"wallet\\\": \\\"EUR\\\", \\\"amount\\\": 100, \\\"currency\\\": \\\"EUR\\\", \\\"call_back_url\\\": \\\"string\\\", \\\"success_url\\\": \\\"string\\\", \\\"fail_url\\\": \\\"string\\\", \\\"description\\\": \\\"First Payment\\\", \\\"payee_name\\\": \\\"John\\\", \\\"reference\\\": \\\"#order-1\\\" }";

        try (OutputStream os = connection.getOutputStream()) {
            os.write(jsonInput.getBytes("utf-8"));
        }

        if (connection.getResponseCode() != HttpURLConnection.HTTP_OK) {
            System.err.println("Error: " + connection.getResponseMessage());
        } else {
            System.out.println("Response received successfully!");
        }
    }
}`,

    php: `<?php

$transactionId = '{transaction_id}'; // Replace with your dynamic value
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://api.infinexia.com/payment/initialize");
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    "Content-Type: application/json",
    "App-id: "YOUR_APP_ID",
    "App-key: "YOUR_APP_KEY"
]);

$data = json_encode([
    "transaction_id" => $transactionId,
    "wallet" => "EUR",
    "amount" => 100,
    "currency" => "EUR",
    "call_back_url" => "string",
    "success_url" => "string",
    "fail_url" => "string",
    "description" => "First Payment",
    "payee_name" => "John",
    "reference" => "#order-1"
]);

curl_setopt($ch, CURLOPT_POSTFIELDS, $data);

$response = curl_exec($ch);
if ($response === false) {
    echo 'cURL Error: ' . curl_error($ch);
} else {
    echo $response;
}
curl_close($ch);`,

    nodejs: `const axios = require("axios");

const transactionId = '{transaction_id}'; // Replace with your dynamic value

const data = {
    transaction_id: transactionId,
    wallet: "EUR",
    amount: 100,
    currency: "EUR",
    call_back_url: "string",
    success_url: "string",
    fail_url: "string",
    description: "First Payment",
    payee_name: "John",
    reference: "#order-1",
};

axios.post("https://api.infinexia.com/payment/initialize", data, {
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
    },
})
    .then(response => console.log(response.data))
    .catch(error => {
        if (error.response) {
            console.error('Error Response:', error.response.data);
        } else {
            console.error('Error Message:', error.message);
        }
    });`,

    csharp: `using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;

class Program {
    static async Task Main() {
        using (HttpClient client = new HttpClient()) {
            var transactionId = "{transaction_id}"; // Replace with your dynamic value

            var payload = new {
                transaction_id = transactionId,
                wallet = "EUR",
                amount = 100,
                currency = "EUR",
                call_back_url = "string",
                success_url = "string",
                fail_url = "string",
                description = "First Payment",
                payee_name = "John",
                reference = "#order-1"
            };

            var content = new StringContent(
                Newtonsoft.Json.JsonConvert.SerializeObject(payload),
                Encoding.UTF8,
                "application/json"
            );

            client.DefaultRequestHeaders.Add("App-id", "YOUR_APP_ID");
            client.DefaultRequestHeaders.Add("App-key", "YOUR_APP_KEY");

            try {
                var response = await client.PostAsync("https://api.infinexia.com/payment/initialize", content);
                Console.WriteLine(await response.Content.ReadAsStringAsync());
            } catch (Exception ex) {
                Console.WriteLine($"Error: {ex.Message}");
            }
        }
    }
}`,
};

export const CheckStatusPayment = {
    curl: `curl -X POST https://api.infinexia.com/payment/check-status \\
    -H "Content-Type: application/json" \\
    -H "App-id: YOUR_APP_ID" \\
    -H "App-key: YOUR_APP_KEY" \\
    -d '{
        "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID" // Replace with your dynamic value
    }'`,

    javascript: `const transactionId = 'YOUR_UNIQUE_TRANSACTION_ID'; // Replace with your dynamic value

fetch("https://api.infinexia.com/payment/check-status", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
    },
    body: JSON.stringify({
        transaction_id: transactionId
    })
})
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error));
`,

    python: `import requests
transaction_id = '{transaction_id}'  # Replace with your dynamic value
url = "https://api.infinexia.com/payment/check-status"
headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
}

payload = {
    "transaction_id": transaction_id
}

response = requests.post(url, headers=headers, json=payload)
print(response.text)
`,

    java: `import java.io.OutputStream;
import java.net.HttpURLConnection;
import java.net.URL;

public class Main {
    public static void main(String[] args) throws Exception {
        String transactionId = "{transaction_id}" // Replace with your dynamic value
        URL url = new URL("https://api.infinexia.com/payment/check-status");
        HttpURLConnection connection = (HttpURLConnection) url.openConnection();

        connection.setRequestMethod("POST");
        connection.setRequestProperty("Content-Type", "application/json");
        connection.setRequestProperty("App-id", "YOUR_APP_ID");
        connection.setRequestProperty("App-key", "YOUR_APP_KEY");
       
        connection.setDoOutput(true);

        String jsonInput = "{ \\"transaction_id\\": \\"" + transactionId + "\\" }";

        try (OutputStream os = connection.getOutputStream()) {
            os.write(jsonInput.getBytes("utf-8"));
        }

        System.out.println(connection.getResponseCode());
    }
}
`,

    php: `<?php

$transactionId = '{transaction_id}'; // Replace with your dynamic value
$url = "https://api.infinexia.com/payment/check-status";

$headers = [
    "Content-Type: application/json",
    "App-id: YOUR_APP_ID",
    "App-key: YOUR_APP_KEY"
];

$data = json_encode([
    "transaction_id" => $transactionId
]);

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS, $data);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

$response = curl_exec($ch);
curl_close($ch);

echo $response;
?>
`,

    nodejs: `const axios = require('axios');

const transactionId = '{transaction_id}'; // Replace with your dynamic value

const payload = {
    transaction_id: transactionId
};

axios.post("https://api.infinexia.com/payment/check-status", payload, {
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
    },
})
.then(response => console.log(response.data))
.catch(error => console.error(error));
`,

    csharp: `using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;

class Program {
    static async Task Main(string[] args) {
        using (HttpClient client = new HttpClient()) {
            var url = "https://api.infinexia.com/payment/check-status";
            var payload = new {
                transaction_id = "YOUR_UNIQUE_TRANSACTION_ID" // Replace with your dynamic value
            };

            var content = new StringContent(
                Newtonsoft.Json.JsonConvert.SerializeObject(payload),
                Encoding.UTF8,
                "application/json"
            );

            client.DefaultRequestHeaders.Add("App-id", "YOUR_APP_ID");
            client.DefaultRequestHeaders.Add("App-key", "YOUR_APP_KEY");

            var response = await client.PostAsync(url, content);
            Console.WriteLine(await response.Content.ReadAsStringAsync());
        }
    }
}
`,
};

export const CheckFees = {
    curl: `curl -X GET https://api.infinexia.com/payment/check-fees \\
    -H "Content-Type: application/json" \\
    -H "App-id: YOUR_APP_ID" \\
    -H "App-key: YOUR_APP_KEY"`,

    javascript: `fetch("https://api.infinexia.com/payment/check-fees", {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        "App-id": "YOUR_APP_ID",
        "App-key": "YOUR_APP_KEY",
    },
})
    .then(response => {
        if (!response.ok) {
            throw new Error(\`HTTP error! status: \${response.status}\`);
        }
        return response.json();
    })
    .then(data => console.log(data))
    .catch(error => console.error("Erreur :", error));
    `,

    python: `import requests

url = "https://api.infinexia.com/payment/check-fees"
headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
}

try:
    response = requests.get(url, headers=headers)
    response.raise_for_status()  # Gérer les erreurs HTTP
    print(response.json())
except requests.exceptions.RequestException as e:
    print(f"Erreur lors de la requête : {e}")
    `,

    java: `import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;

public class Main {
    public static void main(String[] args) throws Exception {
        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
            .uri(URI.create("https://api.infinexia.com/payment/check-fees"))
            .header("Content-Type", "application/json")
            .header("App-id", "YOUR_APP_ID")
            .header("App-key", "YOUR_APP_KEY")
            .GET() // Utilisation de GET
            .build();

        try {
            HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
            System.out.println(response.body());
        } catch (Exception e) {
            System.err.println("Erreur : " + e.getMessage());
        }
    }
}
    `,

    php: `<?php

$url = "https://api.infinexia.com/payment/check-fees";
$headers = [
    "Content-Type: application/json",
    "App-id: YOUR_APP_ID",
    "App-key: YOUR_APP_KEY",
];

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_HTTPGET, true); // Utilisation de GET
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

$response = curl_exec($ch);
if ($response === false) {
    echo 'Erreur : ' . curl_error($ch);
} else {
    echo $response;
}
curl_close($ch);
?>
    `,

    nodejs: `const axios = require("axios");

const headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
};

axios.get("https://api.infinexia.com/payment/check-fees", { headers })
    .then(response => console.log(response.data))
    .catch(error => {
        console.error("Erreur :", error.message);
    });
    `,

    csharp: `using System;
using System.Net.Http;
using System.Threading.Tasks;

class Program {
    static async Task Main() {
        using HttpClient client = new HttpClient();
        client.DefaultRequestHeaders.Add("App-id", "YOUR_APP_ID");
        client.DefaultRequestHeaders.Add("App-key", "YOUR_APP_KEY");

        try {
            var response = await client.GetAsync("https://api.infinexia.com/payment/check-fees");
            if (response.IsSuccessStatusCode) {
                Console.WriteLine(await response.Content.ReadAsStringAsync());
            } else {
                Console.WriteLine($"Erreur HTTP : {response.StatusCode}");
            }
        } catch (Exception ex) {
            Console.WriteLine($"Erreur : {ex.Message}");
        }
    }
}
    `,
};

export const InitializeTransfer = {
    curl: `curl -X POST https://api.infinexia.com/payment/transfer/initialize \\
    -H "Content-Type: application/json" \\
    -H "App-id: YOUR_APP_ID" \\
    -H "App-key: YOUR_APP_KEY" \\
    -d '{
        "transaction_id": "{transaction_id}", // Replace with your dynamic value
        "wallet": "{wallet}", // Replace with your dynamic value
        "amount": {amount}, // Replace with your dynamic value
        "partnerId": "{partnerId}" // Replace with your dynamic value
    }'`,

    javascript: `const transactionId = '{transaction_id}'; // Replace with your dynamic value
const wallet = '{wallet}'; // Replace with your dynamic value
const amount = {amount}; // Replace with your dynamic value
const partnerId = '{partnerId}'; // Replace with your dynamic value

fetch('https://api.infinexia.com/payment/transfer/initialize', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
    },
    body: JSON.stringify({
        transaction_id: transactionId,
        wallet: wallet,
        amount: amount,
        partnerId: partnerId
    }),
})
    .then(response => {
        if (!response.ok) {
            throw new Error(\`HTTP error! status: \${response.status}\`);
        }
        return response.json();
    })
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
`,

    python: `import requests

transaction_id = '{transaction_id}'  # Replace with your dynamic value
wallet = '{wallet}'  # Replace with your dynamic value
amount = {amount}  # Replace with your dynamic value
partnerId = '{partnerId}'  # Replace with your dynamic value

url = "https://api.infinexia.com/payment/transfer/initialize"
headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
}
payload = {
    "transaction_id": transaction_id,
    "wallet": wallet,
    "amount": amount,
    "partnerId": partnerId
}

try:
    response = requests.post(url, headers=headers, json=payload)
    response.raise_for_status()  # Raise an error for HTTP errors
    print(response.json())
except requests.exceptions.RequestException as e:
    print(f"An error occurred: {e}")
`,

    java: `import java.io.OutputStream;
import java.net.HttpURLConnection;
import java.net.URL;

public class Main {
    public static void main(String[] args) throws Exception {
        String transactionId = "{transaction_id}"; // Replace with your dynamic value
        String wallet = "{wallet}"; // Replace with your dynamic value
        double amount = {amount}; // Replace with your dynamic value
        String partnerId = "{partnerId}"; // Replace with your dynamic value

        URL url = new URL("https://api.infinexia.com/payment/transfer/initialize");
        HttpURLConnection connection = (HttpURLConnection) url.openConnection();

        connection.setRequestMethod("POST");
        connection.setRequestProperty("Content-Type", "application/json");
        connection.setRequestProperty("App-id", "YOUR_APP_ID");
        connection.setRequestProperty("App-key", "YOUR_APP_KEY");
        connection.setDoOutput(true);

        String jsonInput = String.format(
            "{ \\\"transaction_id\\\": \\\"%s\\\", \\\"wallet\\\": \\\"%s\\\", \\\"amount\\\": %f, \\\"partnerId\\\": \\\"%s\\\" }",
            transactionId, wallet, amount, partnerId
        );

        try (OutputStream os = connection.getOutputStream()) {
            os.write(jsonInput.getBytes("utf-8"));
        }

        if (connection.getResponseCode() != HttpURLConnection.HTTP_OK) {
            System.err.println("Error: " + connection.getResponseMessage());
        } else {
            System.out.println("Response received successfully!");
        }
    }
}
`,

    php: `<?php

$transactionId = '{transaction_id}'; // Replace with your dynamic value
$wallet = '{wallet}'; // Replace with your dynamic value
$amount = {amount}; // Replace with your dynamic value
$partnerId = '{partnerId}'; // Replace with your dynamic value

$url = 'https://api.infinexia.com/payment/transfer/initialize';
$headers = [
    "Content-Type: application/json",
    "App-id: YOUR_APP_ID",
    "App-key: YOUR_APP_KEY"
];
$data = json_encode([
    'transaction_id' => $transactionId,
    'wallet' => $wallet,
    'amount' => $amount,
    'partnerId' => $partnerId
]);

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, $data);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

$response = curl_exec($ch);

if (curl_errno($ch)) {
    echo 'Error: ' . curl_error($ch);
} else {
    echo $response;
}
curl_close($ch);
?>`,

    nodejs: `const axios = require('axios');

const transactionId = '{transaction_id}'; // Replace with your dynamic value
const wallet = '{wallet}'; // Replace with your dynamic value
const amount = {amount}; // Replace with your dynamic value
const partnerId = '{partnerId}'; // Replace with your dynamic value

const payload = {
    transaction_id: transactionId,
    wallet: wallet,
    amount: amount,
    partnerId: partnerId
};

axios.post("https://api.infinexia.com/payment/transfer/initialize", payload, {
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
    },
})
    .then(response => console.log(response.data))
    .catch(error => {
        if (error.response) {
            console.error('Error Response:', error.response.data);
        } else {
            console.error('Error Message:', error.message);
        }
    });
`,

    csharp: `using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;

class Program {
    static async Task Main(string[] args) {
        string transactionId = "{transaction_id}"; // Replace with your dynamic value
        string wallet = "{wallet}"; // Replace with your dynamic value
        double amount = {amount}; // Replace with your dynamic value
        string partnerId = "{partnerId}"; // Replace with your dynamic value

        var url = "https://api.infinexia.com/payment/transfer/initialize";
        var payload = new {
            transaction_id = transactionId,
            wallet = wallet,
            amount = amount,
            partnerId = partnerId
        };

        var content = new StringContent(
            Newtonsoft.Json.JsonConvert.SerializeObject(payload),
            Encoding.UTF8,
            "application/json"
        );

        using (HttpClient client = new HttpClient()) {
            client.DefaultRequestHeaders.Add("App-id", "YOUR_APP_ID");
            client.DefaultRequestHeaders.Add("App-key", "YOUR_APP_KEY");

            try {
                var response = await client.PostAsync(url, content);
                if (!response.IsSuccessStatusCode) {
                    Console.WriteLine($"HTTP Error: {response.StatusCode}");
                } else {
                    Console.WriteLine(await response.Content.ReadAsStringAsync());
                }
            } catch (Exception ex) {
                Console.WriteLine($"Error: {ex.Message}");
            }
        }
    }
}
`,
};

export const CheckStatusTransfer = {
    curl: `curl -X POST "https://api.infinexia.com/payment/transfer/check-status" \\
    -H "Content-Type: application/json" \\
    -H "App-id: YOUR_APP_ID" \\
    -H "App-key: YOUR_APP_KEY" \\
    -d '{"transaction_id": "{transaction_id}"}'
    `,

    javascript: `const transactionId = '{transaction_id}'; // Replace with your dynamic value

fetch('https://api.infinexia.com/payment/transfer/check-status', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
    },
    body: JSON.stringify({ transaction_id: transactionId }),
})
    .then(response => {
        if (!response.ok) {
            throw new Error(\`HTTP error! status: \${response.status}\`);
        }
        return response.json();
    })
    .then(data => console.log(data))
    .catch(error => console.error('Erreur:', error));
    `,

    python: `import requests

transaction_id = '{transaction_id}'  # Replace with your dynamic value

url = "https://api.infinexia.com/payment/transfer/check-status"
headers = {
    "Content-Type": "application/json",
    "App-id": "YOUR_APP_ID",
    "App-key": "YOUR_APP_KEY",
}
data = {
    "transaction_id": transaction_id
}

try:
    response = requests.post(url, headers=headers, json=data)
    response.raise_for_status()  # Raise an error for HTTP errors
    print(response.json())
except requests.exceptions.RequestException as e:
    print(f"Une erreur s'est produite: {e}")
    `,

    java: `import java.io.OutputStream;
import java.net.HttpURLConnection;
import java.net.URL;

public class Main {
    public static void main(String[] args) throws Exception {
        String transactionId = "{transaction_id}"; // Replace with your dynamic value

        URL url = new URL("https://api.infinexia.com/payment/transfer/check-status");
        HttpURLConnection connection = (HttpURLConnection) url.openConnection();

        connection.setRequestMethod("POST");
        connection.setRequestProperty("Content-Type", "application/json");
        connection.setRequestProperty("App-id", "YOUR_APP_ID");
        connection.setRequestProperty("App-key", "YOUR_APP_KEY");
        connection.setDoOutput(true);

        String jsonInput = String.format("{\\\"transaction_id\\\": \\\"%s\\\"}", transactionId);

        try (OutputStream os = connection.getOutputStream()) {
            os.write(jsonInput.getBytes("utf-8"));
        }

        int responseCode = connection.getResponseCode();
        if (responseCode != HttpURLConnection.HTTP_OK) {
            System.err.println("Erreur HTTP : " + responseCode);
        } else {
            System.out.println("Requête réussie !");
        }
    }
}
    `,

    php: `<?php
$transactionId = '{transaction_id}'; // Replace with your dynamic value

$url = 'https://api.infinexia.com/payment/transfer/check-status';
$data = array('transaction_id' => $transactionId);
$headers = array(
    "Content-Type: application/json",
    "App-id: YOUR_APP_ID",
    "App-key: YOUR_APP_KEY"
);

$options = array(
    'http' => array(
        'header'  => implode("\\r\\n", $headers),
        'method'  => 'POST',
        'content' => json_encode($data),
    ),
);

$context = stream_context_create($options);
$result = file_get_contents($url, false, $context);

if ($result === FALSE) {
    die('Erreur de requête.');
}

echo $result;
?>
    `,

    nodejs: `const https = require('https');

const transactionId = '{transaction_id}'; // Replace with your dynamic value

const data = JSON.stringify({
    transaction_id: transactionId
});

const options = {
    hostname: 'api.infinexia.com',
    port: 443,
    path: '/payment/transfer/check-status',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'App-id': 'YOUR_APP_ID',
        'App-key': 'YOUR_APP_KEY',
        'Content-Length': data.length,
    },
};

const req = https.request(options, (res) => {
    let data = '';

    res.on('data', (chunk) => {
        data += chunk;
    });

    res.on('end', () => {
        console.log(JSON.parse(data));
    });
});

req.on('error', (error) => {
    console.error('Erreur :', error);
});

req.write(data);
req.end();
    `,

    csharp: `using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;

public class Program
{
    private static readonly HttpClient client = new HttpClient();

    public static async Task Main(string[] args)
    {
        string transactionId = "{transaction_id}"; // Replace with your dynamic value

        var url = "https://api.infinexia.com/payment/transfer/check-status";
        client.DefaultRequestHeaders.Add("App-id", "YOUR_APP_ID");
        client.DefaultRequestHeaders.Add("App-key", "YOUR_APP_KEY");

        var data = new StringContent($"{{ \\\"transaction_id\\\": \\\"{transactionId}\\\" }}", Encoding.UTF8, "application/json");

        try {
            var response = await client.PostAsync(url, data);
            if (response.IsSuccessStatusCode) {
                Console.WriteLine(await response.Content.ReadAsStringAsync());
            } else {
                Console.WriteLine("Erreur : " + response.StatusCode);
            }
        } catch (Exception ex) {
            Console.WriteLine($"Erreur : {ex.Message}");
        }
    }
}
    `,
};

export const WebhookRequestSamples = {
    json: `{
  "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID",
  "status": "completed" // "completed" | "pending" | "canceled" | "expired"
  "transaction":{
    "transaction_id": "YOUR_UNIQUE_TRANSACTION_ID",
    "status": "completed" // "completed" | "pending" | "canceled" | "expired"
    "token": "xxxx-xxxx-x-x--xx--x", // reference of the order on Infinexia
    "wallet": "USD",
    "amount": 100,
    "currency": "USD",
    "reference": "#order-1"
    }
}`,
};
