import React, { useEffect, useState } from "react";
import DocHeader from "./HelpCenterHeader";
import Illustration from "../assets/images/hero-illustration.svg";
import Sidebar from "./Sidebar";
import { Outlet, useLocation, useParams } from "react-router-dom";
import HelmetComponent from "../../Admin/Layout/HelmetComponent/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

interface HelpCenterBaseProps {
    disableDarkMode?: boolean;
}

const HelpCenterBase: React.FC<HelpCenterBaseProps> = ({ disableDarkMode }) => {
    const { translate } = translator();
    const location = useLocation();
    const { lang } = useParams();
    const [isMobile, setIsMobile] = useState(false);

    // Détection de la version mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Appeler lors du montage
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Vérifiez si la page actuelle est HelpCentrerIntro
    const isHelpCenterIntro =
        location.pathname ===
        `${lang ? `/${lang}` : "/help-center/intro"}/help-center/intro`;

    const mobileUrls = [
        `${lang ? `/${lang}` : "/help-center/getting-start"}/help-center/getting-start`,
        `${lang ? `/${lang}` : "/help-center/accounts-management"}/help-center/accounts-management`,
        `${lang ? `/${lang}` : "/help-center/transactions"}/help-center/transactions`,
        `${lang ? `/${lang}` : "/help-center/cards"}/help-center/cards`,
        `${lang ? `/${lang}` : "/help-center/settings-security"}/help-center/settings-security`,
        `${lang ? `/${lang}` : "/help-center/sponsorship"}/help-center/sponsorship`,
        `${lang ? `/${lang}` : "/help-center/various"}/help-center/various`,
        `${lang ? `/${lang}` : "/help-center/estimates-invoices"}/help-center/estimates-invoices`,
        `${lang ? `/${lang}` : "/help-center/payment-api"}/help-center/payment-api`,
    ];

    // Vérifie si l'URL actuelle est dans les `mobileUrls`
    const isCurrentUrlMobile = mobileUrls.includes(location.pathname);

    // Fonction pour gérer le changement d'étape
    const handleStepChange = (stepId: string) => {
        console.log(`Step changed to: ${stepId}`);
        // Ajoutez ici la logique de gestion du changement d'étape
    };

    return (
        <div
            className={`font-aspekta antialiased text-slate-800 font-[350] bg-white ${
                !disableDarkMode ? "dark:bg-white dark:text-slate-800" : ""
            }`}
        >
            <div className="flex flex-col min-h-screen overflow-hidden">
                <DocHeader />
                <HelmetComponent
                    title={translate("HELPCENTER", "Help_Center")}
                    description={translate("SEO", "Start_Infinexia_Desc")}
                    link="/help-center"
                    keywords={translate("SEO", "Keys_Home")}
                    imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                    largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                    addPostfixTitle={true}
                    noIndex={true}
                />
                {/*  Page content */}
                <main className="grow">
                    <section className="relative">
                        <div className="absolute top-0 left-1/2 -translate-x-1/2 pointer-events-none -z-10">
                            <img
                                className="max-w-none"
                                src={Illustration}
                                alt="Page illustration"
                                aria-hidden="true"
                            />
                        </div>

                        <div className="max-w-7xl mx-auto px-4 sm:px-6 relative bg-white top-36">
                            {/* Main content */}
                            <div>
                                {/* Affiche Sidebar si non mobile et URL non mobile */}
                                {!isHelpCenterIntro && !isMobile && (
                                    <Sidebar onStepChange={handleStepChange} />
                                )}

                                {/* Gestion du conteneur principal */}
                                {isHelpCenterIntro ? (
                                    // Cas spécifique pour la page d'introduction
                                    <div className="flex justify-center items-center md:grow pb-[200px]">
                                        <div className="w-full md:!w-[80%] max-w-[1080px] sm:pt-14 md:pt-28 pb-0">
                                            <Outlet />
                                        </div>
                                    </div>
                                ) : isMobile && isCurrentUrlMobile ? (
                                    // Conteneur mobile (hors intro)
                                    <div className="flex justify-center items-center md:grow pb-[200px]">
                                        <div className="w-full md:!w-[80%] max-w-[1080px] sm:pt-14 md:pt-28 pb-0">
                                            <Outlet />
                                        </div>
                                    </div>
                                ) : (
                                    // Conteneur desktop
                                    <div className="md:grow sm:pl-0 md:pl-64 lg:pr-6 xl:pr-0 pb-[200px]">
                                        <div className="sm:pt-14 md:pt-28 pb-8 md:pl-6 lg:pl-12">
                                            <Outlet />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default HelpCenterBase;
