export const RequestHeaderSchema = {
    title: "Header parameters",
    fields: [
        {
            name: "Content-Type",
            required: "required",
            type: "string",
            value: "application/json",
            description: "The type of the request body",
        },
        {
            name: "App-id",
            required: "required",
            type: "string",
            value: "app-id",
            description: "Your app identifer",
        },
        {
            name: "App-key",
            required: "required",
            type: "string",
            value: "app-key",
            description: "Your app key",
        },
    ],
};

export const InitializePaymentSchema = {
    title: "Request Body Schema",
    fields: [
        {
            name: "transaction_id",
            required: "required",
            type: "string",
            value: "INF-12345678",
            description: "Unique identifier for the transaction",
        },
        {
            name: "wallet",
            required: "required",
            type: "string",
            value: "EUR",
            description: "Target wallet or currency for the payment",
        },
        {
            name: "amount",
            required: "required",
            type: "number",
            value: 100,
            description: "Amount to be processed in the transaction",
        },
        {
            name: "currency",
            required: "required",
            type: "string",
            value: "EUR",
            description: "Currency of the transaction amount",
        },
        {
            name: "call_back_url",
            required: "optional",
            type: "string",
            value: "https://example.com/callback",
            description:
                "URL to receive asynchronous transaction status updates",
        },
        {
            name: "success_url",
            required: "required",
            type: "string",
            value: "https://example.com/success",
            description: "URL to redirect upon successful transaction",
        },
        {
            name: "fail_url",
            required: "required",
            type: "string",
            value: "https://example.com/fail",
            description: "URL to redirect upon failed transaction",
        },
        {
            name: "description",
            required: "optional",
            type: "string",
            value: "First Payment",
            description: "Description of the transaction",
        },
        {
            name: "payee_name",
            required: "optional",
            type: "string",
            value: "John",
            description: "Name of the payee associated with the transaction",
        },
        {
            name: "reference",
            required: "optional",
            type: "string",
            value: "#order-1",
            description: "Custom reference identifier for the transaction",
        },
    ],
};

export const CheckStatusPaymentSchema = {
    title: "Request Body Schema",
    fields: [
        {
            name: "transaction_id",
            required: "required",
            type: "string",
            value: "YOUR_UNIQUE_TRANSACTION_ID",
            description:
                "Unique identifier for the transaction to check its status",
        },
    ],
};

export const GetFeesPaymentSchema = {
    title: "Header parameters",
    fields: [
        {
            name: "Content-Type",
            required: "required",
            type: "string",
            value: "application/json",
            description: "The type of the request body",
        },
        {
            name: "App-id",
            required: "required",
            type: "string",
            value: "app-id",
            description: "Your app identifer",
        },
        {
            name: "App-key",
            required: "required",
            type: "string",
            value: "app-key",
            description: "Your app key",
        },
    ],
};

export const InitializeTransferSchema = {
    title: "Request Body Schema",
    fields: [
        {
            name: "transaction_id",
            required: "required",
            type: "string",
            value: "INF-trans-111",
            description: "Unique identifier for the transfer transaction",
        },
        {
            name: "wallet",
            required: "required",
            type: "string",
            value: "EUR",
            description:
                "The wallet or currency to be used for the transfer (e.g., EUR, USD)",
        },
        {
            name: "amount",
            required: "required",
            type: "number",
            value: 100,
            description: "The amount to be transferred",
        },
        {
            name: "partnerId",
            required: "required",
            type: "string",
            value: "INF00000320100",
            description:
                "The partner ID to which the transfer is being initialized",
        },
    ],
};

export const WebhookSchema = {
    title: "Body Schema",
    fields: [
        {
            name: "transaction_id",
            required: "required",
            type: "string",
            value: "YOUR_UNIQUE_TRANSACTION_ID",
            description: "Unique identifier for the transaction",
        },
        {
            name: "status",
            required: "required",
            type: "string",
            value: "completed",
            description: "Current status of the transaction",
            enum: "completed / pending / canceled / expired",
        },
        {
            name: "transaction",
            required: "required",
            type: "object",
            description: "Details of the transaction",
            subfields: [
                {
                    name: "transaction_id",
                    required: "required",
                    type: "string",
                    value: "YOUR_UNIQUE_TRANSACTION_ID",
                    description: "Unique identifier for the transaction",
                },
                {
                    name: "status",
                    required: "required",
                    type: "string",
                    value: "completed",
                    description: "Current status of the transaction",
                    enum: ["completed", "pending", "canceled", "expired"],
                },
                {
                    name: "token",
                    required: "required",
                    type: "string",
                    value: "xxxx-xxxx-x-x--xx--x",
                    description:
                        "Reference token for the transaction on Infinexia",
                },
                {
                    name: "wallet",
                    required: "required",
                    type: "string",
                    value: "EUR",
                    description: "The wallet used for the transaction",
                },
                {
                    name: "amount",
                    required: "required",
                    type: "number",
                    value: 100,
                    description: "The amount involved in the transaction",
                },
                {
                    name: "currency",
                    required: "required",
                    type: "string",
                    value: "EUR",
                    description: "The currency used in the transaction",
                },
                {
                    name: "reference",
                    required: "required",
                    type: "string",
                    value: "#order-1",
                    description:
                        "Reference ID for the order related to the transaction",
                },
            ],
        },
    ],
};
