import React, { useEffect, useState } from "react";

import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ChevronCloseSvg from "../../Components/Extra/Svg/ChevronCloseSvg";
import ChevronOpenSvg from "../../Components/Extra/Svg/ChevronOpenSvg";
import Samples from "../Layout/RequestSamples";
import BodySchema from "../Layout/BodySchemas";
import { RequestHeaderSchema } from "../Layout/CodeBodySchemas";
import SamplesRequest from "../Layout/RequestSamples";
import { RequestHeader } from "../Layout/CodeRequestSamples";
import ResponsesSchema from "../Layout/ResponsesSchemas";
import {
    AuthResponses200,
    AuthResponses401,
    WebhookResponses200,
    WebhookResponses401,
    WebhookResponses500,
} from "../Layout/CodeResponsesSchemas";
import SamplesResponses from "../Layout/ResponsesSamples";
import { AuthenticationResponses } from "../Layout/CodeResponsesSamples";

const Authentication = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL
    const [isOpen, setIsOpen] = useState(false);

    type SectionKeys =
        | "base-url"
        | "initialize-pay"
        | "auth-responses"
        | "auth-200"
        | "auth-401"
        | "auth-500";

    const [openSections, setOpenSections] = useState<
        Record<SectionKeys, boolean>
    >({
        "base-url": true,
        "initialize-pay": false,
        "auth-responses": true,
        "auth-200": false,
        "auth-401": false,
        "auth-500": false,
    });

    const toggleSection = (section: SectionKeys) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1)); // Retire le '#' pour obtenir l'ID
            if (element) {
                element.scrollIntoView({ behavior: "smooth" }); // Défilement fluide
            }
        }
    }, [location]);

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "Authentication");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            default:
                return translate("DOC_API_PAGE", "Authentication");
        }
    };
    return (
        <>
            <Banner
                title={translate("DOC_API_PAGE", "Authentication")}
            ></Banner>
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/documentation/introduction`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("DOC_API_PAGE", "DOCUMENTATION")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="w-full space-y-8 text-slate-600 dark:text-slate-400 font-normal sm:!text-sm md:!text-base">
                <div>
                    <header className="mb-5">
                        <h1
                            id="pay-with"
                            data-scrollspy-target=""
                            className="sm:!text-base md:!text-lg font-bold !text-black dark:!text-slate-200"
                        >
                            {translate("DOC_API_PAGE", "API_Auth_Description")}
                        </h1>
                    </header>
                    {/**********  Fin du Header  **********/}
                    <div className="space-y-6">
                        {/***** Section Base URL *****/}
                        <section id="base-url" className="space-y-1">
                            <SubTitle id="base-url" title="Authentification" />
                            <p>
                                {translate(
                                    "DOC_API_PAGE",
                                    "API_Base_Url_Description",
                                )}
                            </p>

                            <div className="grid grid-cols-1 md:!grid-cols-[55%_40%] gap-x-12 gap-y-8 !mt-5 sm:!w-full pb-12">
                                {/* Colonne gauche */}
                                <div>
                                    <div className="border-2 rounded-lg p-5 dark:border-slate-200 dark:border">
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() =>
                                                toggleSection("base-url")
                                            }
                                        >
                                            <SubTitle2 title="Request" />
                                            {openSections["base-url"] ? (
                                                <ChevronCloseSvg />
                                            ) : (
                                                <ChevronOpenSvg />
                                            )}
                                        </div>
                                        {openSections["base-url"] && (
                                            <div className="mt-3 space-y-8">
                                                <BodySchema
                                                    title={
                                                        RequestHeaderSchema.title
                                                    }
                                                    fields={
                                                        RequestHeaderSchema.fields
                                                    }
                                                    isHeader={true}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {/*****  Authentication Responses  *****/}
                                    <div className="border-2 dark:border-slate-200 dark:border rounded-lg p-5 !mt-5">
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() =>
                                                toggleSection("auth-responses")
                                            }
                                        >
                                            <SubTitle2 title="Responses"></SubTitle2>
                                            {openSections["auth-responses"] ? (
                                                <ChevronCloseSvg />
                                            ) : (
                                                <ChevronOpenSvg />
                                            )}
                                        </div>
                                        {openSections["auth-responses"] && (
                                            <div className="mt-3">
                                                <div className="border border-green-600 dark:border-green-600 rounded-lg px-5 py-3 mt-5">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "auth-200",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="200 OK"></SubTitle3>
                                                        {openSections[
                                                            "auth-200"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "auth-200"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        AuthResponses200.title
                                                                    }
                                                                    fields={
                                                                        AuthResponses200.fields
                                                                    }
                                                                    isSuccessResponse={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="border border-red-600 dark:border-red-600 rounded-lg px-5 py-3 mt-3">
                                                    <div
                                                        className="flex justify-between items-center cursor-pointer"
                                                        onClick={() =>
                                                            toggleSection(
                                                                "auth-401",
                                                            )
                                                        }
                                                    >
                                                        <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                                        {openSections[
                                                            "auth-401"
                                                        ] ? (
                                                            <ChevronCloseSvg />
                                                        ) : (
                                                            <ChevronOpenSvg />
                                                        )}
                                                    </div>
                                                    {openSections[
                                                        "auth-401"
                                                    ] && (
                                                        <div className="mt-3 space-y-8">
                                                            <div className="">
                                                                <ResponsesSchema
                                                                    title={
                                                                        AuthResponses401.title
                                                                    }
                                                                    fields={
                                                                        AuthResponses401.fields
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Colonne droite */}
                                <div className="space-y-5">
                                    <div className="bg-slate-800 border border-slate-700 p-4 rounded-lg text-white overflow-hidden">
                                        <pre className="text-sm text-slate-400 bg-slate-800 overflow-x-auto">
                                            <div className="flex justify-between">
                                                <div>
                                                    <code className="text-sm font-semibold text-blue-500 border border-blue-500 dark:border-blue-500 px-1 rounded">
                                                        POST
                                                    </code>{" "}
                                                    <code className="font-pt-mono">
                                                        https://api.infinexia.com/payment
                                                    </code>
                                                </div>
                                                <span className="text-sm text-teal-500">
                                                    <KpCopyClick
                                                        text="https://api.infinexia.com/payment"
                                                        message=""
                                                    />
                                                </span>
                                            </div>
                                        </pre>
                                    </div>
                                    <div className="pt-0">
                                        <SamplesRequest
                                            titleBox="Request samples"
                                            codeSamples={RequestHeader}
                                        />
                                    </div>

                                    <div className="pt-0">
                                        <SamplesResponses
                                            titleBox="Response samples"
                                            codeSamples={
                                                AuthenticationResponses
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Authentication;
