import React, { useState, useEffect } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";

const GettingStart = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            case "open-individual-account":
                return translate("HELPCENTER", "Open_Individual");
            case "open-business-account":
                return translate("HELPCENTER", "Open_Business");
            case "subscription":
                return translate("HELPCENTER", "Select_Offer");
            default:
                return translate("HELPCENTER", "Start_Infinexia");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            case "open-individual-account":
                return translate("HELPCENTER", "Individual_Account");
            case "open-business-account":
                return translate("HELPCENTER", "Business_Account");
            case "subscription":
                return translate("HELPCENTER", "Select_Offer");
            default:
                return translate("HELPCENTER", "Start_Infinexia");
        }
    };

    const renderContentForStep = () => {
        switch (currentStep) {
            case "open-individual-account":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="open-individual-account"
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Subtitle_Start_Infinexia",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Desc1_Start",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Desc2_Start",
                                    )}{" "}
                                    <NavLink
                                        to={`${lang ? `/${lang}` : "/#"}/#`}
                                        className="text-blue-600 underline hover:text-blue-800"
                                    >
                                        {translate(
                                            "HELPCENTER_START",
                                            "Desc3_Start",
                                        )}
                                    </NavLink>
                                    .
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Create_Your_Account",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_to")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_START",
                                                "Create_Account",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate("HELPCENTER", "Select")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_START",
                                                "Individual",
                                            )}
                                            »
                                        </strong>{" "}
                                        {translate(
                                            "HELPCENTER",
                                            "On_Dropdown_Menu",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Indicate_Your_Personal_Infos",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Create_login_Pwd",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_START",
                                                "Create_Account",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Complete_Your_Infos",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Indicate_Rest_Infos",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Indicate_Your_Address",
                                        )}
                                    </li>
                                    <li>
                                        {translate("HELPCENTER", "Select")}{" "}
                                        {translate(
                                            "HELPCENTER_START",
                                            "Choice_Language",
                                        )}
                                    </li>
                                    <li>
                                        {translate("HELPCENTER", "Select")}{" "}
                                        {translate(
                                            "HELPCENTER_START",
                                            "Choice_Currency",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «{translate("HELPCENTER", "Save")}»
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Topup_Your_Account",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Topup_Your_Account_Desc",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "open-business-account":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="open-business-account"
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Subtitle_Start_Infinexia",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Desc1_Start",
                                    )}
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Desc2_Start",
                                    )}{" "}
                                    <NavLink
                                        to={`${lang ? `/${lang}` : "/#"}/#`}
                                        className="text-blue-600 underline hover:text-blue-800"
                                    >
                                        {translate(
                                            "HELPCENTER_START",
                                            "Desc3_Start",
                                        )}
                                    </NavLink>
                                    .
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Create_Your_Account",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_to")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate("HELPCENTER", "Click_On")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_START",
                                                "Create_Account",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        {translate("HELPCENTER", "Select")}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_START",
                                                "Business",
                                            )}
                                            »
                                        </strong>{" "}
                                        {translate(
                                            "HELPCENTER",
                                            "On_Dropdown_Menu",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Indicate_Your_Business_Infos",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Indicate_Your_Personal_Infos",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Create_login_Pwd",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_START",
                                                "Create_Account",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Complete_Your_Infos",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Indicate_Rest_Infos",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Indicate_Your_Address",
                                        )}
                                    </li>
                                    <li>
                                        {translate("HELPCENTER", "Select")}{" "}
                                        {translate(
                                            "HELPCENTER_START",
                                            "Choice_Language",
                                        )}
                                    </li>
                                    <li>
                                        {translate("HELPCENTER", "Select")}{" "}
                                        {translate(
                                            "HELPCENTER_START",
                                            "Choice_Currency",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «{translate("HELPCENTER", "Save")}»
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Topup_Your_Account",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Topup_Your_Account_Desc",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "subscription":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="subscription"
                                    title={translate(
                                        "HELPCENTER_START",
                                        "After_Choice_An_Offer",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Multi_Choice_Offer",
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Subscribe_An_Offer",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate("HELPCENTER", "Rdv_To_Page")}{" "}
                                    <strong>
                                        «
                                        {translate(
                                            "SUBSCRIPTIONS",
                                            "Loyalty_list2",
                                        )}
                                        »
                                    </strong>{" "}
                                    {translate("HELPCENTER", "Steps")}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Select_Offer_Correspond",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Select_Payment_Period",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER",
                                            "And_Valid_Onclick",
                                        )}{" "}
                                        <strong>
                                            «
                                            {translate(
                                                "HELPCENTER_START",
                                                "Subscribe",
                                            )}
                                            »
                                        </strong>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate(
                                        "HELPCENTER_START",
                                        "Identity_Verif",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Step_Verif",
                                    )}
                                </p>
                                <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Selfie_Verif",
                                        )}
                                    </li>
                                    <li>
                                        {translate(
                                            "HELPCENTER_START",
                                            "Doc_Verif",
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id=""
                                    title={translate("HELPCENTER", "Note")}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "After_Verif",
                                    )}
                                </p>
                                <p className="pt-1 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_START",
                                        "Iban_Access",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            default:
                return null; // Aucun contenu par défaut si le type de compte n'est pas reconnu
        }
    };

    return (
        <>
            <Banner title={getBannerTitle()} />
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/help-center/getting-start`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("HELPCENTER", "Start_Infinexia")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape (Fundamentals ou autre) */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div>
                        <div className="text-slate-600 dark:text-slate-600 space-y-6">
                            {/* Affiche le contenu selon currentStep */}
                            {renderContentForStep()}
                        </div>
                    </div>
                    <div className="hidden md:block pt-10">
                        <p className="sm:!text-sm md:!text-base text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}{" "}
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700 font-normal"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                    <div className="md:hidden py-10">
                        <p className="sm:!text-sm md:!text-base text-center text-black font-bold">
                            {translate("HELPCENTER", "Need_Help")}
                        </p>
                        <p className="sm:!text-sm md:!text-base text-center text-black font-normal">
                            <a
                                href="javascript:void(Tawk_API.toggle())"
                                className="text-blue-500 underline hover:text-blue-700"
                            >
                                {translate("HELPCENTER", "Send_Us_Message")}
                            </a>
                        </p>
                    </div>
                </div>
            </article>
        </>
    );
};

export default GettingStart;
