import { CARD_TYPE, CardStatus, RealCardType } from "./UserCard";

export interface IFidelity {
    name: string;
    configuration: string;
    price: number;
    klc_value: number;
    sub_title: string;
    is_text_klc_value: boolean;
    is_text_price: boolean;
    text_klc_value: string;
    text_price: string;
    paymentMonthlyLimit: number;
    withdrawalMonthlyLimit: number;
    withdrawFreeMonthly: number;
    feesWithdrawMonthlyFreeLimit: number;
    price_change_pin: number;
    price_forgot_pin: number;
    price_virtual_card: number;
    free_virtual_card: number;
    price_virtual_card_ephemere: number;
    free_virtual_card_ephemere: number;
    price_virements: number;
    free_virements: number;
    photo?: File;
    discount_3?: number;
    discount_6?: number;
    discount_12?: number;
    discount_24?: number;
    type?: string;
}

export interface ISubscribeFidelity {
    fidelityId?: number;
    nbMonth: number;
    gateway: any;
    wallet?: string;
    order_card?: boolean;
    type?: string;
    virtualType?: "VIRTUAL_CARD" | "VIRTUAL_CARD_U";
    orderType?: CardStatus;
    realCardType?: RealCardType;
    is_first_card_order?: boolean;
    is_stolen_lost_card?: boolean;
}

export interface ISubscribeFidelity2 {
    nbMonth: number;
    gateway: any;
    wallet?: string;
    order_card?: boolean;
    type?: string;
    virtualType?: "VIRTUAL_CARD" | "VIRTUAL_CARD_U";
}

export default class FidelityModel {
    id?: number;
    configuration!: string;
    name!: string;
    price!: number;
    klc_value!: number;
    photo?: string;
    sub_title!: string;
    is_text_klc_value!: boolean;
    is_text_price!: boolean;
    text_klc_value!: string;
    text_price!: string;
    created_at?: Date;
    updated_at?: Date;
    discount_3?: number;
    discount_6?: number;
    discount_12?: number;
    discount_24?: number;
    paymentMonthlyLimit!: number;
    withdrawalMonthlyLimit!: number;
    withdrawFreeMonthly!: number;
    feesWithdrawMonthlyFreeLimit!: number;
    price_change_pin?: number;
    price_forgot_pin?: number;
    price_virtual_card?: number;
    free_virtual_card?: number;
    price_virtual_card_ephemere?: number;
    free_virtual_card_ephemere?: number;
    price_virements?: number;
    free_virements?: number;
    type?: string;
    monthlyPrice: any;
    Price: any;
    isAnnual: any;

    constructor(
        name: string,
        configuration: string,
        price: number,
        klc_value: number,
    ) {
        this.name = name;
        this.configuration = configuration;
        this.price = price;
        this.klc_value = klc_value;
    }

    getCardType(): CARD_TYPE {
        const name = this.name.toLowerCase();
        if (name.includes("blue")) return CARD_TYPE.BLUE;
        if (name.includes("black")) return CARD_TYPE.BLACK;
        return CARD_TYPE.GOLD;
    }
}
export interface WithdrawReduction {
    free: boolean;
    percent_reduction: number;
}

export enum FidelityType {
    PARTICULIER = "particulier",
    BUSINESS = "business",
}

export enum VirtualType {
    VIRTUAL_CARD = "VIRTUAL_CARD",
    VIRTUAL_CARD_U = "VIRTUAL_CARD_U",
}

export enum FidelitySubscriptionType {
    MONTHLY = "monthly",
    ANNUALLY = "annually",
}
