import React from "react";
import FourCubesSvg from "../../Components/Extra/Svg/FourCubesSvg";
import FourBookFaqSvg from "../../Components/Extra/Svg/FourBookFaqSvg";
import BookOpenCoverSvg from "../../Components/Extra/Svg/BookOpenCoverSvg";
import BookFaqSvg from "../../Components/Extra/Svg/BookFaqSvg";

const Banner = ({ title }: { title: string }) => {
    return (
        <div className="h-16 flex md:!justify-start sm:justify-center mb-6 ml-0 items-center">
            <BookOpenCoverSvg />
            <span className="font-bold sm:!text-xl md:!text-2xl text-blue-600 ml-2">
                {title}
            </span>
        </div>
    );
};

export default Banner;
